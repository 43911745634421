const es = {
    brand:'WOW ERAN',
    nav:{
        Home:'Inicio',
        Function:'Función',
        Mining:'Minería',
        RoadMap:'Mapa vial',
        WhitePaper:'WhitePaper',
        Blog:'Blog',
        Task: 'Centro de Tarefas'
    },
    language:'Idioma',
    banner:{
        title:'Puerta de entrada<br/>a Web3',
        des:'Desbloquee la auténtica propiedad de activos digitales:<br/>Su portal a experiencias Web3 incomparables.',
        btn1:'Lanzar Dapp',
        btn2:'Descargar Billetera',
        btn3:'Auditado por Hacken'
    },

    data:{
        data1:'Usuarios activos diarios',
        data2:'Países cubiertos',
        data3:'Usuario',
        data4:'Socios estratégicos',
        data5:'Usuarios de la comunidad',
    },
    features:{
        title:'CARACTERÍSTICAS',
        data1:'Comprar criptomonedas',
        data2:'Dapp de minería',
        data3:'NFTs',
        data4:'Layer1 Blockchain',
        data5:'Staking',
        data6:'Intercambio',
        data7:'DEX',
        data8:'Comunidad'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin es parte del ecosistema WOW EARN, una plataforma blockchain que enfatiza la alta eficiencia y conectividad en el mundo de las finanzas descentralizadas (DeFi), staking | yield farming | mining.',
        data2:'La cadena WOW EARN es una plataforma pionera de Capa 1 diseñada para ofrecer a usuarios y desarrolladores eficiencia y escalabilidad sin igual para varias aplicaciones basadas en blockchain.',
        data3:'La WOW Coin juega un papel crucial dentro de este ecosistema, facilitando transacciones, recompensas y tarifas, y sirviendo como la moneda principal para las interacciones dentro de la plataforma WOW EARN.',
        data4:'Su uso en transferencias de monedas, como se detalla en la sección de transacciones del explorador, demuestra su utilidad fundamental al permitir operaciones de blockchain e intercambio de valor dentro de la red WOW EARN.',

    },
    mechanism:{
        title:'Suministro total',
        data1:'Minería de usuarios 35%',
        data2:'Minería de nodos 20%',
        data3:'Fundación 8%',
        data4:'Oferta pública (IDO) 3%',
        data5:'Ronda de inicio 14%',
        data6:'Desarrollo operativo 10%',
        data7:'Gestión del valor de mercado 5%',
        data8:'Recompensa de staking 4%',
        data9:'Incentivo comunitario 0.95%',
        data10:'Recompensa de Airdrop 0.05%',
        total:'1 mil millones de suministro total',
    },
    roadmap:{
        title:'Hoja de ruta de desarrollo',
        data1:{
            q:'T4/ 2022',
            d:'Establecimiento del equipo WOW EARN.<br/>' +
                'Lanzamiento del Libro Blanco y plan de desarrollo de WOW EARN.'
        },
        data2:{
            q:'T1/ 2023',
            d:'Prueba exitosa del proyecto DApp.<br/>' +
                'Lanzamiento de WOW EARN.<br/>' +
                'Soporte para ETH, BSC.<br/>' +
                'Introducción de staking USDT.<br/>' +
                'Mecanismo de invitación de referidos único.<br/>' +
                'La base de usuarios de WOW EARN alcanza los 50,000.<br/>' +
                'Establecimiento de asociaciones con importantes medios de comunicación blockchain.'
        },
        data3:{
            q:'T2/ 2023',
            d:'Auditoría de contrato inteligente completada.<br/>' +
                'Aprobación de la auditoría del contrato DApp.<br/>' +
                'Lanzamiento de nueva imagen de marca.<br/>' +
                'Lanzamiento público de WOWCoin.<br/>' +
                'La base de usuarios de WOW EARN alcanza los 100,000.<br/>' +
                'Soporte para transacciones entre cadenas.<br/>' +
                'Formación de asociaciones estratégicas con numerosos proyectos DeFi.'
        },
        data4:{
            q:'T3/ 2023',
            d:'La base de usuarios de WOW EARN alcanza los 500,000.<br/>' +
                'Colaboraciones globales de marcas.<br/>' +
                'Inicio de eventos offline Web3.'
        },
        data5:{
            q:'T4/ 2023',
            d:'La base de usuarios de WOW EARN alcanza los 1,000,000.<br/>' +
                'Integración de datos de TOKEN y activos NFT de múltiples escenarios.'
        },
        data6:{
            q:'T1/ 2024',
            d:`Lanzamiento del nuevo sitio web oficial<br/>
            Usuarios activos diarios de DApp superan los 100,000<br/>
            Las direcciones de tenencia de la cadena pública WOW EARN superan las 500,000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Beta pública y lanzamiento del DEX basado en la cadena pública WOW EARN<br/>
            Lanzamiento oficial del proyecto GameFi en la cadena pública WOW EARN<br/>
            Mejora de la experiencia de usuario de la billetera WOW EARN con soporte multi-cadena agregado<br/>
            Lanzamiento exitoso de más de 10,000 aplicaciones DApp<br/>
            Participación en exposiciones de blockchain fuera de línea de renombre mundial aumenta significativamente la visibilidad de la marca<br/>
            Desarrollo de una plataforma de incubación para construir el ecosistema de la cadena pública WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX tiene como objetivo superar un Valor Total Bloqueado (TVL) de 1M U, los productos de préstamo tienen como objetivo superar un TVL de 1M U<br/>
            WOWCoin listado en intercambios centralizados<br/>
            Desarrollo de una plataforma de financiación para proporcionar apoyo financiero a proyectos creativos<br/>
            Desarrollo continuo de productos, lanzamiento de aplicaciones exitosas, incluidas aplicaciones de juegos DApp y aplicaciones Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Mantenimiento de creadores de mercado para preservar el valor de WOWCoin y desarrollo continuo de productos innovadores<br/>
            Mejora significativa en la adherencia de usuarios para productos periféricos<br/>
            Perfeccionamiento integral de la gobernanza de WOW EARN DAO`
        },

    },
    partners:{
        title:'Nuestros Socios y Medios'
    },
    downloadWallet : {
        title: "Comercie de forma segura y protegida en cualquier momento. En cualquier lugar.",
        subtitle: "Negocia en casa y en movimiento con nuestra aplicación. Disponible en App Store y Google Play Store.",
        downWalletTop: "Descargar desde",
        donwloadTitle: "Androide",
        titleIOS: "IOS",


    },
    footer:{
        social:'Redes sociales',
        resources:{
            title:'Resources',
            data1:'WhitePaper',
            data2:'MINERÍA',
            data3:'Staking',
            data4:'Mapa vial',
        },
        contact:'Contáctanos',
        app:{
            ios:'iOS',
            android:'Android',
            apk:'APK'
        }
    }

}
export default es;

/*西班牙语*/
