const th = {
    brand:'WOW ERAN',
    nav:{
        Home:'หน้าแรก',
        Function:'ฟังก์ชัน',
        Mining:'การทำเหมือง',
        RoadMap:'แผนที่',
        WhitePaper:'กระดาษขาว',
        Blog:'บล็อก',
        Task: 'ศูนย์งาน'
    },
    language:'ภาษา',
    banner:{
        title:'เกตเวย์<br/>ไปยังเว็บ 3',
        des:'ปลดล็อกการเป็นเจ้าของสินทรัพย์ดิจิทัลแท้จริง:<br/>ประตูของคุณสู่ประสบการณ์เว็บ 3 ที่ไม่เหมือนใคร',
        btn1:'เริ่ม Dapp',
        btn2:'ดาวน์โหลดวอลเล็ต',
        btn3:'ตรวจสอบโดย Hacken'
    },

    data:{
        data1:'ผู้ใช้กิจกรรมประจำวัน',
        data2:'ประเทศที่ได้รับการครอบคลุม',
        data3:'ผู้ใช้',
        data4:'พันธมิตรกลยุทธ์',
        data5:'ผู้ใช้ในชุมชน',
    },
    features:{
        title:'คุณสมบัติ',
        data1:'ซื้อคริปโต',
        data2:'Dapp การทำเหมือง',
        data3:'NFTs',
        data4:'เลเยอร์ 1 บล็อกเชน',
        data5:'การฝากเงิน',
        data6:'สวิทช์',
        data7:'DEX',
        data8:'ชุมชน'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin เป็นส่วนหนึ่งของระบบ WOW EARN ซึ่งเป็นแพลตฟอร์มบล็อกเชนที่เน้นความมีประสิทธิภาพและการเชื่อมต่อสูงสุดในโลกของการเงินที่กระจาย (DeFi), การฝากเงินทุน | เกษียณเงินทุน | การทำเหมือง.',
        data2:'โซ่ WOW EARN เป็นแพลตฟอร์มชั้นที่ 1 ที่ออกแบบมาเพื่อให้ผู้ใช้และผู้พัฒนาได้รับประสิทธิภาพและการขยายตัวได้โดยไม่เคยเคยมีใครสำหรับแอพลิเคชันที่ใช้เทคโนโลยีบล็อกเชนในหลายๆด้าน',
        data3:'WOW Coin เป็นส่วนสำคัญภายในระบบนี้โดยการกระทำในการทำธุรกรรม, รางวัล, ค่าธรรมเนียม, และการเป็นสกุลเงินหลักสำหรับการจับสารสนเทศภายในแพลตฟอร์ม WOW EARN',
        data4:'การใช้งานในการโอนเหรียญ, เช่นรายละเอียดในส่วนของการซึ่งอยู่ในตำแหน่งของการสำรวจ, ยืนยันถึงความสำคัญของมันในการทำให้การดำเนินการบล็อกเชนและการแลกเปลี่ยนมูลค่าในเครือข่าย WOW EARN สามารถดำเนินการได้'

    },
    mechanism:{
        title:'จำนวนทั้งหมด',
        data1:'การทำเหมืองผู้ใช้ 35%',
        data2:'การทำเหมืองโหนด 20%',
        data3:'มูลนิธิ 8%',
        data4:'เสนอให้ขายต่อสาธารณะ (IDO) 3%',
        data5:'รอบเมล็ดพันธุ์ 14%',
        data6:'การพัฒนาดำเนินงาน 10%',
        data7:'การจัดการมูลค่าตลาด 5%',
        data8:'รางวัลการฝากเงิน 4%',
        data9:'สิทธิส่งเสริมชุมชน 0.95%',
        data10:'รางวัลแจกฟรี 0.05%',
        total:'1 พันล้านหน่วยจำนวนทั้งหมด',
    },
    roadmap:{
        title:'แผนการพัฒนา',
        data1:{
            q:'ไตรมาส 4/ 2022',
            d:'การสร้างทีม WOW EARN.<br/>' +
                'การเผยแพร่เอกสารสีขาวและแผนการพัฒนา WOW EARN'
        },
        data2:{
            q:'ไตรมาส 1/ 2023',
            d:'การทดสอบโครงการ DApp ที่ประสบความสำเร็จ.<br/>' +
                'เริ่มต้น WOW EARN.<br/>' +
                'สนับสนุนสำหรับ ETH, BSC.<br/>' +
                'การแนะนำการฝาก USDT.<br/>' +
                'เครื่องมือการเชิญอ้างอิงที่ไม่ซ้ำกัน.<br/>' +
                'ฐานผู้ใช้ WOW EARN ได้รับการเข้าถึง 50,000.<br/>' +
                'การสร้างพันธมิตรกับสื่อบล็อกเชนชั้นนำ'
        },
        data3:{
            q:'ไตรมาส 2/ 2023',
            d:'การตรวจสอบสัญญาฉลากฉลองเสร็จสมบูรณ์.<br/>' +
                'การผ่านการตรวจสอบสัญญา DApp.<br/>' +
                'เริ่มต้นภาพประกอบแบรนด์ใหม่.<br/>' +
                'การเผยแพร่ WOWCoin แบบสาธารณะ.<br/>' +
                'ฐานผู้ใช้ WOW EARN ได้รับการเข้าถึง 100,000.<br/>' +
                'สนับสนุนสำหรับการทำธุรกรรมต่างๆ ในเครือข่าย.<br/>' +
                'การสร้างพันธมิตรกลยุทธ์กับโปรเจกต์ DeFi จำนวนมาก'
        },
        data4:{
            q:'ไตรมาส 3/ 2023',
            d:'ฐานผู้ใช้ WOW EARN ได้รับการเข้าถึง 500,000.<br/>' +
                'การร่วมมือระดับโลกกับแบรนด์ที่มีชื่อเสียง.<br/>' +
                'เริ่มต้นกิจกรรมออฟไลน์ Web3'
        },
        data5:{
            q:'ไตรมาส 4/ 2023',
            d:'ฐานผู้ใช้ WOW EARN ได้รับการเข้าถึง 1,000,000.<br/>' +
                'การรวมข้อมูลที่มีหลายโครงการเป็นสถานการณ์หนึ่ง'
        },
        data6:{
            q:'ไตรมาส 1/ 2024',
            d:`เปิดตัวเว็บไซต์ใหม่<br/>
            ผู้ใช้งาน DApp รายวันทะลุ 100,000 คน<br/>
            ที่อยู่การถือครองเหรียญบน WOW EARN เชนสาธารณะเกิน 500,000 ที่อยู่`
        },
        data7:{
            q:'Q2/ 2024',
            d:`เปิดการทดสอบสาธารณะและเปิดตัว DEX บน WOW EARN เชน<br/>
            โปรเจกต์ gamefi บน WOW EARN เชนเริ่มต้นอย่างเป็นทางการ<br/>
            ปรับปรุงประสบการณ์ผู้ใช้งานกระเป๋า WOW EARN โดยเพิ่มการสนับสนุนหลายเชน<br/>
            การเปิดตัวแอปพลิเคชัน DApp มากกว่า 10,000 แอปพลิเคชันอย่างสำเร็จ<br/>
            การเข้าร่วมงานแสดงสินค้าบล็อกเชนนอกสถานที่ที่มีชื่อเสียงทั่วโลกช่วยเพิ่มความน่าเชื่อถือของแบรนด์อย่างมาก<br/>
            สร้างแพลตฟอร์มการบ่มเพาะเพื่อปรับปรุงระบบนิเวศของ WOW EARN เชน`
        },
        data8:{
            q:'Q3/ 2024',
            d:`วัตถุประสงค์ของ DEX คือการทะลุ TVL 1M U, ผลิตภัณฑ์การกู้ยืมมีเป้าหมาย TVL ทะลุ 1M U<br/>
            WOWCoin เข้าสู่การซื้อขายในตลาดกลาง<br/>
            สร้างแพลตฟอร์มการเงินเพื่อสนับสนุนโครงการสร้างสรรค์<br/>
            การพัฒนาผลิตภัณฑ์อย่างต่อเนื่อง, การเปิดตัวแอปพลิเคชันฮิต รวมถึงแอปพลิเคชันเกม DApp และแอปพลิเคชัน Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`การบำรุงรักษาผู้สร้างตลาด, รักษาค่าของ WOWCoin พร้อมกับการพัฒนาผลิตภัณฑ์ใหม่ๆ ที่มีนวัตกรรม<br/>
            ผลิตภัณฑ์รอบข้างมีการเพิ่มขึ้นอย่างมากของการยึดติดของผู้ใช้<br/>
            การบริหารจัดการของ WOW EARN DAO ได้รับการปรับปรุงและสมบูรณ์แบบ`
        },

    },
    partners:{
        title:'พาร์ทเนอร์และสื่อของเรา'
    },
    downloadWallet : {
        title: "ซื้อขายอย่างปลอดภัยและมั่นคงทุกเวลา ทุกที่",
        subtitle: "ซื้อขายที่บ้านและขณะเดินทางด้วยแอปของเรา มีให้บริการที่ App Store และ Google Play Store",
        downWalletTop: "ดาวน์โหลดจาก",
        donwloadTitle: "หุ่นยนต์",
        titleIOS: "ไอโอเอส",


    },
    footer:{
        social:'โซเชียลมีเดีย',
        resources:{
            title:'ทรัพยากร',
            data1:'กระดาษสีขาว',
            data2:'MINGING',
            data3:'การฝากเงิน',
            data4:'แผนที่',
        },
        contact:'ติดต่อเรา',
        app:{
            ios:'IOS',
            android:'Android',
            apk:'APK'
        }
    }

}
export default th;
/*泰语*/
