
const de = {
    brand:'WOW ERAN',
    nav:{
        Home:'Home',
        Function:'Funktion',
        Mining:'Mining',
        RoadMap:'RoadMap',
        WhitePaper:'WhitePaper',
        Blog:'Blog',
        Task: 'Aufgabencenter'
    },
    language:'Sprache',
    banner:{
        title:'Gateway<br/>zu Web3',
        des:'Echte digitale Vermögenswerte freischalten:<br/>Ihr Portal zu beispiellosen Web3-Erfahrungen.',
        btn1:'Dapp starten',
        btn2:'Wallet downloaden',
        btn3:'Geprüft von Hacken'
    },

    data:{
        data1:'Tägliche Nutzer',
        data2:'Abgedeckte Länder',
        data3:'Benutzer',
        data4:'Strategische Partner',
        data5:'Community-Benutzer',
    },
    features:{
        title:'MERKMALE',
        data1:'Krypto kaufen',
        data2:'Mining Dapp',
        data3:'NFTs',
        data4:'Layer1 Blockchain',
        data5:'Staking',
        data6:'Swap',
        data7:'DEX',
        data8:'Community'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'Die WOW COIN ist Teil des WOW EARN-Ökosystems, einer Blockchain-Plattform, die hohe Effizienz und Konnektivität in der Welt der dezentralen Finanzen (DeFi), Staking | Yield Farming | Mining, betont.',
        data2:'Die WOW EARN-Chain ist eine wegweisende Layer-1-Plattform, die Benutzern und Entwicklern unvergleichliche Effizienz und Skalierbarkeit für verschiedene blockchainbasierte Anwendungen bietet.',
        data3:'Die WOW COIN spielt eine entscheidende Rolle innerhalb dieses Ökosystems, indem sie Transaktionen, Belohnungen und Gebühren erleichtert und als primäre Währung für Interaktionen innerhalb der WOW EARN-Plattform dient.',
        data4:'Ihre Verwendung bei Münztransfers, wie im Transaktionsabschnitt des Explorers detailliert dargestellt, zeigt ihre grundlegende Nützlichkeit bei der Ermöglichung von Blockchain-Operationen und dem Werteaustausch innerhalb des WOW EARN-Netzwerks.',

    },
    mechanism:{
        title:'Gesamtangebot',
        data1:'Benutzer-Mining 35%',
        data2:'Knoten-Mining 20%',
        data3:'Stiftung 8%',
        data4:'Öffentliches Angebot (IDO) 3%',
        data5:'Saatrunde 14%',
        data6:'Operationelle Entwicklung 10%',
        data7:'Marktwertmanagement 5%',
        data8:'Staking-Belohnung 4%',
        data9:'Gemeinschaftsanreiz 0,95%',
        data10:'Airdrop-Belohnung 0,05%',
        total:'1 Milliarde Gesamtangebot',
    },
    roadmap:{
        title:'Entwicklungs-Roadmap',
        data1:{
            q:'Q4/ 2022',
            d:'Gründung des WOW EARN-Teams.<br/>' +
                'Veröffentlichung von WOW EARN Whitepaper und Entwicklungsplan.'
        },
        data2:{
            q:'Q1/ 2023',
            d:'Erfolgreicher Test des DApp-Projekts.<br/>' +
                'Start von WOW EARN.<br/>' +
                'Unterstützung für ETH, BSC.<br/>' +
                'Einführung von USDT Staking.<br/>' +
                'Einzigartiger Empfehlungs-Einladungsmechanismus.<br/>' +
                'Die WOW EARN-Benutzerbasis erreicht 50.000.<br/>' +
                'Aufbau von Partnerschaften mit wichtigen Blockchain-Medienunternehmen.'
        },
        data3:{
            q:'Q2/ 2023',
            d:'Abschluss der Smart Contract-Prüfung.<br/>' +
                'Bestandene DApp-Vertragsprüfung.<br/>' +
                'Start des neuen Markenbildes.<br/>' +
                'Öffentliche Veröffentlichung von WOWCoin.<br/>' +
                'Die WOW EARN-Benutzerbasis erreicht 100.000.<br/>' +
                'Unterstützung für Cross-Chain-Transaktionen.<br/>' +
                'Bildung strategischer Partnerschaften mit zahlreichen DeFi-Projekten.'
        },
        data4:{
            q:'Q3/ 2023',
            d:'Die WOW EARN-Benutzerbasis erreicht 500.000.<br/>' +
                'Globale Markenzusammenarbeit.<br/>' +
                'Initiierung von Web3-Offline-Veranstaltungen.'
        },
        data5:{
            q:'Q4/ 2023',
            d:'Die WOW EARN-Benutzerbasis erreicht 1.000.000.<br/>' +
                'Integration von Multi-Szenario-TOKEN- und NFT-Asset-Daten.'
        },
        data6:{
            q:'Q1/ 2024',
            d:`Neue offizielle Website gestartet<br/>
            Täglich aktive Nutzer von DApp überschreiten 100.000<br/>
            Anzahl der Halteadressen in der WOW EARN Public Chain überschreitet 500.000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Öffentliche Beta und Start des auf der WOW EARN Public Chain basierenden DEX<br/>
            Offizieller Start des GameFi-Projekts auf der WOW EARN Public Chain<br/>
            Verbesserung des Benutzererlebnisses des WOW EARN Wallets durch zusätzliche Multi-Chain-Unterstützung<br/>
            Erfolgreicher Start von mehr als 10.000 DApp-Anwendungen<br/>
            Teilnahme an weltweit bekannten Blockchain-Offline-Ausstellungen steigert die Markensichtbarkeit erheblich<br/>
            Entwicklung einer Inkubationsplattform zum Aufbau des Ökosystems der WOW EARN Public Chain`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX strebt an, einen Total Value Locked (TVL) von über 1M U zu überschreiten, Ziel des Kreditprodukts ist es, einen TVL von über 1M U zu überschreiten<br/>
            WOWCoin an zentralisierten Börsen gelistet<br/>
            Entwicklung einer Finanzierungsplattform zur Bereitstellung von Finanzierungshilfen für kreative Projekte<br/>
            Kontinuierliche Produktentwicklung und Einführung von Blockbuster-Anwendungen, einschließlich DApp-Spieleanwendungen und Gamefi-Anwendungen.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Wartung von Market Makern zur Wahrung des Wertes von WOWCoin und kontinuierliche Entwicklung von bahnbrechenden Produkten<br/>
            Deutliche Verbesserung der Benutzerbindung bei peripheren Produkten<br/>
            Umfassende Perfektionierung der WOW EARN DAO-Governance`
        },

    },
    partners:{
        title:'Unsere Partner und Medien'
    },
    downloadWallet : {
        title: "Handeln Sie sicher und geschützt jederzeit. Überall.",
        subtitle: "Handeln Sie zu Hause und unterwegs mit unserer App. Verfügbar im App Store und Google Play Store.",
        downWalletTop: "Herunterladen von",
        donwloadTitle: "Android",
        titleIOS: "IOS",


    },
    footer:{
        social:'Soziale Medien',
        resources:{
            title:'Ressourcen',
            data1:'WhitePaper',
            data2:'Mining',
            data3:'Staking',
            data4:'RoadMap',
        },
        contact:'Kontaktiere uns',
        app:{
            ios:'IOS',
            android:'Android',
            apk:'APK'
        }
    }

}
export default de;
/*德语*/
