const kh = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'ទំព័រដើម',
        Function: 'មុខងារ',
        Mining: 'ការសម្ភារៈ',
        RoadMap: 'ផែនដីកំណត់',
        WhitePaper: 'WhitePaper',
        Blog: 'ប្លក់',
        Task: 'មជ្ឈមណ្ឌលភ្ជាប់ '
    },
    language: 'ភាសា',
    banner: {
        title: 'កុំព្យូទ័រ<br/>ទៅកាន់ Web3',
        des: 'ដោះសោកម្មសិទ្ធិទ្រព្យសម្បត្តិឌីជីថលពិតប្រាកដ៖<br/>វិបផតថលរបស់អ្នកទៅកាន់បទពិសោធន៍ Web3 ដែលមិនអាចប្រៀបផ្ទឹមបាន។',
        btn1: 'ចាប់ផ្ដើម Dapp',
        btn2: 'ទាញយកថុនក្រោយ',
        btn3:'ត្រួតពិនិត្យដោយ Hacken'
    },

    data: {
        data1: 'អ្នកប្រើប្រាស់សកម្មប្រចាំថ្ងៃ',
        data2: 'ប្រទេសដែលគ្របដណ្តប់',
        data3: 'អ្នកប្រើប្រាស់',
        data4: 'ដៃគូយុទ្ធសាស្ត្រ',
        data5: 'អ្នកប្រើប្រាស់សហគមន៍',
    },
    features: {
        title: 'លក្ខណៈពិសេស',
        data1: 'ទិញប្រភេទគូរ',
        data2: 'ការសម្ភារៈ Dapp',
        data3: 'NFTs',
        data4: 'ប្រព័ន្ធ Layer1',
        data5: 'Staking',
        data6: 'ប្ដូរ',
        data7: 'DEX',
        data8:'សហគមន៍'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin គឺជាផ្នែកមួយនៃប្រព័ន្ធអេកូ WOW EARN ដែលជាវេទិកា blockchain ដែលសង្កត់ធ្ងន់លើប្រសិទ្ធភាពខ្ពស់ និងការតភ្ជាប់នៅក្នុងពិភពនៃវិមជ្ឈការ -finance (DeFi) ការភ្នាល់ | ទិន្នផលកសិកម្ម | ការជីកយករ៉ែ។',
        data2: 'ខ្សែស្រួលនៃ WOW EARN គឺជាប្រព័ន្ធ Layer 1 ដែលត្រូវបានរក្សាលំនាំដើមដើម្បីផ្ដល់នូវស្ថានភាពនិងការចងចាំគ្នាសម្រាប់កម្មវិធីដែលផ្អែកលើប្រព័ន្ធគណនីស្វាគមន៍។',
        data3: 'WOW Coin ដើរតួយ៉ាងសំខាន់នៅក្នុងប្រព័ន្ធអេកូនេះ សម្រួលប្រតិបត្តិការ រង្វាន់ និងថ្លៃសេវា ហើយបម្រើជារូបិយប័ណ្ណចម្បងសម្រាប់អន្តរកម្មនៅក្នុងវេទិកា WOW EARN។',
        data4: 'ប្រើប្រាស់ក្នុងការប្ដូរប្រតិបត្តនៃអាសន្នរបស់វា,ដោយសារអត្ថប្រយោជន៍បន្ថែមនៃការប្រតិបត្តន៍ប្រូបាបនិងការវាយតម្លៃក្នុងប្រព័ន្ធកាត់ដោយបើកចុងក្រោយរបស់វាក្នុងប្រព័ន្ធ WOW EARN។',
    },
    mechanism: {
        title: 'ការផ្តល់ដំណើរការសរុប',
        data1: 'សម្រាប់អ្នកសម្ភារៈ 35%',
        data2: 'សម្រាប់ស្រ្តីចំណី 20%',
        data3: 'សហគមន៍ 8%',
        data4: 'ការលក់ជាសាធារណៈ (IDO) 3%',
        data5: 'បន្ថយដឹកជញ្ជូន 14%',
        data6: 'ការទទួលពាណិជ្ជកម្ម 10%',
        data7: 'ការគ្រប់គ្រងតម្លៃទីផ្សារ 5%',
        data8: 'ការប្រានបង្ហាញចំណីស្រ្តី 4%',
        data9: 'អប្បបរមាការជំនួយសហគមន៍ 0.95%',
        data10: 'អប្បបរមាការវាយតម្លៃកសិករ 0.05%',
        total: 'ការផ្តល់សរុប ១ សេដ្ឋកិច្ច',
    },
    roadmap: {
        title: 'ផែនដីអភិវឌ្ឍ',
        data1: {
            q: 'Q4/ 2022',
            d: 'ការបង្កើតក្រុមការងារ WOW EARN<br/>' +
                'ការចេញផ្សាយ WOW EARN Whitepaper និងផែនការអភិវឌ្ឍន៍។'
        },
        data2: {
            q: 'Q1/ 2023',
            d: 'ការធ្វើតេស្តមានជំនួយប្រតិបត្តនៃគំរូ DApp បានជោគជ័យ។<br/>' +
                'ចាប់ផ្ដើមនៃការបង្កើតស្ថានីយ WOW EARN។<br/>' +
                'ការគាំទ្រសម្រាប់ ETH, BSC។<br/>' +
                'បង្វើឲ្យបានការប្រើប្រាស់ USDT staking។<br/>' +
                'មូលហេតុទិញប្រភេទកម្មវិធីរួមសម្រាប់ការអនុវត្តចូលរួមចូលទៅជាមួយពីកម្មវិធីសាស្រ្តគណនេយ្យប្រូបាប។<br/>' +
                'ការធ្វើអនុវត្តនៃការប្រើប្រាស់ស្ថានីយ WOW EARN ដែលបានទទួលសំណើរ 50,000 នាក់។<br/>' +
                'ការបង្កើតការប្រជុំជាមួយសាធារណៈនិងសាធារណៈពិភពលោកចាប់ស្តីសុខសប្បាយបំផុត។'
        },
        data3: {
            q: 'Q2/ 2023',
            d: 'សិក្សារការរុញរៀបបញ្ចប់នៃការប្រតិបត្តនៃកម្មវិធីស៊ីចេក.<br/>' +
                'ការរុញរៀបបញ្ចប់នៃកម្មវិធី DApp ត្រូវបានបញ្ជាក់.<br/>' +
                'ចាប់ផ្ដើមរូបស្លាករបស់ស្ថានដាក់ផ្នែកចុងក្រោយ.<br/>' +
                'ប៉ារ៉ាបទរបស់ WOWCoin ត្រូវបានបំពេញ.<br/>' +
                'ការរុញរៀបបញ្ចប់នៃសម្រាប់អ្នកប្រើប្រាស់ WOW EARN ធ្វើឱ្យតែធ្លាប់ជាមួយមានកម្មវិធីប្រព័ន្ធផ្នែកចូលរួមជាច្រើន។'
        },
        data4: {
            q: 'Q3/ 2023',
            d: 'សម្រាប់អ្នកប្រើប្រាស់នេះដែលប្រើប្រាស់បានប្រតិបត្តនៃកម្មវិធី WOW EARN ត្រូវបានបើកទៅដល់បច្ចេកទេសហាងនិងការដាក់ផ្នែកអន្តរជាតិ Web3។'
        },
        data5: {
            q: 'Q4/ 2023',
            d: 'សម្រាប់អ្នកប្រើប្រាស់នេះដែលប្រើប្រាស់បានប្រតិបត្តនៃកម្មវិធី WOW EARN ត្រូវបានបើកទៅដល់ 1,000,000 នាក់។<br/>' +
                'ការរួមបញ្ចូលគ្នានៃពហុសេណារីយ៉ូ TOKEN និងទិន្នន័យទ្រព្យសកម្ម NFT ។'
        },
        data6: {
            q: 'Q1/ 2024',
            d:`គេហទំព័រផ្លូវការថ្មីបានចេញផ្សាយ<br/>
            អ្នកប្រើប្រាស់សកម្មប្រចាំថ្ងៃរបស់ DApp លើសពី 100,000<br/>
            WOW EARN អាសយដ្ឋានកាន់រូបិយប័ណ្ណខ្សែសង្វាក់សាធារណៈលើសពី 500,000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`DEX ផ្អែកលើខ្សែសង្វាក់សាធារណៈ WOW EARN ចាប់ផ្តើមបេតាសាធារណៈ ហើយត្រូវបានចេញផ្សាយ<br/>
            គម្រោង gamefi នៃខ្សែសង្វាក់សាធារណៈ WOW EARN ត្រូវបានបើកដំណើរការជាផ្លូវការហើយ។<br/>
            កែលម្អបទពិសោធន៍អ្នកប្រើប្រាស់កាបូប WOW EARN និងបន្ថែមការគាំទ្រច្រើនខ្សែ<br/>
            បានចាប់ផ្តើមកម្មវិធី 10,000+ DApp ដោយជោគជ័យ<br/>
            ការចូលរួមក្នុងការតាំងពិពណ៌ក្រៅបណ្តាញ blockchain ដ៏ល្បីល្បាញលើពិភពលោកបង្កើនការយល់ដឹងអំពីម៉ាកយីហោយ៉ាងសំខាន់<br/>
            បង្កើតវេទិកាបណ្ដុះបណ្ដាល និងកែលម្អប្រព័ន្ធអេកូឡូស៊ីនៃខ្សែសង្វាក់សាធារណៈ WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`គោលដៅ DEX TVL លើសពី 1M U ការផ្តល់ប្រាក់កម្ចីផលិតផល TVL លើសពី 1M U<br/>
            WOWCoin ចូលទៅកាន់ការផ្លាស់ប្តូរកណ្តាល<br/>
            បង្កើតវេទិកាហិរញ្ញវត្ថុ ដើម្បីផ្តល់ការគាំទ្រផ្នែកហិរញ្ញវត្ថុសម្រាប់ការច្នៃប្រឌិត<br/>
            ការអភិវឌ្ឍន៍ផលិតផលជាបន្តបន្ទាប់ ការបើកដំណើរការកម្មវិធីទប់ស្កាត់ រួមទាំងកម្មវិធីហ្គេម DApp និងកម្មវិធី Gamefi ។`
        },
        data9:{
            q:'Q4/ 2024',
            d:`ថែរក្សាអ្នកបង្កើតទីផ្សារ រក្សាតម្លៃនៃ WOWCoin និងបន្តអភិវឌ្ឍ និងធ្វើបច្ចុប្បន្នភាពផលិតផលដែលឈានមុខគេ<br/>
            ផលិតផលគ្រឿងកុំព្យូទ័របានធ្វើឱ្យប្រសើរឡើងយ៉ាងខ្លាំងនូវភាពស្អិតរបស់អ្នកប្រើប្រាស់<br/>
            WOW EARN អភិបាលកិច្ច DAO ត្រូវបានកែលម្អយ៉ាងពេញលេញ`
        },
    },
    partners: {
        title: 'សហគមន៍និងវេបសាយរបស់យើង'
    },
    downloadWallet : {
        title: "ធ្វើសម្រាប់ការហត្ថលេខានិងសុវត្ថិភាពនៅពេលណាស់។ ផ្តោតក្នុងការបើកំពង់ផ្លូវ។",
        subtitle: "ដោយប្រើកម្មវិធីរបស់យើងអាចធ្វើសម្រាប់ពីផ្ទៃក្រឡាន និងក្នុងហេតុដែលខាងក្រៅ។ មានធនាគាររបស់ App Store និង Google Play Store។",
        downWalletTop: "ពីទំព័របន្ទាប់",
        donwloadTitle: "ប្រព័ន្ធប្រតិបត្តិការ ",
    titleIOS: "ប្រព័ន្ធប្រតិបត្តិការ iOS",


    },
    footer: {
        social: 'ប្រព័ន្ធបង្រៀនសង្គម',
        resources: {
            title: 'ឧបករណ៍',
            data1: 'WhitePaper',
            data2: 'MINGING',
            data3: 'ការស្តារយោង',
            data4: 'ផែនដីកំណត់',
        },
        contact: 'ទាក់ទងយើង',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}
export default kh;
/*高棉语*/
