import { createRouter, createWebHashHistory } from 'vue-router'
import DownloadWallet from './components/DownloadWallet.vue'
import DownloadWalletTwo from './components/DownloadWalletTwo.vue'
import HelloWorld from './components/HelloWorld.vue'
import BlogDetails from '@/components/BlogDetails.vue';
import BlogPage from '@/components/BlogPage.vue';

const routes = [
  { path: '/download-wallet', component: DownloadWallet },
  { path: '/download-wallet-two', component: DownloadWalletTwo },
  { path: '/', component: HelloWorld },
  { path: '/blog', component: BlogPage },
  { path: '/blog-details', component: BlogDetails }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
