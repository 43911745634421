const zh_TW = {
    brand: 'WOW ERAN',
    nav: {
        Home: '首頁',
        Function: '特性',
        Mining: '挖礦',
        RoadMap: '路線圖',
        WhitePaper: '白皮書',
        Blog: '部落格',
        Task: '任務中心'
    },
    language: '語言',
    banner: {
        title: '通往<br />Web3的門戶',
        des: '解鎖真正的數字資產所有權：<br />帶給您無與倫比的Web3體驗',
        btn1: '啟動DApp',
        btn2: '下載錢包',
        btn3:' 由 Hacken 審計'
    },

    data: {
        data1: '每日活躍用戶',
        data2: '覆蓋國家',
        data3: '用戶',
        data4: '戰略合作夥伴',
        data5: '社區',
    },
    features: {
        title: '特性',
        data1: '購買加密貨幣',
        data2: '挖礦DApp',
        data3: 'NFTs',
        data4: 'Layer1 區塊鏈',
        data5: '質押',
        data6: '兌換',
        data7: '去中心化交易所',
        data8:'社群'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin 是 WOW EARN 生態系統的一部分，它是一個強調高效性和連接性的區塊鏈平台，致力於去中心化金融（DeFi）領域，包括質押、流動性挖礦和挖礦。',
        data2: 'WOW EARN鏈是一種開創性的Layer 1平台，旨在為各種基於區塊鏈的應用程序提供無與倫比的效率和可擴展性，以滿足用戶和開發者的需求。',
        data3: 'WOW Coin 在這一生態系統中發揮著至關重要的作用，促進交易、獎勵和費用，並作為 WOW EARN 平台內交互的主要貨幣。',
        data4: '其在幣種轉移中的應用，如在區塊鏈瀏覽器的交易部分詳細說明的那樣，展示了它在WOW EARN網絡內啟用區塊鏈操作和價值交換方面的基本效用。',

    },
    mechanism: {
        title: '總供應量',
        data1: '用戶挖礦 35%',
        data2: '節點挖礦 20%',
        data3: '基金會 8%',
        data4: '公募（IDO） 3%',
        data5: '種子輪 14%',
        data6: '運營發展 10%',
        data7: '市值管理 5%',
        data8: '質押獎勵 4%',
        data9: '社區激勵 0.95%',
        data10: '空投獎勵 0.05%',
        total: '10億總供應量'
    },
    roadmap: {
        title: '發展路線圖',
        data1: {
            q: 'Q4/ 2022',
            d: 'WOW EARN 團隊組建。<br />' +
                'WOW EARN 白皮書發布，並拟定發展計劃'
        },
        data2: {
            q: 'Q1/ 2023',
            d: 'Dapp項目測試成功<br />' +
                'WOW EARN運行了<br />' +
                '支持ETH、BSC<br />' +
                '開放USDT質押<br />' +
                '獨特的推薦邀請機制<br />' +
                'WOW EARN用戶達到50,000人<br />' +
                '與主要區塊鏈媒體建立了合作夥伴關係'

        },
        data3: {
            q: 'Q2/ 2023',
            d: '智能合約審計通過<br />' +
                'DApp合約審核通過<br />' +
                '新品牌形象發布<br />' +
                'WOWCoin公開發行<br />' +
                'WOW EARN用戶達到100,000人<br />' +
                '支持跨鏈交易<br />' +
                '與眾多的DeFi項目形成了戰略合作夥伴關係'
        },
        data4: {
            q: 'Q3/ 2023',
            d: 'WOW EARN用戶達到500,0000人<br />' +
                '全球品牌合作<br />' +
                'Web3線下活動啟動'
        },
        data5: {
            q: 'Q4/ 2023',
            d: 'WOW EARN用戶達到1,000,0000人<br />' +
                '打通多場景TOKEN和NFT資產數據'
        },
        data6: {
            q: 'Q1/ 2024',
            d:`新的官方網站發布<br />
            DApp每日活躍用戶突破100,000人<br />
            WOW EARN公鏈持幣地址突破500,000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`基於WOW EARN公鏈的DEX開啟公測，並發布<br />
            WOW EARN公鏈的gamefi項目正式啟動<br />
            提升WOW EARN錢包用戶體驗，增加多鏈支持<br />
            成功上線10,000+DApp應用<br />
            參加全球知名區塊鏈線下展會顯著提升品牌知名度<br />
            打造孵化平台，完善WOW EARN公鏈的生態系統`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX目標TVL突破1M U,借貸產品目標TVL突破1M U<br />
            WOWCoin登錄中心化交易所<br />
            打造融資平台，為創意提供資金支持<br />
            持續產品開發，推出爆款應用，包括DApp遊戲應用，Gamefi應用`
        },
        data9:{
            q:'Q4/ 2024',
            d:`維護做市商，保持WOWCoin價值以及持續開發更新有突破性的產品<br />
            周邊產品有顯著的用戶粘性提升<br />
            WOW EARN DAO治理全面完善`
        },

    },
    partners: {
        title: '我們的合作夥伴和媒體'
    },
    downloadWallet : {
        title: "安全地交易，隨時隨地。",
        subtitle: "使用我們的應用程式，在家裡或在外出時進行交易。在 App Store 和 Google Play Store 上提供。",
        downWalletTop: "從下載",
        donwloadTitle: "安卓",
        titleIOS: "IOS",


    },
    footer: {
        social: '社交媒體',
        resources: {
            title: '資源',
            data1: '白皮書',
            data2: '挖礦',
            data3: '質押',
            data4: '歷史進程',
        },
        contact: '聯繫我們',
        app: {
            ios: '蘋果版',
            android: '安卓版',
            apk: 'APK'
        }
    }

}
export default zh_TW;

/*繁体中文*/
