<template>
  <div style="background-color: #0c0c0c">
    <nav
      class="navbar-header pa-blog p-0 navbar-dark bg-transparent static-top"
    >
      <a class="navbar-brand-blog d-flex" href="#"
        ><img
          alt=""
          src="../assets/images/wow-blog.svg"
          class="navimg"
          style="width: 230px"
          @click="redirectToHome()"
      /></a>
    </nav>
    <div>
      <section class="bg-image-blog">
    <div class="text-bg">
      <div class="typed-text container text-left justify-content-start">
        <span id="typed-text" class="animated-text-effect">{{ typedText }}</span>
        <span class="typed-cursor">|</span>
      </div>
    </div>
  </section>
      <div class="container blog-feed" style="background-color: #0c0c0c" >
        <div class="w-100">
          <img src="../assets/images/sample-slot-3.webp" class="feedimg" />
        </div>

        <div class="card-wrp">
          <div class="feed-card" @click="redirectToblogdetails(item)" v-for="item in list" :key="item.configKey">
            <img :src="item.logUrl" />
            <time class="date-card">{{ dayjs(item.createTime).format('MM/DD/YYYY') }}</time>
            <h3 class="card-heading">
              {{ item.title }}
            </h3>
            <div class="d-flex">
              <a  class="moretag pa-hvr-sweep-to-right">Full story</a>
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation example d-flex">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" @click="setPrev" aria-label="Previous">
                <span aria-hidden="true"><i class="fa fa-caret-left"></i></span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item" :class="{active: item == page}" v-for="item in this.allpage" :key="item">
              <a class="page-link" @click="page = item">{{ item }}</a>
            </li>
            <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li> -->
            <li class="page-item">
              <a class="page-link" @click="setNext" aria-label="Next">
                <span aria-hidden="true"
                  ><i class="fa fa-caret-right"></i
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>

        <footer class="blog-footer">
          <div class="container col-12 text-center mb-1" style="margin-bottom:25px">
            <p>
              @2022-204 Copy WOWEARN All Rights Reserved<a
                target="_blank"
              ></a>
            </p>
          </div>
          <div class="pa-back-to-top-wrap pa-backtotop-visible">
            <a class="pa-back-to-top" @click="toTop"
              ><span class="pa-back-to-top"></span
            ></a>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import "@/assets/css/blog.css";
export default {
  name: "BlogPage",
  methods: {
    dayjs,
    async requestList(){
      try {
        const res = await fetch('https://appapi.wowearn.io/newRead/queryPage?lang=en&pageNumber=' + this.page + '&pageSize=9')
        const json = await res.json()
        this.list = json.result.records
        this.allpage = json.result.pages
        this.page = json.result.current
        this.toTop()
      } catch (error) {
        console.log(error)
      }
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    setPrev() {
      this.page = Math.max(this.page - 1, 1)
    },
    setNext() {
      this.page = Math.min(this.page + 1, this.allpage)
    },
    redirectToblogdetails(item){
      localStorage.setItem('contentHTML', item.content)
      this.$router.push('/blog-details');
      this.toTop()
    },
    redirectToHome(){
      this.$router.push('/');
    },
    // Function to simulate typing effect
    type() {
      if (!this.isTyping) return; // Check if typing animation should continue
      const currentSentence = this.sentences[this.sentenceIndex];
      if (this.index < currentSentence.length) {
        this.typedText += currentSentence.charAt(this.index);
        this.index++;
        setTimeout(this.type, this.typingSpeed); // Adjust typing speed as needed
      } else {
        // Check if there are more sentences to type
        if (this.sentenceIndex < this.sentences.length - 1) {
          setTimeout(this.changeSentence, this.delayBetweenSentences); // Change sentence after delay
        } else {
          setTimeout(this.reset, this.delayAfterCompletion); // Reset after completing all sentences
        }
      }
    },
    // Function to change the current sentence
    changeSentence() {
      this.sentenceIndex++;
      this.index = 0; // Reset index for typing new sentence
      this.typedText = ""; // Clear typed text
      this.type(); // Start typing new sentence
    },
    // Function to reset typing after completing all sentences
    reset() {
      if (this.isTyping) {
        this.sentenceIndex = 0; // Reset sentence index
        this.typedText = ""; // Clear typed text
        this.index = 0; // Reset index for typing new sentence
        this.type(); // Start typing first sentence again
      }
    }
  },
  data() {
    return {
      list: [],
      page: 1,
      allpage: 1,
      // Simple array of strings
      pages1: ["?page=1", "?page=2", "?page=3"],
      // Array of objects with string links
      pages2: [
        { link: "?page=1", text: "One" },
        { link: "?page=2", text: "Two" },
        { link: "?page=3", text: "Three" },
      ],
      // Array of objects with router `to` locations
      pages3: [
        { link: { query: { page: 1 } }, text: "Page 1" },
        { link: { query: { page: 2 } }, text: "Page 2" },
        { link: { query: { page: 3 } }, text: "Page 3" },
      ],
      typedText: "", // To store the typed text
      sentences: ["Here's our latest news.", "Wow Coin Blog"], // Sentences to be typed
      sentenceIndex: 0, // Index to keep track of the current sentence
      index: 0, // Index to keep track of the characters typed
      typingSpeed: 90, // Typing speed in milliseconds
      delayBetweenSentences: 3000, // Delay between sentences in milliseconds
      delayAfterCompletion: 2000, // Delay after completing one sentence in milliseconds
      isTyping: true
    };
  },
  watch: {
    page() {
      this.requestList()
    }
  },
  mounted() {
    // Start typing animation when component is mounted
    this.type();
    this.requestList()
  },
  beforeUnmount() {
    // Set the typing flag to false before unmounting
    this.isTyping = false;
  }
};
</script>

<style scoped>
.pagination{
  flex-wrap: wrap;
}
.animated-text-effect {
  display: inline-block;
  overflow: hidden;
  /* border-right: 0.08em solid orange; Cursor style */
  white-space: nowrap;
  letter-spacing: 0.15em; /* Adjust as needed */
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
