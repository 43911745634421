const fr = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Accueil',
        Function: 'Fonction',
        Mining: 'Minage',
        RoadMap: 'RoadMap',
        WhitePaper: 'WhitePaper',
        Blog: 'Blog',
        Task: 'Centre de tâches'
    },
    language: 'Langue',
    banner: {
        title: 'Portail<br/>Vers Web3',
        des: 'Déverrouillez la véritable propriété d\'actifs numériques :<br/>Votre portail vers des expériences Web3 inégalées.',
        btn1: 'Lancer Dapp',
        btn2: 'Télécharger le Portefeuille',
        btn3:'Audité par Hacken'
    },

    data: {
        data1: 'Utilisateurs quotidiens',
        data2: 'Pays couverts',
        data3: 'Utilisateur',
        data4: 'Partenaires clés',
        data5: 'Communauté',
    },
    features: {
        title: 'FONCTIONNALITÉS',
        data1: 'Acheter des cryptos',
        data2: 'Dapp de Minage',
        data3: 'NFTs',
        data4: 'Blockchain Layer1',
        data5: 'Staking',
        data6: 'Échanger',
        data7: 'DEX',
        data8:'Communauté'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin fait partie de l\'écosystème WOW EARN, une plateforme blockchain qui met l\'accent sur l\'efficacité et la connectivité élevées dans le monde de la finance décentralisée (DeFi), mise en jeu | agriculture de rendement | minage.',
        data2: 'La chaîne WOW EARN est une plateforme Layer 1 pionnière conçue pour offrir aux utilisateurs et aux développeurs une efficacité et une évolutivité inégalées pour diverses applications basées sur la blockchain.',
        data3: 'La WOW Coin joue un rôle crucial au sein de cet écosystème, facilitant les transactions, les récompenses et les frais, et servant de monnaie principale pour les interactions au sein de la plateforme WOW EARN.',
        data4: 'Son utilisation dans les transferts de pièces, comme détaillé dans la section des transactions de l\'explorateur, démontre son utilité fondamentale dans la possibilité d\'effectuer des opérations blockchain et d\'échanger de la valeur au sein du réseau WOW EARN.',
    },
    mechanism: {
        title: 'Offre totale',
        data1: 'Minage utilisateur 35%',
        data2: 'Minage de nœuds 20%',
        data3: 'Fondation 8%',
        data4: 'Offre publique (IDO) 3%',
        data5: 'Tour de table 14%',
        data6: 'Développement opérationnel 10%',
        data7: 'Gestion de la valeur marchande 5%',
        data8: 'Récompense de mise 4%',
        data9: 'Incitation à la communauté 0,95%',
        data10: 'Récompense de largage 0,05%',
        total: 'Offre totale de 1 milliard',
    },
    roadmap: {
        title: 'Feuille de route de développement',
        data1: {
            q: 'T4/ 2022',
            d: 'Établissement de l\'équipe WOW EARN.<br/>' +
                'Publication du Livre blanc et du plan de développement de WOW EARN.'
        },
        data2: {
            q: 'T1/ 2023',
            d: 'Test réussi du projet DApp.<br/>' +
                'Lancement de WOW EARN.<br/>' +
                'Prise en charge de ETH, BSC.<br/>' +
                'Introduction de la mise en jeu USDT.<br/>' +
                'Mécanisme unique d\'invitation de parrainage.<br/>' +
                'La base d\'utilisateurs de WOW EARN atteint 50 000.<br/>' +
                'Établissement de partenariats avec les principaux médias blockchain.'
        },
        data3: {
            q: 'T2/ 2023',
            d: 'Audit des contrats intelligents terminé.<br/>' +
                'Audit réussi du contrat DApp.<br/>' +
                'Lancement de la nouvelle image de marque.<br/>' +
                'Publication publique de WOWCoin.<br/>' +
                'La base d\'utilisateurs de WOW EARN atteint 100 000.<br/>' +
                'Prise en charge des transactions inter-chaînes.<br/>' +
                'Partenariats stratégiques formés avec de nombreux projets DeFi.'
        },
        data4: {
            q: 'T3/ 2023',
            d: 'La base d\'utilisateurs de WOW EARN atteint 500 000.<br/>' +
                'Collaborations de marque mondiale.<br/>' +
                'Initiation des événements hors ligne Web3.'
        },
        data5: {
            q: 'T4/ 2023',
            d: 'La base d\'utilisateurs de WOW EARN atteint 1 000 000.<br/>' +
                'Intégration de données d\'actifs TOKEN et NFT multi-scénarios.'
        },
        data6: {
            q: 'T1/ 2024',
            d:`Lancement du nouveau site web officiel<br/>
            Les utilisateurs actifs quotidiens de DApp dépassent les 100 000<br/>
            Les adresses de détention de la chaîne publique WOW EARN dépassent les 500 000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Bêta publique et lancement du DEX basé sur la chaîne publique WOW EARN<br/>
            Lancement officiel du projet GameFi sur la chaîne publique WOW EARN<br/>
            Amélioration de l'expérience utilisateur du portefeuille WOW EARN avec le support multi-chaînes ajouté<br/>
            Lancement réussi de plus de 10 000 applications DApp<br/>
            La participation à des expositions de blockchain hors ligne de renommée mondiale augmente significativement la visibilité de la marque<br/>
            Développement d'une plateforme d'incubation pour construire l'écosystème de la chaîne publique WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX vise à dépasser une Valeur Totale Bloquée (TVL) de 1M U, les produits de prêt visent à dépasser une TVL de 1M U<br/>
            WOWCoin est coté sur les échanges centralisés<br/>
            Développement d'une plateforme de financement pour fournir un soutien financier aux projets créatifs<br/>
            Développement continu de produits, lancement d'applications à succès, y compris des applications de jeux DApp et des applications Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Maintenance des market makers pour préserver la valeur de WOWCoin et développement continu de produits innovants<br/>
            Amélioration significative de l'adhésion des utilisateurs pour les produits périphériques<br/>
            Perfectionnement complet de la gouvernance de WOW EARN DAO`
        },
    },
    partners: {
        title: 'Nos partenaires et médias'
    },
    downloadWallet : {
        title: "Commercez en toute sécurité et en toute sécurité à tout moment. Partout.",
    subtitle: "Commercez chez vous et en déplacement avec notre application. Disponible sur l'App Store et Google Play Store.",
    downWalletTop: "Télécharger depuis",
    donwloadTitle: "Android",
    titleIOS: "IOS",


    },
    footer: {
        social: 'Médias sociaux',
        resources: {
            title: 'Ressourcen',
            data1: 'WhitePaper',
            data2: 'Mining',
            data3: 'Staking',
            data4: 'RoadMap',
        },
        contact: 'Contactez-nous',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}
export default fr;

/*法语*/
