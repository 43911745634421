const bd = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'হোম',
        Function: 'ফাংশন',
        Mining: 'খনন',
        RoadMap: 'রোডম্যাপ',
        WhitePaper: 'সাদা কাগজ',
        Blog: 'ব্লগ',
        Task: 'টাস্ক সেন্টার',

    },
    language: 'ভাষা',
    banner: {
        title: 'ওয়েব 3-এ গেটওয়ে<br/>তে',
        des: 'সত্যিমিতিকে আনলক করুন ডিজিটাল সম্পত্তির মালিকানার: <br/>অদ্বিতীয় ওয়েব 3 অভিজ্ঞতার প্রবেশদ্বার।',
        btn1: 'Dapp লঞ্চ',
        btn2: 'ওয়ালেট ডাউনলোড',
        btn3:'হ্যাকেন দ্বারা পরীক্ষামূলক'
    },
    data: {
        data1: 'দৈনিক সক্রিয় ব্যবহারকারী',
        data2: 'প্রযুক্তিসমূহের দেশ',
        data3: 'ব্যবহারকারী',
        data4: 'স্ট্রেটেজিক অংশীদার',
        data5: 'সম্প্রদায় ব্যবহারকারী',
    },
    features: {
        title: 'বৈশিষ্ট্যসমূহ',
        data1: 'ক্রিপ্টো ক্রয় করুন',
        data2: 'খনন ড্যাপ',
        data3: 'NFT গুলি',
        data4: 'লেয়ার 1 ব্লকচেন',
        data5: 'স্টেকিং',
        data6: 'পরিবর্তন',
        data7: 'DEX',
        data8:'কমিউনিটি'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin হল WOW EARN ইকোসিস্টেমের একটি অংশ, যা ডিসেন্ট্রালাইজড ফাইন্যান্স (DeFi), স্টেকিং | ইয়িল্ড ফার্মিং | মাইনিং এর বিশ্বে উচ্চ দক্ষতা এবং সংযোগতা জোর দেয় এমন একটি ব্লকচেইন প্ল্যাটফর্ম।',
        data2: 'WOW EARN চেইন হল একটি অগ্রগামী Layer 1 প্ল্যাটফর্ম যা বিভিন্ন ব্লকচেইন-ভিত্তিক অ্যাপ্লিকেশনগুলির জন্য ব্যবহারকারীদের এবং ডেভেলপারদের অতুলনীয় দক্ষতা এবং স্কেলেবিলিটি অফার করার জন্য ডিজাইন করা হয়েছে।',
        data3: 'WOW Coin এই ইকোসিস্টেমের মধ্যে একটি গুরুত্বপূর্ণ ভূমিকা পালন করে, লেনদেন, পুরস্কার এবং ফি সহজতর করে, এবং WOW EARN প্ল্যাটফর্মের মধ্যে মিথস্ক্রিয়াগুলির জন্য প্রাথমিক মুদ্রা হিসেবে কাজ করে।',
        data4: 'WOW Coin এই ইকোসিস্টেমের মধ্যে একটি গুরুত্বপূর্ণ ভূমিকা পালন করে, লেনদেন, পুরস্কার এবং ফি সুবিধাজনক করে, এবং WOW EARN প্ল্যাটফর্মের মধ্যে মিথস্ক্রিয়ার জন্য প্রাথমিক মুদ্রা হিসাবে কাজ করে।',

    },
    mechanism: {
        title: 'মোট সরবরাহ',
        data1: 'ব্যবহারকারী খনন 35%',
        data2: 'নোড খনন 20%',
        data3: 'ফাউন্ডেশন 8%',
        data4: 'পাবলিক অফারিং (আইডিও) 3%',
        data5: 'বীজ রাউন্ড 14%',
        data6: 'অপারেশনাল ডেভেলপমেন্ট 10%',
        data7: 'মার্কেট মান ব্যবস্থাপনা 5%',
        data8: 'স্টেকিং পুরস্কার 4%',
        data9: 'সম্প্রদায় ইনসেন্টিভ 0.95%',
        data10: 'এয়ারড্রপ পুরস্কার 0.05%',
        total: '10 বিলিয়ন মোট সরবরাহ',
    },
    roadmap: {
        title: 'ডেভেলপমেন্ট রোডম্যাপ',
        data1: {
            q: 'Q4/ 2022',
            d: 'WOW EARN দলের স্থাপনা।<br/>' +
                'WOW EARN সাদা পত্রিকা এবং উন্নতি পরিকল্পনা প্রকাশ।'
        },
        data2: {
            q: 'Q1/ 2023',
            d: 'DApp প্রকল্পের সফল পরীক্ষা।<br/>' +
                'WOW EARN লঞ্চ।<br/>' +
                'ETH, BSC এর সমর্থন করুন।<br/>' +
                'USDT স্টেকিং উত্তোলন এর পরিচিতি।<br/>' +
                'অনন্য রেফারেন্স আমন্ত্রণ মেকানিজম।<br/>' +
                'WOW EARN ব্যবহারকারী বেস 50,000 পৌঁছে।<br/>' +
                'মুখ্য ব্লকচেন মিডিয়া আউটলেটের সাথে যৌথ মিলন স্থাপন।'
        },
        data3: {
            q: 'Q2/ 2023',
            d: 'স্মার্ট চুক্তি পরিকল্পনা সমাপ্ত হয়েছে।<br/>' +
                'DApp চুক্তি পরীক্ষা পাস করেছে।<br/>' +
                'নতুন ব্র্যান্ড ইমেজ লঞ্চ করা হয়েছে।<br/>' +
                'WOWCoin এর পাবলিক মুক্তি।<br/>' +
                'WOW EARN ব্যবহারকারী বেস 100,000 পৌঁছে।<br/>' +
                'ক্রস-চেইন লেনদেন সমর্থন।<br/>' +
                'নানান ডিফাই প্রকল্পের সাথে রণনীতি সম্প্রদায় স্থাপন।'
        },
        data4: {
            q: 'Q3/ 2023',
            d: 'WOW EARN ব্যবহারকারী বেস 500,000 পৌঁছে।<br/>' +
                'বিশ্বব্যাপী ব্র্যান্ড সহযোগিতা।<br/>' +
                'Web3 অফলাইন ঘটনার আরম্ভ।'
        },
        data5: {
            q: 'Q4/ 2023',
            d: 'WOW EARN ব্যবহারকারী বেস 1,000,000 পৌঁছে।<br/>' +
                'মাল্টি-সিনেরিও টোকেন এবং NFT সম্পত্তির উপাদান সংযোজন।'
        },
        data6: {
            q: 'Q1/ 2024',
            d:`নতুন অফিসিয়াল ওয়েবসাইট চালু<br/>
            DApp এর দৈনিক সক্রিয় ব্যবহারকারীর সংখ্যা ১,০০,০০০ ছাড়িয়ে গেছে<br/>
            WOW EARN পাবলিক চেইনের হোল্ডিং অ্যাড্রেসের সংখ্যা ৫,০০,০০০ ছাড়িয়ে গেছে`
        },
        data7:{
            q:'Q2/ 2024',
            d:`WOW EARN পাবলিক চেইন ভিত্তিক DEX এর পাবলিক বেটা শুরু এবং চালু<br/>
            WOW EARN পাবলিক চেইনের gamefi প্রজেক্ট অফিসিয়ালি শুরু হয়েছে<br/>
            WOW EARN ওয়ালেটের ব্যবহারকারী অভিজ্ঞতা উন্নতি সহ মাল্টি-চেইন সাপোর্ট যোগ করা হয়েছে<br/>
            ১০,০০০+ DApp অ্যাপ্লিকেশন সফলভাবে লঞ্চ করা হয়েছে<br/>
            বিশ্বব্যাপী পরিচিত ব্লকচেইন অফলাইন এক্সপোতে অংশগ্রহণ ব্র্যান্ডের দৃশ্যমানতা উল্লেখযোগ্যভাবে বৃদ্ধি করেছে<br/>
            ইনকিউবেশন প্ল্যাটফর্ম তৈরি করে WOW EARN পাবলিক চেইনের ইকোসিস্টেম উন্নত করা হয়েছে`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX এর লক্ষ্য TVL 1M U ছাড়িয়ে যাওয়া, ঋণ প্রকল্পের লক্ষ্য TVL 1M U ছাড়িয়ে যাওয়া<br/>
            WOWCoin সেন্ট্রালাইজড এক্সচেঞ্জে লিস্টেড হয়েছে<br/>
            সৃজনশীল প্রকল্পের জন্য অর্থায়নের সহায়তা প্রদানের জন্য অর্থায়ন প্ল্যাটফর্ম তৈরি করা<br/>
            অবিরাম পণ্য বিকাশ, ব্লকবাস্টার অ্যাপ্লিকেশন চালু করা, যার মধ্যে DApp গেমিং অ্যাপ্লিকেশন এবং Gamefi অ্যাপ্লিকেশন অন্তর্ভুক্ত।`
        },
        data9:{
            q:'Q4/ 2024',
            d:`মার্কেট মেকারদের রক্ষণাবেক্ষণ, WOWCoin এর মূল্য বজায় রাখা এবং নিরবচ্ছিন্নভাবে নতুন এবং উদ্ভাবনী পণ্য উন্নয়ন<br/>
            পার্শ্বজ পণ্যগুলিতে ব্যবহারকারীদের আঠালোতা স্পষ্টভাবে বৃদ্ধি<br/>
            WOW EARN DAO গভর্নেন্স সম্পূর্ণরূপে উন্নতি করা হয়েছে`
        },

    },
    partners: {
        title: 'আমাদের অংশীদার এবং মিডিয়া'
    },
    downloadWallet : {
        title: "নিরাপদভাবে ও নিরাপদে বাণিজ্য করুন যে কোন সময়। যে কোন জায়গায়।",
        subtitle: "আমাদের অ্যাপ ব্যবহার করে বা বের হোক ঘরে। অ্যাপ স্টোর এবং গুগল প্লে স্টোর মাধ্যমে উপলব্ধ।",
        downWalletTop: "ডাউনলোড করুন",
        donwloadTitle: "অ্যান্ড্রয়েড",
        titleIOS: "আইওএস",

    },
    footer: {
        social: 'সোশ্যাল মিডিয়া',
        resources: {
            title: 'সম্পদ',
            data1: 'সাদা কাগজ',
            data2: 'MINGING',
            data3: 'স্টেকিং',
            data4: 'রোডম্যাপ',
        },
        contact: 'যোগাযোগ করুন',
        app: {
            ios: 'IOS',
            android: 'অ্যান্ড্রয়েড',
            apk: 'APK'
        }
    }

}
export default bd;
/*孟加拉语*/
