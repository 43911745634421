const zh = {
    brand:'WOW ERAN',
    nav:{
        Home:'首页',
        Function:'特性',
        Mining:'挖矿',
        RoadMap:'路线图',
        WhitePaper:'白皮书',
        Blog:'博客',
        Task: '任务中心 '
    },
    language:'语言',
    banner:{
        title:'通往<br />Web3的门户',
        des:'解锁真正的数字资产所有权：<br />带给您无与伦比的Web3体验',
        btn1:'启动DApp',
        btn2:'下载钱包',
        btn3:' 由 Hacken 审计'
    },

    data:{
        data1:'每日活跃用户',
        data2:'覆盖国家',
        data3:'用户',
        data4:'战略合作伙伴',
        data5:'社区',
    },
    features:{
        title:'特性',
        data1:'购买加密货币',
        data2:'挖矿DApp',
        data3:'NFTs',
        data4:'Layer1 区块链',
        data5:'质押',
        data6:'兑换',
        data7:'去中心化交易所',
        data8:'社区'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin 是 WOW EARN 生态系统的一部分，它是一个强调高效性和连接性的区块链平台，致力于去中心化金融（DeFi）领域，包括质押、流动性挖矿和挖矿。',
        data2:'WOW EARN链是一种开创性的Layer 1平台，旨在为各种基于区块链的应用程序提供无与伦比的效率和可扩展性，以满足用户和开发者的需求。',
        data3:'WOW Coin 在这一生态系统中发挥着至关重要的作用，促进交易、奖励和费用，并作为 WOW EARN 平台内交互的主要货币。',
        data4:'其在币种转移中的应用，如在区块链浏览器的交易部分详细说明的那样，展示了它在WOW EARN网络内启用区块链操作和价值交换方面的基本效用。',

    },
    mechanism:{
        title:'总发行量',
        data1:'用户挖矿 35%',
        data2:'节点挖矿 20%',
        data3:'基金会 8%',
        data4:'公募（IDO） 3%',
        data5:'种子轮 14%',
        data6:'运营发展 10%',
        data7:'市值管理 5%',
        data8:'质押奖励 4%',
        data9:'社区激励 0.95%',
        data10:'空投奖励 0.05% ',
        total:'总供应量10亿枚'
    },
    roadmap:{
        title:'发展路线图',
        data1:{
            q:'Q4/ 2022',
            d:'WOW EARN 团队组建。<br/>' +
                'WOW EARN 白皮书发布，并拟定发展计划'
        },
        data2:{
            q:'Q1/ 2023',
            d:'Dapp项目测试成功<br />' +
                'WOW EARN运行了<br />' +
                '支持ETH、BSC<br />' +
                '开放USDT质押<br />' +
                '独特的推荐邀请机制<br />' +
                'WOW EARN用户达到50,000人<br />' +
                '与主要区块链媒体建立了合作伙伴关系'

        },
        data3:{
            q:'Q2/ 2023',
            d:'智能合约审计通过<br />' +
                'DApp合约审核通过<br />' +
                '新品牌形象发布<br />' +
                'WOWCoin公开发行<br />' +
                'WOW EARN用户达到100,000人<br />' +
                '支持跨链交易<br />' +
                '与众多的DeFi项目形成了战略合作伙伴关系'
        },
        data4:{
            q:'Q3/ 2023',
            d:'WOW EARN用户达到500,0000人<br />' +
                '全球品牌合作<br />' +
                'Web3线下活动启动'
        },
        data5:{
            q:'Q4/ 2023',
            d:'WOW EARN用户达到1,000,0000人<br />' +
                '打通多场景TOKEN和NFT资产数据'
        },
        data6:{
            q:'Q1/ 2024',
            d:`新的官网发布<br />
            DApp日活跃用户突破100,000人<br />
            WOW EARN公链持币地址突破500,000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`基于WOW EARN公链的DEX开启公测，并发布<br />
            WOW EARN公链的gamefi项目正式启动<br />
            提高WOW EARN钱包用户体验，增加多链支持<br />
            成功上线10,000+DApp应用<br />
            参加全球知名区块链线下展会显著提升品牌知名度<br />
            打造孵化平台，完善WOW EARN公链的生态系统`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX目标TVL突破1M U,借贷产品目标TVL突破1M U<br />
            WOWCoin登录中心化交易所<br />
            打造融资平台，为创意提供资金支持<br />
            持续产品开发，推出爆款应用，包括DApp游戏应用，Gamefi应用`
        },
        data9:{
            q:'Q4/ 2024',
            d:`维护做市商，保持WOWCoin价值以及持续开发更新有突破性的产品<br />
            周边产品有显著的用户粘性提升<br />
            WOW EARN DAO治理全面完善`
        },

    },
    partners:{
        title:'我们的合作伙伴和媒体'
    },
    downloadWallet : {
        title: "安全地交易，随时随地。",
        subtitle: "使用我们的应用程序，在家里或在外出时进行交易。在 App Store 和 Google Play Store 上提供。",
        downWalletTop: "从下载",
        donwloadTitle: "安卓",
        titleIOS: "IOS",


    },
    footer:{
        social:'社交媒体',
        resources:{
            title:'资源',
            data1:'白皮书',
            data2:'挖矿',
            data3:'质押',
            data4:'历史进程',
        },
        contact:'联系我们',
        app:{
            ios:'苹果版',
            android:'安卓版',
            apk:'APK'
        }
    }

}
export default zh;
