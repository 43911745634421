const cz = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Domů',
        Function: 'Funkce',
        Mining: 'Těžba',
        RoadMap: 'Plán',
        WhitePaper: 'WhitePaper',
        Blog: 'Blog',
        Task: 'Centrum úloh',
    },
    language: 'Jazyk',
    banner: {
        title: 'Brána<br/>Do Web3',
        des: 'Odemkněte skutečné vlastnictví digitálních aktiv:<br/>Váš portál k nepřekonatelným zkušenostem Web3.',
        btn1: 'Spustit Dapp',
        btn2: 'Stáhnout peněženku',
        btn3:'Auditováno Hackenem'
    },
    data: {
        data1: 'Denní aktivní uživatelé',
        data2: 'Pokryté země',
        data3: 'Uživatel',
        data4: 'Strategičtí partneři',
        data5: 'Uživatelé komunity',
    },
    features: {
        title: 'FUNKCE',
        data1: 'Koupit Krypto',
        data2: 'Těžební Dapp',
        data3: 'NFTs',
        data4: 'Layer1 Blockchain',
        data5: 'Staking',
        data6: 'Swap',
        data7: 'DEX',
        data8:'Společenství'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin je součástí ekosystému WOW EARN, blockchainové platformy, která klade důraz na vysokou efektivitu a propojení ve světě decentralizovaných financí (DeFi), staking | yield farming | mining.',
        data2: 'Řetězec WOW EARN je průkopnická platforma 1. úrovně navržená tak, aby uživatelům a vývojářům nabízela nepřekonatelnou efektivitu a škálovatelnost pro různé aplikace založené na blockchainu.',
        data3: 'WOW Coin hraje v tomto ekosystému klíčovou roli, usnadňuje transakce, odměny a poplatky a slouží jako primární měna pro interakce v rámci platformy WOW EARN.',
        data4: 'Její použití při převodech mincí, jak je podrobně popsáno v sekci transakcí průzkumníka, ukazuje její základní užitečnost při umožňování operací s blockchainem a výměny hodnoty v rámci sítě WOW EARN.',

    },
    mechanism: {
        title: 'Celková nabídka',
        data1: 'Uživatelské těžení 35%',
        data2: 'Těžba uzlu 20%',
        data3: 'Zakládající 8%',
        data4: 'Veřejná nabídka (IDO) 3%',
        data5: 'Seed round 14%',
        data6: 'Operační vývoj 10%',
        data7: 'Správa tržní hodnoty 5%',
        data8: 'Odměna za staking 4%',
        data9: 'Incentivizace komunity 0,95%',
        data10: 'Odměna za airdrop 0,05%',
        total: '1 miliarda celkové nabídky',
    },
    roadmap: {
        title: 'Vývojová mapa',
        data1: {
            q: 'Č4/ 2022',
            d: 'Založení týmu WOW EARN.<br/>' +
                'Vydání bílé knihy WOW EARN a plánu rozvoje.'
        },
        data2: {
            q: 'Č1/ 2023',
            d: 'Úspěšné testování projektu DApp.<br/>' +
                'Spuštění WOW EARN.<br/>' +
                'Podpora ETH, BSC.<br/>' +
                'Zavedení stakingu USDT.<br/>' +
                'Jedinečný mechanismus odkazu na doporučení.<br/>' +
                'Báze uživatelů WOW EARN dosahuje 50 000.<br/>' +
                'Uzavření partnerství s hlavními mediálními platformami blockchainu.'
        },
        data3: {
            q: 'Č2/ 2023',
            d: 'Dokončen audit chytrých smluv.<br/>' +
                'Audit smlouvy DApp úspěšně projel.<br/>' +
                'Spuštění nového obrazu značky.<br/>' +
                'Veřejné vydání WOWCoin.<br/>' +
                'Báze uživatelů WOW EARN dosahuje 100 000.<br/>' +
                'Podpora mezireťazových transakcí.<br/>' +
                'Uzavření strategických partnerství s řadou projektů DeFi.'
        },
        data4: {
            q: 'Č3/ 2023',
            d: 'Báze uživatelů WOW EARN dosahuje 500 000.<br/>' +
                'Globální spolupráce s značkami.<br/>' +
                'Zahájení offline událostí Web3.'
        },
        data5: {
            q: 'Č4/ 2023',
            d: 'Báze uživatelů WOW EARN dosahuje 1 000 000.<br/>' +
                'Integrace dat tokenů a aktiv NFT s více scénáři.'
        },
        data6: {
            q: 'Č1/ 2024',
            d:`Spuštění nového oficiálního webu<br/>
            Denní aktivní uživatelé DApp překročili 100 000<br/>
            Adresy držící WOW EARN veřejný řetězec překročily 500 000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Veřejná beta a spuštění DEX založeného na veřejném řetězci WOW EARN<br/>
            Oficiální spuštění projektu GameFi na veřejném řetězci WOW EARN<br/>
            Zlepšení uživatelského zážitku z peněženky WOW EARN přidáním podpory více řetězců<br/>
            Úspěšné spuštění více než 10 000 aplikací DApp<br/>
            Účast na celosvětově známých offline výstavách blockchainu výrazně zvyšuje viditelnost značky<br/>
            Vybudování inkubační platformy pro zdokonalení ekosystému veřejného řetězce WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`Cíl DEX překročit celkovou uzamčenou hodnotu (TVL) 1M U, cíl produktů půjček překročit TVL 1M U<br/>
            WOWCoin se objevuje na centralizovaných burzách<br/>
            Vybudování finanční platformy pro podporu kreativních projektů<br/>
            Kontinuální vývoj produktů, spuštění hitových aplikací, včetně herních aplikací DApp a aplikací Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Údržba tvůrců trhu pro zachování hodnoty WOWCoin a nepřetržitý vývoj průlomových produktů<br/>
            Výrazné zlepšení uživatelské přilnavosti k periferním produktům<br/>
            Kompletní zdokonalení správy WOW EARN DAO`
        },
    },
    partners: {
        title: 'Naši partneři a média'
    },
    downloadWallet : {
        title: "Obchodujte bezpečně a zabezpečeně kdykoliv. Kdekoliv.",
        subtitle: "Obchodujte doma i na cestách s naší aplikací. K dispozici v App Store a Google Play Store.",
        downWalletTop: "Stáhnout z",
        donwloadTitle: "Android",
        titleIOS: "IOS",


    },
    footer: {
        social: 'Sociální média',
        resources: {
            title: 'Zdroje',
            data1: 'WhitePaper',
            data2: 'TĚŽBA',
            data3: 'Staking',
            data4: 'Plán',
        },
        contact: 'Oslovte nás',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}

export default cz;
/*捷克语*/
