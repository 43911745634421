<template>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" v-if="isShow"/>
   <router-view>
    <DownloadWallet msg="Welcome to Your Vue.js App" v-if="isShow" />
   </router-view> -->

  <!-- <DownloadWallet msg="Welcome to Your Vue.js App" v-if="isShow" /> -->

  <router-view />
</template>

<script>
import router from "./router";

export default {
  router,
};
</script>

<style></style>
