const ru = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Главная',
        Function: 'Функция',
        Mining: 'Майнинг',
        RoadMap: 'План развития',
        WhitePaper: 'Белая бумага',
        Blog: 'Блог',
        Task: ' Центр задач'
    },
    language: 'Язык',
    banner: {
        title: 'Шлюз<br/>к Web3',
        des: 'Разблокируйте подлинное владение цифровыми активами:<br/>Ваш портал к непревзойденным опытам Web3.',
        btn1: 'Запустить Dapp',
        btn2: 'Скачать Кошелек',
        btn3:' Проверено Hacken'
    },

    data: {
        data1: 'Ежедневные активные',
        data2: 'Охваченные страны',
        data3: 'Польз',
        data4: 'Стратеги',
        data5: 'Сообщество',
    },
    features: {
        title: 'ОСОБЕННОСТИ',
        data1: 'Купить крипту',
        data2: 'Майнинг Dapp',
        data3: 'NFT',
        data4: 'Блокчейн Layer1',
        data5: 'Стейкинг',
        data6: 'Обмен',
        data7: 'DEX',
        data8:'Сообщество'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin является частью экосистемы WOW EARN, блокчейн-платформы, которая акцентирует высокую эффективность и связанность в мире децентрализованных финансов (DeFi), стейкинг | добыча доходов | майнинг.',
        data2: 'Цепочка WOW EARN - это передовая платформа Layer 1, разработанная для предоставления пользователям и разработчикам непревзойденной эффективности и масштабируемости для различных приложений на основе блокчейна.',
        data3: 'WOW Coin играет ключевую роль в этой экосистеме, облегчая транзакции, вознаграждения и комиссии, а также служит основной валютой для взаимодействия в рамках платформы WOW EARN.',
        data4: 'Ее использование в переводах монет, как подробно описано в разделе транзакций исследователя, демонстрирует ее фундаментальную полезность в обеспечении операций блокчейна и обмена ценностями в сети WOW EARN.',
    },
    mechanism: {
        title: 'Общее предложение',
        data1: 'Майнинг пользователей 35%',
        data2: 'Майнинг узлов 20%',
        data3: 'Фонд 8%',
        data4: 'Публичное предложение (IDO) 3%',
        data5: 'Первичное размещение 14%',
        data6: 'Операционное развитие 10%',
        data7: 'Управление рыночной стоимостью 5%',
        data8: 'Вознаграждение за стейкинг 4%',
        data9: 'Инцентивы для сообщества 0,95%',
        data10: 'Вознаграждение за Airdrop 0,05%',
        total: '1 миллиард общего предложения',
    },
    roadmap: {
        title: 'План развития',
        data1: {
            q: 'Q4/ 2022',
            d: 'Создание команды WOW EARN.<br/>' +
                'Публикация белой бумаги и плана развития WOW EARN.'
        },
        data2: {
            q: 'Q1/ 2023',
            d: 'Успешное тестирование проекта DApp.<br/>' +
                'Запуск WOW EARN.<br/>' +
                'Поддержка ETH, BSC.<br/>' +
                'Введение стейкинга USDT.<br/>' +
                'Уникальный механизм приглашения по реферальной ссылке.<br/>' +
                'База пользователей WOW EARN достигает 50 000.<br/>' +
                'Заключение партнерств с крупными медиа-ресурсами блокчейна.'
        },
        data3: {
            q: 'Q2/ 2023',
            d: 'Завершена проверка смарт-контрактов.<br/>' +
                'Прошла проверка контрактов DApp.<br/>' +
                'Запуск нового бренда.<br/>' +
                'Публичный выпуск WOWCoin.<br/>' +
                'База пользователей WOW EARN достигает 100 000.<br/>' +
                'Поддержка кросс-чейн транзакций.<br/>' +
                'Формирование стратегических партнерств с многочисленными проектами DeFi.'
        },
        data4: {
            q: 'Q3/ 2023',
            d: 'База пользователей WOW EARN достигает 500 000.<br/>' +
                'Глобальные брендовые коллаборации.<br/>' +
                'Начало офлайн-мероприятий Web3.'
        },
        data5: {
            q: 'Q4/ 2023',
            d: 'База пользователей WOW EARN достигает 1 000 000.<br/>' +
                'Интеграция данных многосценарных ТОКЕНОВ и NFT-активов.'
        },
        data6: {
            q: 'Q1/ 2024',
            d:`Запуск нового официального сайта<br/>
            Число активных пользователей DApp превысило 100,000<br/>
            Количество адресов держателей в публичной сети WOW EARN превысило 500,000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Открытое бета-тестирование и запуск DEX на основе публичной сети WOW EARN<br/>
            Официальный запуск проекта GameFi на публичной сети WOW EARN<br/>
            Улучшение пользовательского опыта кошелька WOW EARN за счет добавления поддержки мульти-цепочек<br/>
            Успешный запуск более чем 10,000 приложений DApp<br/>
            Участие в мировых известных оффлайн выставках блокчейна значительно увеличивает узнаваемость бренда<br/>
            Создание инкубационной платформы для развития экосистемы публичной сети WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`Цель DEX превысить общую заблокированную стоимость (TVL) в 1M U, цель продуктов кредитования превысить TVL в 1M U<br/>
            WOWCoin внесен в список на централизованных биржах<br/>
            Создание финансовой платформы для поддержки творческих проектов<br/>
            Непрерывная разработка продуктов, запуск блокбастерных приложений, включая игровые приложения DApp и приложения Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Поддержка маркетмейкеров для сохранения стоимости WOWCoin и непрерывное развитие прорывных продуктов<br/>
            Значительное улучшение приверженности пользователей к сопутствующим продуктам<br/>
            Полное совершенствование управления WOW EARN DAO`
        },
    },
    partners: {
        title: 'Наши партнеры и медиа'
    },
    downloadWallet : {
        title: "Торгуйте безопасно и надежно в любое время. Где угодно.",
        subtitle: "Торгуйте дома и в пути с нашим приложением. Доступно в App Store и Google Play Store.",
        downWalletTop: "Скачать с",
        donwloadTitle: "Android",
        titleIOS: "IOS"


    },
    footer: {
        social: 'Социальные медиа',
        resources: {
            title: 'Ресурсы',
            data1: 'Белая бумага',
            data2: 'МАЙНИНГ',
            data3: 'Стейкинг',
            data4: 'ДОРОЖНАЯ КАРТА',
        },
        contact: 'Свяжитесь с нами',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}
export default ru;

/*俄罗斯语*/
