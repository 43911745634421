const tr = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Home',
        Function: 'Fonksiyon',
        Mining: 'Madencilik',
        RoadMap: 'Yol Har',
        WhitePaper: 'WhitePaper',
        Blog: 'Blog',
        Task: 'Görev Merkezi'
    },
    language: 'Dil',
    banner: {
        title: 'Web3\'e<br/>Giriş',
        des: 'Gerçek dijital varlık sahipliğini açığa çıkarın:<br/>Eşsiz Web3 deneyimleriniz için portalınız.',
        btn1: 'Dapp\'i Başlat',
        btn2: 'Cüzdanı İndir',
        btn3:'Hacken tarafından denetlendi'
    },
    data: {
        data1: 'Günlük kullanıcılar.',
        data2: 'Kapsanan ülkeler.',
        data3: 'Kullanıcı',
        data4: 'Stratejik ortaklar',
        data5: 'Toplum',
    },
    features: {
        title: 'ÖZELLİKLER',
        data1: 'Kripto Satın Al',
        data2: "Madencilik Dapp'ı",
        data3: 'NFT\'ler',
        data4: 'Layer1 Blockchain',
        data5: 'Staking',
        data6: 'Takas',
        data7: 'DEX',
        data8:'Topluluk'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin, WOW EARN ekosisteminin bir parçasıdır, merkezi olmayan finans (DeFi), stake | yield farming | mining gibi blok zinciri tabanlı uygulamalar için yüksek verimlilik ve bağlantıyı vurgulayan bir blok zinciri platformudur.',
        data2:'WOW EARN zinciri, çeşitli blok zinciri tabanlı uygulamalar için kullanıcılara ve geliştiricilere eşsiz verimlilik ve ölçeklenebilirlik sunmayı amaçlayan öncü Katman 1 platformudur.',
        data3:'WOW Coin, bu ekosistem içinde kritik bir rol oynar, işlemleri kolaylaştırır, ödüller ve ücretler sunar ve WOW EARN platformu içindeki etkileşimler için birincil para birimi olarak hizmet eder.',
        data4:'Blok zinciri işlemlerini ve değer değişimini etkinleştirmekte temel bir yararını gösteren wow Coin, gezginin işlem bölümünde detaylandırıldığı gibi, WOW EARN ağı içindeki değer takası ve blok zinciri işlemlerini mümkün kılar.'
    },
    mechanism: {
        title: 'Toplam Tedarik',
        data1: 'Kullanıcı madenciliği 35%',
        data2: 'Düğüm madenciliği 20%',
        data3: 'Vakıf 8%',
        data4: 'Halka arz (IDO) 3%',
        data5: 'Tohum turu 14%',
        data6: 'Operasyonel Gelişim 10%',
        data7: 'Pazar değeri yönetimi 5%',
        data8: 'Staking ödülü 4%',
        data9: 'Topluluk Teşviki 0.95%',
        data10: 'Airdrop ödülü 0.05%',
        total: '1 Milyar Toplam Tedarik',
    },
    roadmap: {
        title: 'Geliştirme Yol Haritası',
        data1: {
            q: '2022/Q4',
            d: 'WOW KAZAN Ekibi\'nin Kurulması.<br/>' +
                'WOW KAZAN Beyaz Kağıdı ve geliştirme planının yayınlanması.'
        },
        data2: {
            q: '2023/Q1',
            d: 'DApp projesinin başarılı test edilmesi.<br/>' +
                'WOW KAZAN\'ın başlatılması.<br/>' +
                'ETH, BSC için destek.<br/>' +
                'USDT staking tanıtımı.<br/>' +
                'Benzersiz referans davetiye mekanizması.<br/>' +
                'WOW KAZAN kullanıcı tabanının 50.000\'e ulaşması.<br/>' +
                'Büyük blockchain medya kuruluşlarıyla ortaklıkların kurulması.'
        },
        data3: {
            q: '2023/Q2',
            d: 'Akıllı kontrat denetiminin tamamlanması.<br/>' +
                'DApp kontrat denetiminin geçilmesi.<br/>' +
                'Yeni marka imajının başlatılması.<br/>' +
                'WOWCoin\'in halka arzının yapılması.<br/>' +
                'WOW KAZAN kullanıcı tabanının 100.000\'e ulaşması.<br/>' +
                'Çapraz zincir işlemlerinin desteklenmesi.<br/>' +
                'Çeşitli DeFi projeleriyle stratejik ortaklıkların kurulması.'
        },
        data4: {
            q: '2023/Q3',
            d: 'WOW KAZAN kullanıcı tabanının 500.000\'e ulaşması.<br/>' +
                'Küresel marka işbirlikleri.<br/>' +
                'Web3 offline etkinliklerinin başlatılması.'
        },
        data5: {
            q: '2023/Q4',
            d: 'WOW KAZAN kullanıcı tabanının 1.000.000\'e ulaşması.<br/>' +
                'Çoklu senaryo TOKEN ve NFT varlık verilerinin entegrasyonu.'
        },
        data6: {
            q: '2024/Q1',
            d:`Yeni resmi web sitesi yayınlandı<br/>
            DApp günlük aktif kullanıcı sayısı 100.000'i aştı<br/>
            WOW EARN halka açık zincirinin tutulan adres sayısı 500.000'i aştı`
        },
        data7:{
            q:'Q2/ 2024',
            d:`WOW EARN halka açık zincirine dayalı DEX'in kamu beta sürümü başladı ve yayınlandı<br/>
            WOW EARN halka açık zincirinin gamefi projesi resmen başlatıldı<br/>
            WOW EARN cüzdan kullanıcı deneyimini geliştirerek çoklu zincir desteği eklendi<br/>
            10.000+ DApp uygulaması başarıyla yayınlandı<br/>
            Küresel çapta tanınmış blockchain offline sergilerine katılım marka bilinirliğini önemli ölçüde artırdı<br/>
            WOW EARN halka açık zincir ekosistemini geliştirmek için bir kuluçka platformu oluşturuldu`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX'in hedeflediği Toplam Kilitli Değer (TVL) 1M U'yu, kredi ürününün hedeflediği TVL 1M U'yu aştı<br/>
            WOWCoin merkezi borsalara giriş yaptı<br/>
            Yaratıcı projelere finansal destek sağlamak için bir finansman platformu oluşturuldu<br/>
            Sürekli ürün geliştirme, aralarında DApp oyun uygulamaları ve Gamefi uygulamaları da bulunan çığır açan uygulamaların piyasaya sürülmesi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`WOWCoin'in değerini korumak ve devrim niteliğinde ürünlerin sürekli geliştirilmesi için piyasa yapıcıların bakımı yapıldı<br/>
            Çevre ürünlerde kullanıcı bağlılığında önemli bir artış sağlandı<br/>
            WOW EARN DAO yönetimi tamamen mükemmelleştirildi`
        },
    },
    partners: {
        title: 'Ortaklarımız ve Medya'
    },
    downloadWallet : {
        title: "Her zaman güvenli ve güvenli bir şekilde ticaret yapın. Her yerde.",
        subtitle: "Evde ve dışarıda uygulamamızla işlem yapın. App Store ve Google Play Store'da mevcuttur.",
        downWalletTop: "Buradan indirin",
        donwloadTitle: "Android",
        titleIOS: "iOS",


    },
    footer: {
        social: 'Sosyal medya',
        resources: {
            title: 'Kaynaklar',
            data1: 'WhitePaper',
            data2: 'MADENCİLİK',
            data3: 'Staking',
            data4: 'Yol Har',
        },
        contact: 'Bize Ulaşın',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}

export default tr;
/*土耳其语*/
