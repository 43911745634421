const my = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Home',
        Function: 'Fungsi',
        Mining: 'Penambangan',
        RoadMap: 'Peta Jalan',
        WhitePaper: 'Kertas Putih',
        Blog: 'Blog',
        Task: 'Pusat Tugasan'
    },
    language: 'Bahasa',
    banner: {
        title: 'Pintu<br/>Ke Web3',
        des: 'Buka kepemilikan aset digital sejati:<br/>Portal anda ke pengalaman Web3 yang tiada tandingannya.',
        btn1: 'Lancarkan Dapp',
        btn2: 'Muat Turun Wallet',
        btn3:' Diperiksa oleh Hacken'
    },
    data: {
        data1: 'Pengguna Aktif Harian',
        data2: 'Negara yang Diliputi',
        data3: 'Pengguna',
        data4: 'Rakan Strategik',
        data5: 'Pengguna Komuniti',
    },
    features: {
        title: 'CIRI-CIRI',
        data1: 'Beli Kripto',
        data2: 'Dapp Penambangan',
        data3: 'NFTs',
        data4: 'Layer1 Blockchain',
        data5: 'Staking',
        data6: 'Tukar',
        data7: 'DEX',
        data8:'Komuniti'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin adalah sebahagian daripada ekosistem WOW EARN, platform blockchain yang menekankan kecekapan dan keterhubungan yang tinggi dalam dunia kewangan terdesentralisasi (DeFi), staking | yield farming | penambangan.',
        data2: 'Rantaian WOW EARN adalah platform Layer 1 yang menjadi perintis yang direka untuk menawarkan kecekapan dan penyesuaian yang tiada tandingan kepada pengguna dan pembangun untuk pelbagai aplikasi berasaskan blockchain.',
        data3: 'WOW Coin memainkan peranan penting dalam ekosistem ini, memudahkan transaksi, ganjaran, dan yuran, dan berfungsi sebagai mata wang utama untuk interaksi dalam platform WOW EARN.',
        data4: 'Penggunaannya dalam pemindahan koin, seperti yang diterangkan dalam bahagian transaksi pramasa, menunjukkan kegunaannya yang asas dalam membolehkan operasi blockchain dan pertukaran nilai dalam rangkaian WOW EARN.',
    },
    mechanism: {
        title: 'Bekalan Jumlah',
        data1: 'Penambangan Pengguna 35%',
        data2: 'Penambangan Nod 20%',
        data3: 'Yayasan 8%',
        data4: 'Tawaran Awam (IDO) 3%',
        data5: 'Pusingan Benih 14%',
        data6: 'Pembangunan Operasi 10%',
        data7: 'Pengurusan Nilai Pasaran 5%',
        data8: 'Ganjaran Staking 4%',
        data9: 'Insentif Komuniti 0.95%',
        data10: 'Ganjaran Airdrop 0.05%',
        total: 'Bekalan Jumlah 1 Bilion',
    },
    roadmap: {
        title: 'Peta Jalan Pembangunan',
        data1: {
            q: 'K4/ 2022',
            d: 'Penubuhan Pasukan WOW EARN.<br/>' +
                'Penerbitan Kertas Putih WOW EARN dan pelan pembangunan.'
        },
        data2: {
            q: 'K1/ 2023',
            d: 'Pengujiabahjaya berjaya projek DApp.<br/>' +
                'Pelancaran WOW EARN.<br/>' +
                'Sokongan untuk ETH, BSC.<br/>' +
                'Pengenalan staking USDT.<br/>' +
                'Mekanisme undangan rujukan unik.<br/>' +
                'Basis pengguna WOW EARN mencapai 50,000.<br/>' +
                'Penubuhan rakan kerjasama dengan media blockchain utama.'
        },
        data3: {
            q: 'K2/ 2023',
            d: 'Audit kontrak pintar selesai.<br/>' +
                'Audit kontrak DApp berjaya.<br/>' +
                'Pelancaran imej jenama baru.<br/>' +
                'Penerbitan awam WOWCoin.<br/>' +
                'Basis pengguna WOW EARN mencapai 100,000.<br/>' +
                'Sokongan untuk transaksi melintang-jejala.<br/>' +
                'Penubuhan rakan kerjasama strategik dengan pelbagai projek DeFi.'
        },
        data4: {
            q: 'K3/ 2023',
            d: 'Basis pengguna WOW EARN mencapai 500,000.<br/>' +
                'Kerjasama jenama global.<br/>' +
                'Permulaan acara offline Web3.'
        },
        data5: {
            q: 'K4/ 2023',
            d: 'Basis pengguna WOW EARN mencapai 1,000,000.<br/>' +
                'Pengintegrasian data aset TOKEN dan NFT dalam pelbagai skenario.'
        },
        data6: {
            q: 'K1/ 2024',
            d:`Pelancaran laman web rasmi baru<br/>
            Pengguna aktif harian DApp melebihi 100,000 orang<br/>
            Alamat pegangan rangkaian awam WOW EARN melebihi 500,000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`DEX berasaskan rangkaian awam WOW EARN memulakan ujian beta awam dan dilancarkan<br/>
            Projek gamefi pada rangkaian awam WOW EARN secara rasmi dilancarkan<br/>
            Meningkatkan pengalaman pengguna dompet WOW EARN dengan menyokong pelbagai rangkaian<br/>
            Berhasil melancarkan lebih daripada 10,000 aplikasi DApp<br/>
            Penyertaan dalam pameran blockchain luar talian yang terkenal di seluruh dunia secara signifikan meningkatkan visibiliti jenama<br/>
            Membangun platform inkubasi untuk menyempurnakan ekosistem rangkaian awam WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX bertujuan melebihi TVL 1M U, produk pinjaman bertujuan melebihi TVL 1M U<br/>
            WOWCoin disenaraikan di bursa terpusat<br/>
            Membangun platform pembiayaan untuk menyediakan sokongan kewangan kepada projek kreatif<br/>
            Pembangunan produk berterusan, melancarkan aplikasi blockbuster, termasuk aplikasi permainan DApp dan aplikasi Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Menyelenggara pembuat pasaran untuk menjaga nilai WOWCoin dan pembangunan produk terobosan secara berterusan<br/>
            Peningkatan ketara dalam keakraban pengguna terhadap produk sekitar<br/>
            Pengurusan WOW EARN DAO disempurnakan secara menyeluruh`
        },
    },
    partners: {
        title: 'Rakan dan Media Kami'
    },
    downloadWallet : {
        title: "Berjual beli dengan selamat dan dengan aplikasi kami di mana-mana. Pada bila-bila masa. Di mana sahaja.",
        subtitle: "Berjual beli di rumah dan semasa dalam perjalanan dengan aplikasi kami. Terdapat di App Store dan Google Play Store.",
        downWalletTop: "Muat turun dari",
        donwloadTitle: "Android",
        titleIOS: "IOS"



    },
    footer: {
        social: 'Media Sosial',
        resources: {
            title: 'Sumber',
            data1: 'Kertas Putih',
            data2: 'Penambangan',
            data3: 'Staking',
            data4: 'Peta Jalan',
        },
        contact: 'Hubungi',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}

export default my;
/*马来语*/
