import { createApp } from 'vue';
import App from './App.vue';
// import DownloadWallet from './components/DownloadWallet.vue'
// import { createRouter, createWebHistory } from 'vue-router'
import animate from 'animate.css';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import router from './router' 


//import 'wowjs/css/libs/animate.css'
//import "bootstrap/dist/css/bootstrap.min.css";
//import 'bootstrap/dist/js/bootstrap.bundle.min.js'

//import Parallax from 'parallax-js';
//import 'lib-flexible'


//import { createI18n } from 'vue-i18n'

import en from "./lang/en";
import zh from "./lang/zh";
import VueScrollTo from 'vue-scrollto';
import ClickOutside from 'v-click-outside';
const language = {
    zh: { ...zh },
    en: { ...en },
};



const app=createApp(App)
app.use(router);
app.use(ToastPlugin);
app.use(animate)
app.use(language)
app.use(VueScrollTo)
app.use(ClickOutside)

// app.component('download-wallet', DownloadWallet);

app.mount('#app')
/*
createApp(App).use({
    animate,
    Parallax,
    language,


}).mount('#app')

 */

//npm i -s parallax-js
//npm install vue-i18n
//npm install bootstrap-vue bootstrap
//npm install animate.css
//npm i lib-flexible postcss-px2rem px2rem-loader --save
//npm install wowjs