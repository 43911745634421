const ir = {
    brand:'WOW ERAN',
    nav:{
        Home:'خانه',
        Function:'تابع',
        Mining:'استخراج',
        RoadMap:'نقشه راه',
        WhitePaper:'کاغذ سفید',
        Blog:'بلاگ',
        Task: 'مرکز وظایف'
    },
    language:'زبان',
    banner:{
        title:'دروازه<br/>به وب 3',
        des:'باز کردن مالکیت دیجیتال واقعی دارایی:<br/>دروازه شما به تجربیات بی‌نظیر وب 3.',
        btn1:'راه‌اندازی Dapp',
        btn2:'دانلود کیف پول',
        btn3:'مورد بازرسی توسط Hacken'
    },

    data:{
        data1:'کاربران فعال روزانه',
        data2:'کشورهای پوشش داده شده',
        data3:'کاربر',
        data4:'شرکای استراتژیک',
        data5:'کاربران جامعه',
    },
    features:{
        title:'ویژگی‌ها',
        data1:'خرید رمزارز',
        data2:'استخراج Dapp',
        data3:'NFT ها',
        data4:'بلوکچین لایه 1',
        data5:'مراهنه',
        data6:'تعویض',
        data7:'DEX',
        data8:'جامعه'
    },
wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin بخشی از اکوسیستم WOW EARN است، پلتفرم بلاکچینی که بر کارایی بالا و اتصال در دنیای مالی غیرمتمرکز (DeFi)، استیکینگ | کشت سود | استخراج تأکید دارد.',
        data2:'زنجیره WOW EARN یک پلتفرم پیشرو لایه 1 طراحی شده است تا به کاربران و توسعه دهندگان کارایی و قابلیت ارتقاء بی نظیری را برای برنامه های مبتنی بر بلاکچین ارائه دهد.',
        data3:'WOW Coin نقش حیاتی در این اکوسیستم ایفا می‌کند، تسهیل تراکنش‌ها، پاداش‌ها و هزینه‌ها و به عنوان ارز اصلی برای تعاملات درون پلتفرم WOW EARN عمل می‌کند.',
        data4:'استفاده آن در انتقالات سکه، همانطور که در بخش تراکنش‌های اکسپلورر توضیح داده شده، کاربرد اساسی آن را در فعال سازی عملیات بلاکچین و تبادل ارزش در شبکه WOW EARN نشان می‌دهد.',

    },

    mechanism:{
        title:'کل عرض',
        data1:'استخراج توسط کاربر 35%',
        data2:'استخراج توسط گره 20%',
        data3:'بنیاد 8%',
        data4:'پیشنهاد عمومی (IDO) 3%',
        data5:'دوره بذری 14%',
        data6:'توسعه عملیاتی 10%',
        data7:'مدیریت ارزش بازار 5%',
        data8:'پاداش مراهنه 4%',
        data9:'تشویق جامعه 0.95%',
        data10:'پاداش اسقاط هوا 0.05%',
        total:'1 میلیارد عرضه کلیه',
    },
    roadmap:{
        title:'نقشه راه توسعه',
        data1:{
            q:'Q4/ 2022',
            d:'تأسیس تیم WOW EARN.<br/>' +
                'عرضه کاغذ سفید WOW EARN و برنامه توسعه.'
        },
        data2:{
            q:'Q1/ 2023',
            d:'آزمایش موفق پروژه DApp.<br/>' +
                'راه‌اندازی WOW EARN.<br/>' +
                'پشتیبانی از ETH ، BSC.<br/>' +
                'معرفی مراهنه USDT.<br/>' +
                'مکانیزم دعوت مرجعی منحصر به فرد.<br/>' +
                'وصول پایگاه کاربران WOW EARN به 50،000.<br/>' +
                'تأسیس شراکت‌ها با رسانه‌های بلوکچین اصلی.'
        },
        data3:{
            q:'Q2/ 2023',
            d:'اتمام بازرسی قرارداد هوشمند.<br/>' +
                'گذر از بازرسی قرارداد DApp.<br/>' +
                'راه‌اندازی تصویر برند جدید.<br/>' +
                'عرضه عمومی WOWCoin.<br/>' +
                'وصول پایگاه کاربران WOW EARN به 100،000.<br/>' +
                'پشتیبانی از معاملات عبر زنجیره.<br/>' +
                'شراکت‌های استراتژیک با چندین پروژه DeFi.'
        },
        data4:{
            q:'رQ3/ 2023',
            d:'وصول پایگاه کاربران WOW EARN به 500،000.<br/>' +
                'همکاری‌های جهانی با برندهای تجاری.<br/>' +
                'آغاز رویدادهای آفلاین Web3.'
        },
        data5:{
            q:'ربQ4/ 2023',
            d:'وصول پایگاه کاربران WOW EARN به 1،000،000.<br/>' +
                'یکپارچه‌سازی داده‌های TOKEN و NFT چند سناریو.'
        },
        data6:{
            q:'Q1/ 2024',
            d:`
            وب‌سایت رسمی جدید منتشر شد<br/>
            تعداد کاربران فعال روزانه DApp از 100,000 نفر گذشت<br/>
            تعداد آدرس‌های نگهداری سکه در زنجیره عمومی WOW EARN از 500,000 عبور کرد
            `
        },
        data7:{
            q:'Q2/ 2024',
            d:`
            آزمایش عمومی و انتشار DEX بر پایه زنجیره عمومی WOW EARN آغاز شد<br/>
            پروژه gamefi زنجیره عمومی WOW EARN به طور رسمی شروع شد<br/>
            تجربه کاربری کیف پول WOW EARN با افزودن پشتیبانی چندین زنجیره بهبود یافت<br/>
            بیش از 10,000 برنامه DApp با موفقیت راه‌اندازی شد<br/>
            شرکت در نمایشگاه‌های آفلاین بلاکچین شناخته شده جهانی به طور قابل توجهی اعتبار برند را افزایش داد<br/>
            سکوی نوآوری ساخته شد تا سیستم اکولوژیک زنجیره عمومی WOW EARN بهبود یابد
            `
        },
        data8:{
            q:'Q3/ 2024',
            d:`
            هدف DEX برای عبور از TVL 1M U، هدف محصولات وام برای عبور از TVL 1M U<br/>
            WOWCoin در بورس‌های متمرکز ثبت شد<br/>
            پلتفرم تأمین مالی ساخته شد تا از پروژه‌های خلاقانه حمایت مالی شود<br/>
            توسعه مداوم محصول، راه اندازی برنامه‌های برتر شامل برنامه‌های بازی DApp و برنامه‌های Gamefi.
            `
        },
        data9:{
            q:'Q4/ 2024',
            d:`
            نگهداری فروشندگان بازار برای حفظ ارزش WOWCoin و توسعه مداوم محصولات نوآورانه<br/>
            چسبندگی کاربر در محصولات جانبی به طور قابل توجهی افزایش یافت<br/>
            حکمرانی WOW EARN DAO به طور کامل بهبود یافت`
        },
    },
    partners:{
        title:'شرکای ما و رسانه‌ها'
    },
    downloadWallet : {
        title: "به طور امن و امنیتی تجارت کنید. هر زمانی. هر کجا.",
        subtitle: "در خانه و در حال حرکت با برنامه ما تجارت کنید. از طریق فروشگاه اپل و فروشگاه گوگل پلی در دسترس است.",
        downWalletTop: "دانلود از",
        donwloadTitle: "اندروید",
        titleIOS: "IOS",



    },
    footer:{
        social:'رسانه‌های اجتماعی',
        resources:{
            title:'منابع',
            data1:'کاغذ سفید',
            data2:'استخراج',
            data3:'مراهنه',
            data4:'نقشه راه',
        },
        contact:'تماس با ما',
        app:{
            ios:'IOS',
            android:'Android',
            apk:'APK'
        }
    }
}
export default ir;
/*波斯语*/
