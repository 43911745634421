<template id="blogDetails" >
  <nav class="navbar-header pa-blog p-0 navbar-dark bg-transparent static-top" style="background-color: #0c0c0c">
    <a class="navbar-brand-blog d-flex" href="#"
      ><img
        alt=""
        src="../assets/images/wow-blog.svg"
        class="navimg"
        style="width: 230px"
        @click="redirectToHome()"
    /></a>
  </nav>
  <section class="bg-image-blog">
    <div class="text-bg">
      <div class="typed-text container text-left justify-content-start">
        <span id="typed-text" class="animated-text-effect">{{ typedText }}</span>
        <span class="typed-cursor">|</span>
      </div>
    </div>
  </section>

  <div class="container para" style="background-color: #0c0c0c">
    <div class="w-100">
      <img src="../assets/images/sample-slot-3.webp" class="feedimg" />
    </div>
    <div class="blog-details-para" v-html="content">
    </div>
    <!-- <div class="card-img-details">
      <p>🧐WOW EARN Wallet: Your gateway to web3 knowledge🌐</p>
      <img alt="" src="../assets/images/blog-details-card-img.jpg" />
    </div> -->
    <footer class="blog-footer">
      <div class="container col-12 text-center" style="margin:25px 0px">
        <p>
          @2022-204 Copy WOWEARN All Rights Reserved<a target="_blank"></a>
        </p>
      </div>
      <div class="pa-back-to-top-wrap pa-backtotop-visible">
        <a class="pa-back-to-top" href="#top"
          ><span class="pa-back-to-top"></span
        ></a>
      </div>
    </footer>
  </div>
</template>

<script>
import "@/assets/css/blog.css";
export default {
  name: "BlogDetails",
  methods: {
    redirectToHome(){
      this.$router.push('/');
    },
    // Function to simulate typing effect
    type() {
      if (!this.isTyping) return; // Check if typing animation should continue
      const currentSentence = this.sentences[this.sentenceIndex];
      if (this.index < currentSentence.length) {
        this.typedText += currentSentence.charAt(this.index);
        this.index++;
        setTimeout(this.type, this.typingSpeed); // Adjust typing speed as needed
      } else {
        // Check if there are more sentences to type
        if (this.sentenceIndex < this.sentences.length - 1) {
          setTimeout(this.changeSentence, this.delayBetweenSentences); // Change sentence after delay
        } else {
          setTimeout(this.reset, this.delayAfterCompletion); // Reset after completing all sentences
        }
      }
    },
    // Function to change the current sentence
    changeSentence() {
      this.sentenceIndex++;
      this.index = 0; // Reset index for typing new sentence
      this.typedText = ""; // Clear typed text
      this.type(); // Start typing new sentence
    },
    // Function to reset typing after completing all sentences
    reset() {
      if (this.isTyping) {
        this.sentenceIndex = 0; // Reset sentence index
        this.typedText = ""; // Clear typed text
        this.index = 0; // Reset index for typing new sentence
        this.type(); // Start typing first sentence again
      }
    }
  },
  data() {
    return {
      content: '',
      typedText: "", // To store the typed text
      sentences: ["Here's our latest news.", "Wow Coin Blog"], // Sentences to be typed
      sentenceIndex: 0, // Index to keep track of the current sentence
      index: 0, // Index to keep track of the characters typed
      typingSpeed: 90, // Typing speed in milliseconds
      delayBetweenSentences: 3000, // Delay between sentences in milliseconds
      delayAfterCompletion: 2000, // Delay after completing one sentence in milliseconds
      isTyping: true
    };
  },
  mounted() {
    this.content = localStorage.getItem('contentHTML')
    // Start typing animation when component is mounted
    this.type();
  },
  beforeUnmount() {
    // Set the typing flag to false before unmounting
    this.isTyping = false;
  }
};
</script>

<style scoped>
.animated-text-effect {
  display: inline-block;
  overflow: hidden;
  /* border-right: 0.08em solid orange; Cursor style */
  white-space: nowrap;
  letter-spacing: 0.15em; /* Adjust as needed */
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
