const ua = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Головна',
        Function: 'Функція',
        Mining: 'Майнінг',
        RoadMap: 'Маршрутка',
        WhitePaper: 'Білий Папір',
        Blog: 'Блог',
        Task: 'Центр завдань'
    },
    language: 'Мова',
    banner: {
        title: 'Шлюз<br/>До Web3',
        des: 'Розблокуйте власність на справжні цифрові активи:<br/>Ваш портал до неперевершених вражень від Web3.',
        btn1: 'Запуск Dapp',
        btn2: 'Завантажити Гаманець',
        btn3:' Перевірено за допомогою Hacken'
    },
    data: {
        data1: 'Щоденні користувачі',
        data2: 'Охоплені країни',
        data3: 'Користувач',
        data4: 'Стратегічні партнери',
        data5: 'Громада',
    },
    features: {
        title: 'ОСОБЛИВОСТІ',
        data1: 'Купити Крипто',
        data2: 'Майнінг Dapp',
        data3: 'NFTs',
        data4: 'Блокчейн 1-го рівня',
        data5: 'Стейкінг',
        data6: 'Обмін',
        data7: 'DEX',
        data8:'Спільнота'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin є частиною екосистеми WOW EARN, блокчейн-платформи, яка наголошує на високій ефективності та зв’язку в світі децентралізованих фінансів (DeFi), стейкінг | фермерство урожаю | майнінг.',
        data2: 'Ланцюжок WOW EARN - це передова платформа 1-го рівня, призначена для надання користувачам та розробникам неперевершеної ефективності та масштабованості для різних застосунків на основі блокчейну.',
        data3: 'WOW Coin відіграє вирішальну роль у цій екосистемі, сприяючи транзакціям, винагородам і комісіям, а також слугуючи основною валютою для взаємодії на платформі WOW EARN.',
        data4: 'Її використання при переказі монет, як детально описано в розділі транзакцій дослідника, демонструє її фундаментальну корисність у забезпеченні операцій з блокчейном та обміну вартості в межах мережі WOW EARN.',

    },
    mechanism: {
        title: 'Загальний обсяг',
        data1: 'Користувальницький майнінг 35%',
        data2: 'Майнінг вузла 20%',
        data3: 'Фонд 8%',
        data4: 'Публічна пропозиція (IDO) 3%',
        data5: 'Початковий раунд 14%',
        data6: 'Операційний розвиток 10%',
        data7: 'Управління ринковою вартістю 5%',
        data8: 'Винагорода за стейкінг 4%',
        data9: 'Стимулювання спільноти 0.95%',
        data10: 'Винагорода за аірдроп 0.05%',
        total: '1 мільярд загального обсягу',
    },
    roadmap: {
        title: 'Мапа Розвитку',
        data1: {
            q: 'Ч4/ 2022',
            d: 'Створення команди WOW EARN.<br/>' +
                'Випуск Білого Паперу та плану розвитку WOW EARN.'
        },
        data2: {
            q: 'Ч1/ 2023',
            d: 'Успішне тестування проекту DApp.<br/>' +
                'Запуск WOW EARN.<br/>' +
                'Підтримка ETH, BSC.<br/>' +
                'Введення стейкінгу USDT.<br/>' +
                'Унікальний механізм запрошення посилань.<br/>' +
                'База користувачів WOW EARN досягає 50 000.<br/>' +
                'Укладення партнерства з провідними засобами масової інформації блокчейн.'
        },
        data3: {
            q: 'Ч2/ 2023',
            d: 'Аудит смарт-контракту завершено.<br/>' +
                'Аудит контракту DApp пройшов.<br/>' +
                'Запуск нового бренду.<br/>' +
                'Публічний реліз WOWCoin.<br/>' +
                'База користувачів WOW EARN досягає 100 000.<br/>' +
                'Підтримка міжланцюжкових транзакцій.<br/>' +
                'Укладення стратегічних партнерств з численними проектами DeFi.'
        },
        data4: {
            q: 'Ч3/ 2023',
            d: 'База користувачів WOW EARN досягає 500 000.<br/>' +
                'Глобальні брендові співпраці.<br/>' +
                'Початок офлайн подій Web3.'
        },
        data5: {
            q: 'Ч4/ 2023',
            d: 'База користувачів WOW EARN досягає 1 000 000.<br/>' +
                'Інтеграція даних багатосценарного TOKEN та активів NFT.'
        },
        data6: {
            q: 'Ч1/ 2024',
            d:`Запущено новий офіційний веб-сайт<br/>
            Щоденна кількість активних користувачів DApp перевищила 100,000<br/>
            Кількість адрес тримачів на публічному ланцюзі WOW EARN перевищила 500,000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Відкрито публічне бета-тестування та запущено DEX на базі публічного ланцюга WOW EARN<br/>
            Офіційно запущено проект GameFi на публічному ланцюзі WOW EARN<br/>
            Покращено користувацький досвід гаманця WOW EARN за рахунок додавання підтримки багатьох ланцюгів<br/>
            Успішно запущено понад 10,000 DApp додатків<br/>
            Участь у всесвітньо відомих офлайн-виставках блокчейну значно підвищила впізнаваність бренду<br/>
            Створено інкубаційну платформу для вдосконалення екосистеми публічного ланцюга WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`Ціль DEX щодо загальної заблокованої вартості (TVL) перевищила 1M U, ціль продуктів кредитування перевищити TVL на 1M U<br/>
            WOWCoin зареєстровано на централізованих біржах<br/>
            Створено фінансову платформу для надання фінансової підтримки креативним проектам<br/>
            Постійний розвиток продуктів, запуск блокбастерних додатків, включаючи ігрові додатки DApp та додатки Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Підтримка маркет-мейкерів для збереження вартості WOWCoin та триває розробка проривних продуктів<br/>
            Значне підвищення залученості користувачів до супутніх продуктів<br/>
            Повне вдосконалення управління WOW EARN DAO`
        },
    },
    partners: {
        title: 'Наші Партнери і Медіа'
    },
    downloadWallet : {
        title: "Торгуйте безпечно і надійно в будь-який час. Де завгодно.",
        subtitle: "Торгуйте вдома і в дорозі за допомогою нашого додатка. Доступно в App Store і Google Play Store.",
        downWalletTop: "Завантажити з",
        donwloadTitle: "Android",
        titleIOS: "IOS",



    },
    footer: {
        social: 'Соціальні мережі',
        resources: {
            title: 'Ресурси',
            data1: 'Білий Папір',
            data2: 'МАЙНІНГ',
            data3: 'Стейкінг',
            data4: 'Маршрутка',
        },
        contact: 'Зв’яжіться з нами',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}

export default ua;
/*乌克兰语*/
