
import en from "./en";
import de from "./de";
import es from "./es";
import fr from "./fr";
import it from "./it";
import ru from "./ru";
import jp from "./jp";
import kr from "./kr";
import pt from "./pt";
import ua from "./ua";
import cz from "./cz";
import my from "./my";
import tr from "./tr";
import mm from "./mm";
import id from "./id";
import bd from "./bd";
import sa from "./sa";
import th from "./th";
import vn from "./vn";
import kh from "./kh";
import pk from "./pk";
import ir from "./ir";
import zh_TW from "./zh_TW";
import zh from "./zh";
const language = {
    en: { ...en },
    de: { ...de },
    es: { ...es },
    fr: { ...fr },
    it: { ...it },
    ru: { ...ru },
    jp: { ...jp },
    kr: { ...kr },
    pt: { ...pt },
    ua: { ...ua },
    cz: { ...cz },
    my: { ...my },
    tr: { ...tr },
    mm: { ...mm },
    id: { ...id },
    bd: { ...bd },
    sa: { ...sa },
    th: { ...th },
    vn: { ...vn },
    kh: { ...kh },
    pk: { ...pk },
    ir: { ...ir },
    zh_TW: { ...zh_TW },
    zh: { ...zh },

};

export default language
