const mm = {
    brand:'WOW ERAN',
    nav:{
        Home:'Home',
        Function:'Features',
        Mining:'Mining',
        RoadMap:'RoadMap',
        WhitePaper:'WhitePaper',
        Blog:'Blog',
        Task: ' လုပ်ငန်း ဗားရှင်း '
    },
    language:'Language',
    banner:{
        title:'ဦးဆောင်သည်။<br />Web3 Portal',
        des:'စစ်မှန်သော ဒစ်ဂျစ်တယ်ပိုင်ဆိုင်မှုပိုင်ဆိုင်မှုကို လော့ခ်ဖွင့်ပါ။：<br />ပြိုင်ဘက်ကင်းသော Web3 အတွေ့အကြုံကို သင့်ထံ ယူဆောင်လာပါ။',
        btn1:'Dapp စတင်ခြင်း',
        btn2:'ကိုယ်ပိုင်သည်ကိုနှိပ်ရန်',
        btn3:'Hacken မှ စစ်ဆေးခဲ့သည်။'
    },

    data:{
        data1:'နေ့စဉ်အသုံးပြုသူများ',
        data2:'နိုင်ငံတွေ',
        data3:'အသုံးပြုသူ',
        data4:'သုံးလုပ်သူများ',
        data5:'အဖွဲ့ဝင်အသုံးပြုသူများ',
    },
    features:{
        title:'FEATURES',
        data1:'Buy Crypto',
        data2:'အရွယ်အစား Dapp',
        data3:'NFTs',
        data4:'Layer1 Blockchain',
        data5:'Staking',
        data6:'Swap',
        data7:'DEX',
        data8:'အသိုင်းအဝိုင်း'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin သည် ဗဟိုချုပ်ကိုင်မှုလျှော့ချထားသော-finance (DeFi)၊ လောင်းကြေး | အထွက်နှုန်းလယ်ယာ | သတ္တုတူးဖော်ခြင်း၏ ကမ္ဘာတွင် မြင့်မားသော ထိရောက်မှုနှင့် ချိတ်ဆက်မှုကို အလေးပေးသည့် blockchain ပလပ်ဖောင်း၏ တစ်စိတ်တစ်ပိုင်းဖြစ်သည်။',
        data2:'WOW EARN ကွင်းဆက်သည် အမျိုးမျိုးသော blockchain-based အပလီကေးရှင်းများအတွက် အသုံးပြုသူများနှင့် developer များအား ပြိုင်ဆိုင်မှုမရှိဘဲ စွမ်းဆောင်ရည်နှင့် ကျွမ်းကျင်ပိုင်နိုင်မှုတို့ကို ပေးဆောင်ရန် ဒီဇိုင်းထုတ်ထားသော ရှေ့ဆောင် Layer 1 ပလပ်ဖောင်းတစ်ခုဖြစ်သည်။',
        data3:'WOW Coin သည် ဤဂေဟစနစ်အတွင်းတွင် အရေးပါသောအခန်းကဏ္ဍမှပါဝင်ပြီး၊ လွှဲပြောင်းမှုများ၊ ဆုလာဘ်များနှင့် အခကြေးငွေများကို လွယ်ကူချောမွေ့စေကာ WOW EARN ပလပ်ဖောင်းအတွင်း အပြန်အလှန်ဖလှယ်မှုအတွက် အဓိကငွေကြေးအဖြစ် ဆောင်ရွက်ပေးပါသည်။',
        data4:'အကြွေစေ့လွှဲပြောင်းခြင်းများတွင်၎င်း၏အသုံးပြုမှုသည်ရှာဖွေသူ၏အရောင်းအ၀ယ်ကဏ္ဍတွင်အသေးစိတ်ဖော်ပြထားသည့်အတိုင်း၊ WOW EARN ကွန်ရက်အတွင်းတွင်၎င်း၏အခြေခံအကျဆုံးအသုံးဝင်မှုကိုပြသပြီး blockchain လည်ပတ်မှုနှင့်တန်ဖိုးဖလှယ်မှုကိုပြသသည်။',
    },
    mechanism:{
        title:'စုစုပေါင်းရောင်းချခြင်း',
        data1:'အသုံးပြုသူစရိတ်များ 35%',
        data2:'အသုံးပြုသူအတွက်များ 20%',
        data3:'အသုံးပြုသူနှင့်ဆောင်ရွက်လာခြင်း 8%',
        data4:'အများဆုံးပေးသောအဖွဲ့များပြုပြင်ရန် (IDO) 3%',
        data5:'အပေါ်ကုမ္ပဏီ 14%',
        data6:'လုပ်ငန်းအစီအစဥ် 10%',
        data7:'လက်ရှိလတ်တလောအားအုပ်ချုပ် 5%',
        data8:'Staking ပုံစံ 4%',
        data9:'အဖွဲ့ဝင်ကိုစောင့်ခြင်း 0.95%',
        data10:'Airdrop အသုံးပြုခြင်း 0.05%',
        total:'10 သစ်ပါဝင်ခြင်းအားစုစုပေါင်း',
    },
    roadmap:{
        title:'လမ်းကြောင်းပြင်မှုရက်စွဲ',
        data1:{
            q:'Q4/ 2022',
            d:'WOW EARN အဖွဲ့ကို တည်ထောင်ခြင်း။<br/>'+
               'WOW EARN Whitepaper နှင့် ဖွံ့ဖြိုးရေးအစီအစဉ်ကို ထုတ်ပြန်ခြင်း'
        },
        data2:{
            q:'Q1/ 2023',
            d:'DApp ပရောဂျက်ကို အောင်မြင်စွာ စမ်းသပ်ခြင်း။<br/>' +
                'WOW EARN ကို စတင်လိုက်ပါ။<br/>' +
                'ETH, BSC အတွက် ပံ့ပိုးမှု။<br/>' +
                'USDT လောင်းကြေးငွေ မိတ်ဆက်။<br/>' +
                'ထူးခြားသော ရည်ညွှန်းဖိတ်ကြားမှု ယန္တရား။<br/>' +
                'WOW EARN အသုံးပြုသူအခြေခံ 50,000 သို့ရောက်ရှိ။<br/>' +
                'အဓိက blockchain မီဒီယာဆိုင်များနှင့် မိတ်ဖက်များ တည်ထောင်ခြင်း။'
        },
        data3:{
            q:'Q2/ 2023',
            d:'စမတ်ကန်ထရိုက်စာရင်းစစ် ပြီးပါပြီ။<br/>' +
                'DApp စာချုပ်ကို စာရင်းစစ်ပြီးသွားပြီ။<br/>' +
                'အမှတ်တံဆိပ်ပုံရိပ်အသစ်ကို စတင်လိုက်ပါ။<br/>' +
                'WOWCoin ကို အများသူငှာ ထုတ်ပြန်ခြင်း။<br/>' +
                'WOW EARN အသုံးပြုသူအခြေခံ 100,000 သို့ရောက်ရှိ။<br/>' +
                'ကွင်းဆက် အရောင်းအ၀ယ်များအတွက် ပံ့ပိုးမှု။<br/>' +
                'Dမြောက်မြားစွာသော DeFi ပရောဂျက်များဖြင့် ဖွဲ့စည်းထားသော မဟာဗျူဟာမြောက် ပူးပေါင်းဆောင်ရွက်မှုများ။'
        },
        data4:{
            q:'Q3/ 2023',
            d:'WOW EARN အသုံးပြုသူ အခြေခံ 500,000 သို့ ရောက်ရှိသည်။<br/>' +
                'ကမ္ဘာလုံးဆိုင်ရာ အမှတ်တံဆိပ် ပူးပေါင်းဆောင်ရွက်ခြင်း။<br/>' +
                'Web3 အော့ဖ်လိုင်းဖြစ်ရပ်များ စတင်ခြင်း။'
        },
        data5:{
            q:'Q4/ 2023',
            d:'WOW EARN အသုံးပြုသူ အခြေခံ 1,000,000 သို့ ရောက်ရှိသည်။<br/>' +
                'ဇာတ်လမ်းပေါင်းများစွာ TOKEN နှင့် NFT ပိုင်ဆိုင်မှုဒေတာ ပေါင်းစပ်ခြင်း။'
        },
        data6:{
            q:'Q1/ 2024',
            d:`တရားဝင် ဝဘ်ဆိုဒ်အသစ် ထွက်လာပါပြီ။<br/>
            DApp သည် နေ့စဥ်အသုံးပြုသူ 100,000 ကျော်ရှိသည်။<br/>
            WOW EARN အများသူငှာ ကွင်းဆက်ငွေကြေးကိုင်ဆောင်ထားသော လိပ်စာများသည် 500,000 ကျော်လွန်သွားပါပြီ။`
        },
        data7:{
            q:'Q2/ 2024',
            d:`WOW EARN အများသူငှာ ကွင်းဆက်အပေါ် အခြေခံသည့် DEX သည် အများသူငှာ စမ်းသပ်ဆော့ဖ်ဝဲကို စတင်ပြီး ဖြန့်ချိသည်။<br/>
            WOW EARN အများသူငှာ ကွင်းဆက်၏ gamefi ပရောဂျက်ကို တရားဝင် စတင်လိုက်ပြီဖြစ်သည်။<br/>
            WOW EARN ပိုက်ဆံအိတ်အသုံးပြုသူအတွေ့အကြုံကို မြှင့်တင်ပြီး ကွင်းဆက်ပေါင်းများစွာ ပံ့ပိုးမှုထည့်ပါ။<br/>
            10,000+ DApp အပလီကေးရှင်းများကို အောင်မြင်စွာ စတင်လိုက်ပါ။<br/>
            ကမ္ဘာကျော် blockchain အော့ဖ်လိုင်းပြပွဲများတွင် ပါဝင်ခြင်းသည် အမှတ်တံဆိပ်အသိအမြင်ကို သိသာထင်ရှားစွာ တိုးမြင့်စေသည်။<br/>
            ပေါက်ဖွားခြင်းပလပ်ဖောင်းတစ်ခုဖန်တီးပြီး WOW EARN အများသူငှာကွင်းဆက်၏ ဂေဟစနစ်ကို မြှင့်တင်ပါ။`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX ပစ်မှတ် TVL သည် 1M U ကိုကျော်လွန်သည်၊ ချေးငွေထုတ်ချေးသည့်ပစ်မှတ် TVL သည် 1M U ထက်ကျော်လွန်ပါသည်။<br/>
            WOWCoin သည် ဗဟိုမှ လဲလှယ်ခြင်းသို့ ဝင်ရောက်ပါ။<br/>
            တီထွင်ဖန်တီးနိုင်မှုအတွက် ငွေကြေးပံ့ပိုးမှုပေးရန်အတွက် ငွေကြေးထောက်ပံ့မှု ပလက်ဖောင်းတစ်ခု ဖန်တီးပါ။<br/>
            စဉ်ဆက်မပြတ် ထုတ်ကုန်ဖွံ့ဖြိုးတိုးတက်မှု၊ DApp ဂိမ်းဆော့ဖ်ဝဲများနှင့် Gamefi အက်ပ်လီကေးရှင်းများအပါအဝင် blockbuster အပလီကေးရှင်းများကို စတင်ခြင်း။`
        },
        data9:{
            q:'Q4/ 2024',
            d:`စျေးကွက်ထုတ်လုပ်သူများကိုထိန်းသိမ်းပါ၊ WOWCoin ၏တန်ဖိုးကိုထိန်းသိမ်းပြီးအောင်မြင်မှုများထုတ်ကုန်များကိုဆက်လက်ဖန်တီးရန်နှင့်မွမ်းမံပါ။<br/>
            အရံပစ္စည်းများသည် သုံးစွဲသူ၏ ကပ်စေးမှုကို သိသိသာသာ မြှင့်တင်ပေးပါသည်။<br/>
            WOW EARN DAO အုပ်ချုပ်ရေးသည် အပြည့်အဝ တိုးတက်ပါသည်။`
        },

    },
    partners:{
        title:'Our Partners And Medias'
    },
    downloadWallet : {
        title: "လုပ်ငန်းအားလုံးအတွက် လုပ်ငန်းနေသည်။ မကြာခဏ မှာရန်၊ မကြာခဏမှာမှာ။",
        subtitle: "ကျေးဇူးတင်ပြီးကိုယ်တိုင်အနေဖြင့်န",
        downWalletTop: "ဒေါင်းလုတ်ယူရန်",
        donwloadTitle: "Android ပါ။",
        titleIOS: "iOS ပါ။"


    },
    footer:{
        social:'Social media',
        resources:{
            title:'Resources',
            data1:'Whitepaper',
            data2:'Mining',
            data3:'Staking',
            data4:'RoadMap',
        },
        contact:'ဆက်သွယ်ရန်',
        app:{
            ios:'IOS',
            android:'Android',
            apk:'APK'
        }
    }

}
export default mm;
/*缅甸*/
