const sa = {
    brand:'WOW ERAN',
    nav:{
        Home:'بيت',
        Function:'الوظيفة',
        Mining:'تعدين',
        RoadMap:'خارطة الطريق',
        WhitePaper:'ورقة بيضاء',
        Blog:'مدونة',
        Task: 'مركز المهام'
    },
    language:'اللغة',
    banner:{
        title:'بوابة<br/>لويب 3',
        des:'فتح ملكية الأصول الرقمية الحقيقية:<br/>بوابتك إلى تجارب لا مثيل لها على الويب 3.',
        btn1:'إطلاق تطبيق Dapp',
        btn2:'تنزيل المحفظة',
        btn3:'تمت المراجعة بواسطة Hacken'
    },
    data:{
        data1:'المستخدمون النشطون يوميًا',
        data2:'البلدان المغطاة',
        data3:'المستخدم',
        data4:'الشركاء الاستراتيجيين',
        data5:'مستخدمو المجتمع',
    },
    features:{
        title:'الميزات',
        data1:'شراء العملات الرقمية',
        data2:'تطبيق التعدين',
        data3:'NFTs',
        data4:'بلوكشين الطبقة 1',
        data5:'المراهنة',
        data6:'تبادل',
        data7:'DEX',
        data8:'المجتمع'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin هي جزء من نظام WOW EARN البيئي، وهي منصة بلوك تشين تؤكد على الكفاءة العالية والتواصل في عالم التمويل اللامركزي (DeFi)، الاستكمال | زراعة العائد | التعدين.',
        data2:'سلسلة WOW EARN هي منصة طبقة 1 رائدة تهدف إلى توفير كفاءة غير مسبوقة وقابلية للتوسع لمختلف التطبيقات المعتمدة على بلوك تشين للمستخدمين والمطورين.',
        data3:'WOW Coin تلعب دورًا حاسمًا داخل هذا النظام، حيث تسهل المعاملات والمكافآت والرسوم، وتعمل كالعملة الأساسية للتفاعلات داخل منصة WOW EARN.',
        data4:'استخدامها في تحويل العملات، كما هو موضح في قسم المعاملات في المستكشف، يظهر الفائدة الأساسية لها في تمكين العمليات على بلوك تشين وتبادل القيم ضمن شبكة WOW EARN.',

    },

    mechanism:{
        title:'إجمالي العرض',
        data1:'تعدين المستخدم 35%',
        data2:'تعدين العقدة 20%',
        data3:'المؤسسة 8%',
        data4:'العرض العام (IDO) 3%',
        data5:'جولة البذور 14%',
        data6:'التطوير التشغيلي 10%',
        data7:'إدارة القيمة السوقية 5%',
        data8:'مكافأة المراهنة 4%',
        data9:'الحافز المجتمعي 0.95%',
        data10:'مكافأة الإسقاط الجوائز 0.05%',
        total:'إجمالي العرض 1 مليار',
    },
    roadmap:{
        title:'خريطة الطريق للتطوير',
        data1:{
            q:'Q4/ 2022',
            d:'تأسيس فريق WOW EARN.<br/>' +
                'إصدار ورقة بيضاء وخطة تطوير WOW EARN.'
        },
        data2:{
            q:'Q1/ 2023',
            d:'اختبار ناجح لمشروع DApp.<br/>' +
                'إطلاق WOW EARN.<br/>' +
                'دعم ETH ، BSC.<br/>' +
                'إدخال المراهنة على USDT.<br/>' +
                'آلية دعوة مرجعية فريدة.<br/>' +
                'وصول قاعدة مستخدمي WOW EARN إلى 50,000.<br/>' +
                'تأسيس شراكات مع وسائل الإعلام الرئيسية للبلوكشين.'
        },
        data3:{
            q:'Q2/ 2023',
            d:'اكتمال فحص العقد الذكي.<br/>' +
                'اجتاز فحص عقد DApp.<br/>' +
                'إطلاق صورة العلامة التجارية الجديدة.<br/>' +
                'إصدار عملة WOWCoin العام.<br/>' +
                'وصول قاعدة مستخدمي WOW EARN إلى 100,000.<br/>' +
                'دعم للمعاملات عبر السلاسل.<br/>' +
                'تشكيل شراكات استراتيجية مع مشاريع DeFi عديدة.'
        },
        data4:{
            q:'Q3/ 2023',
            d:'وصول قاعدة مستخدمي WOW EARN إلى 500,000.<br/>' +
                'تعاونات عالمية مع العلامات التجارية.<br/>' +
                'بدء الأحداث غير المتصلة بالويب3.'
        },
        data5:{
            q:'Q4/ 2023',
            d:'وصول قاعدة مستخدمي WOW EARN إلى 1,000,000.<br/>' +
                'تكامل بيانات TOKEN وNFT المتعددة السيناريو.'
        },
        data6:{
            q:'Q1/ 2024',
            d:`
            إطلاق الموقع الرسمي الجديد<br/>
            عدد المستخدمين النشطين اليوميين للـ DApp يتجاوز 100,000 مستخدم<br/>
            عدد عناوين الحفظ في سلسلة WOW EARN يتجاوز 500,000
            `
        },
        data7:{
            q:'Q2/ 2024',
            d:`
            بدء الاختبار العام وإطلاق DEX على أساس سلسلة WOW EARN<br/>
            انطلاق مشروع gamefi على سلسلة WOW EARN<br/>
            تحسين تجربة المستخدم لمحفظة WOW EARN بإضافة الدعم لعدة سلاسل<br/>
            نجاح إطلاق أكثر من 10,000 تطبيق DApp<br/>
            المشاركة في المعارض العالمية المعروفة للبلوكشين تحسن من شهرة العلامة التجارية بشكل ملحوظ<br/>
            تطوير منصة حضانة لتحسين نظام سلسلة WOW EARN البيئي
            `
        },
        data8:{
            q:'Q3/ 2024',
            d:`
            هدف DEX هو تجاوز TVL بقيمة 1M U، والهدف لمنتجات القروض هو تجاوز TVL بقيمة 1M U<br/>
            تسجيل دخول WOWCoin إلى البورصات المركزية<br/>
            تطوير منصة تمويل لدعم المشاريع الإبداعية<br/>
            التطوير المستمر للمنتجات، إطلاق تطبيقات رائعة بما في ذلك تطبيقات ألعاب DApp وتطبيقات Gamefi.
            `
        },
        data9:{
            q:'Q4/ 2024',
            d:`
            صيانة صانعي السوق للحفاظ على قيمة WOWCoin والتطوير المستمر لمنتجات ذات ابتكار<br/>
            تحسن ملحوظ في التصاق المستخدمين بالمنتجات المحيطة<br/>
            تحسين شامل لحوكمة WOW EARN DAO`
        },

    },
    partners:{
        title:'شركاؤنا ووسائل الإعلام'
    },
    downloadWallet : {
        title: "تداول بأمان وأمان في أي وقت. في أي مكان.",
    subtitle: "تداول في المنزل وأثناء التنقل مع تطبيقنا. متاح عبر متجر التطبيقات ومتجر Google Play.",
    downWalletTop: "تحميل من",
    donwloadTitle: "Android",
    titleIOS: "IOS"


    },
    footer:{
        social:'وسائل التواصل الاجتماعي',
        resources:{
            title:'موارد',
            data1:'ورقة بيضاء',
            data2:'التعدين',
            data3:'المراهنة',
            data4:'خارطة الطريق',
        },
        contact:'تواصل معنا',
        app:{
            ios:'IOS',
            android:'أندرويد',
            apk:'APK'
        }
    }

}
export default sa;
/*阿拉伯语*/
