const pk = {
    brand:'WOW ERAN',
    nav:{
        Home:'ہوم',
        Function:'فنکشن',
        Mining:'کان کنی',
        RoadMap:'راہ نقشہ',
        WhitePaper:'سفید کاغذ',
        Blog:'بلاگ',
        Task: 'اسک سینٹر'
    },
    language:'زبان',
    banner:{
        title:'ویب 3 کے دروازے',
        des:'اصل ڈیجیٹل اثاثہ کی ملکیت کو آزاد کریں:<br/>بے مثال ویب 3 تجارت کے لئے آپ کا پورٹل۔',
        btn1:'ڈی ایپ کا اجراء',
        btn2:'والیٹ ڈاؤن لوڈ کریں',
        btn3:'Hacken کی جانب سے جائزہ شدہ'
    },

    data:{
        data1:'روزانہ فعال صارفین',
        data2:'ملکوں کا احاطہ',
        data3:'صارف',
        data4:'اہم شراکت دار',
        data5:'کمیونٹی کے صارفین',
    },
    features:{
        title:'خصوصیات',
        data1:'کرپٹو خریدیں',
        data2:'کین کنی ڈی ایپ',
        data3:'این ایف ٹی',
        data4:'لیئر 1 بلاک چین',
        data5:'اسٹیکنگ',
        data6:'تبدیلی',
        data7:'ڈی ایکس',
        data8:'برادری'
    },
    wowcoin:{
    "title": 'WOW COIN',
    "data1": 'WOW Coin WOW EARN ایکوسسٹم کا حصہ ہے، ایک بلاک چین پلیٹ فارم جو ڈیسنٹرلائزڈ فنانس (DeFi)، اسٹیکنگ، ییلڈ فارمنگ، اور مائننگ میں بلند کارکردگی اور رابطے کی اہمیت پر زور دیتا ہے۔',
    "data2": 'WOW EARN چین ایک پہلا لیئر 1 پلیٹ فارم ہے جو مختلف بلاک چین پر مبنی اطلاقات کے لئے صارفین اور ڈویلپرز کو بے مثال کارکردگی اور پسماندگی فراہم کرنے کے لئے ڈیزائن کی گئی ہے۔',
    "data3":'WOW Coin اس ایکوسسٹم میں اہم کردار ادا کرتا ہے، لین دین، انعامات، اور فیس میں اعلی اور مسلسل ہوتا ہے، اور WOW EARN پلیٹ فارم کے اندر رابطوں کے لئے بنیادی کرنسی کے طور پر خدمت کرتا ہے۔',
    "data4": 'Its use in coin transfers,as detailed in the transactionssection of the explorer,demonstrates its fundamental utility inenabling blockchain operations and value exchange withinthe WOW EARN network.',

    },
    mechanism:{
        title:'کل سپلائی',
        data1:'صارف کان کنی 35٪',
        data2:'نوڈ کان کنی 20٪',
        data3:'بنیاد 8٪',
        data4:'عوامی پیشکش (آئی ڈی او) 3٪',
        data5:'بیج راؤنڈ 14٪',
        data6:'عملی ترقی 10٪',
        data7:'مارکیٹ قیمت کی انتظام 5٪',
        data8:'اسٹیکنگ انعام 4٪',
        data9:'کمیونٹی کی انعامی 0.95٪',
        data10:'ایئر ڈراپ انعام 0.05٪',
        total: '10 ارب کل سپلائی'

    },
    roadmap:{
        title:'ترقی کا راہ نقشہ',
        data1:{
            q:'Q4/ 2022',
            d:'WOW EARN ٹیم کی قائم کاری۔<br/>' +
                'WOW EARN سفید کاغذ اور ترقی کا منصوبہ جاری کرنا۔'
        },
        data2:{
            q:'Q1/ 2023',
            d:'DApp پروجیکٹ کی کامیاب ٹیسٹنگ۔<br/>' +
                'WOW EARN کا اجراء۔<br/>' +
                'ETH، BSC کی حمایت۔<br/>' +
                'USDT کی انٹراڈکشن۔<br/>' +
                'یونیک رفرنس کی دعوتی نظام۔<br/>' +
                'WOW EARN صارفین کی بیس 50000 تک پہنچتی ہے۔<br/>' +
                'اہم بلاک چین میڈیا آؤٹ لیٹس کے ساتھ شراکتوں کی بنیاد۔'
        },
        data3:{
            q:'Q2/ 2023',
            d:'اسمارٹ کنٹریکٹ کی آڈیٹ مکمل۔<br/>' +
                'DApp کنٹریکٹ کی آڈیٹ پاس ہوگئی۔<br/>' +
                'نئی برانڈ امیج کا اجراء۔<br/>' +
                'WOWCoin کی عوامی ریلیز۔<br/>' +
                'WOW EARN صارفین کی بیس 100000 تک پہنچتی ہے۔<br/>' +
                'کراس-چین ٹرانزیکشنز کی حمایت۔<br/>' +
                'مختلف ڈی فائنس پروجیکٹس کے ساتھ استراتیجی شراکتوں کی بنیاد۔'
        },
        data4:{
            q:'Q3/ 2023',
            d:'WOW EARN صارفین کی بیس 500000 تک پہنچتی ہے۔<br/>' +
                'عالمی برانڈ کے تعاون۔<br/>' +
                'ویب3 آف لائن واقعات کی آغاز۔'
        },
        data5:{
            q:'Q4/ 2023',
            d:'WOW EARN صارفین کی بیس 1000000 تک پہنچتی ہے۔<br/>' +
                'متعدد مناظر کی ٹوکن اور این ایف ٹی اثاثہ ڈیٹا کے انضمام کا تعلق۔'
        },
        data6:{
            q:'Q1/ 2024',
            d:`<br/>نیا آفیشل ویب سائٹ کا اجرا
            <br/>DApp کے روزانہ فعال صارفین کی تعداد 100,000 سے تجاوز کر گئی
            <br/>WOW EARN پبلک چین پر کوئن رکھنے والے ایڈریسز کی تعداد 500,000 سے زیادہ ہو گئی
            `
        },
        data7:{
            q:'Q2/ 2024',
            d:`<br/>WOW EARN پبلک چین کی بنیاد پر DEX کا عوامی ٹیسٹنگ کا آغاز اور اجرا
            <br/>WOW EARN پبلک چین پر gamefi پروجیکٹ کا باضابطہ آغاز
            <br/>WOW EARN والٹ کے صارف تجربہ میں بہتری لانا، ملٹی چین سپورٹ کا اضافہ
            <br/>10,000+ DApp ایپلیکیشنز کا کامیاب اجرا
            <br/>عالمی سطح پر معروف بلاک چین آف لائن نمائشوں میں شرکت سے برانڈ کی شناخت میں نمایاں بہتری
            <br/>انکیوبیشن پلیٹ فارم تیار کرنا، WOW EARN پبلک چین کے ایکوسسٹم کو بہتر بنانا
            `
        },
        data8:{
            q:'Q3/ 2024',
            d:`<br/>DEX کا ہدف TVL 1M U کو تجاوز کرنا, قرض کی مصنوعات کا ہدف TVL 1M U کو تجاوز کرنا
            <br/>WOWCoin مرکزی تبادلہ میں داخل ہوا
            <br/>تخلیقی منصوبوں کے لئے مالی مدد فراہم کرنے کے لئے فنانسنگ پلیٹ فارم بنانا
            مسلسل مصنوعات کی ترقی، بلاک بسٹر ایپلیکیشنز کی لانچنگ، بشمول DApp گیمنگ ایپلیکیشنز اور Gamefi ایپلیکیشنز۔
            `
        },
        data9:{
            q:'Q4/ 2024',
            d:`<br/>مارکیٹ میکرز کی دیکھ بھال، WOWCoin کی قیمت کو برقرار رکھنا اور مسلسل انوویٹو مصنوعات کی ترقی
            <br/>پیریفیرل مصنوعات میں صارفین کی وابستگی میں نمایاں اضافہ
            <br/>WOW EARN DAO کی حکمرانی کا مکمل بہتر بنانا`
        },
    },
    partners:{
        title:'ہمارے شراکت دار اور میڈیا'
    },
    downloadWallet : {
        title: "بے خوفی اور محفوظی کے ساتھ ہر وقت کاروبار کریں۔ ہر جگہ۔",
        subtitle: "ہماری ایپ کے ساتھ گھر پر اور گردش کے دوران تجارت کریں۔ App Store اور Google Play Store کے ذریعے دستیاب ہے۔",
        downWalletTop: "سے ڈاؤن لوڈ کریں",
        donwloadTitle: "انڈروئد",
        titleIOS: "آئی او ایس"


    },
    footer:{
        social:'سوشل میڈیا',
        resources:{
            title:'وسائل',
            data1:'سفید کاغذ',
            data2:'MINING',
            data3:'اسٹیکنگ',
            data4:'راہ نقشہ',
        },
        contact:'ہم سے رابطہ کریں',
        app:{
            ios:'IOS',
            android:'Android',
            apk:'APK'
        }
    }
}

export default pk;
/*乌尔都语*/
