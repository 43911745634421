const id = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Beranda',
        Function: 'Fungsi',
        Mining: 'Penambangan',
        RoadMap: 'Peta Jalan',
        WhitePaper: 'WhitePaper',
        Blog: 'Blog',
        Task: ' Pusat Tugas'
    },
    language: 'Bahasa',
    banner: {
        title: 'Gerbang<br/>Ke Web3',
        des: 'Buka kepemilikan aset digital yang sebenar:<br/>Portal Anda ke pengalaman Web3 yang tak tertandingi.',
        btn1: 'Luncurkan Dapp',
        btn2: 'Unduh Wallet',
        btn3:'Diaudit oleh Hacken'
    },
    data: {
        data1: 'Pengguna Aktif Harian',
        data2: 'Negara yang Dicakup',
        data3: 'Pengguna',
        data4: 'Mitra Strategis',
        data5: 'Pengguna Komunitas',
    },
    features: {
        title: 'FITUR',
        data1: 'Beli Kripto',
        data2: 'Dapp Penambangan',
        data3: 'NFTs',
        data4: 'Layer1 Blockchain',
        data5: 'Staking',
        data6: 'Tukar',
        data7: 'DEX',
        data8:'Komunitas'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin adalah bagian dari ekosistem WOW EARN, platform blockchain yang menekankan efisiensi dan konektivitas tinggi dalam dunia keuangan terdesentralisasi (DeFi), staking | yield farming | penambangan.',
        data2: 'Rantai WOW EARN adalah platform Lapisan 1 yang menjadi perintis dirancang untuk menawarkan efisiensi dan skalabilitas tak tertandingi bagi berbagai aplikasi berbasis blockchain.',
        data3: 'WOW Coin memainkan peran penting dalam ekosistem ini, memfasilitasi transaksi, imbalan, dan biaya, dan berfungsi sebagai mata uang utama untuk interaksi dalam platform WOW EARN.',
        data4: 'Penggunaannya dalam transfer koin, seperti yang dijelaskan dalam bagian transaksi penjelajah, menunjukkan utilitasnya yang mendasar dalam memungkinkan operasi blockchain dan pertukaran nilai dalam jaringan WOW EARN.',
    },
    mechanism: {
        title: 'Total Pasokan',
        data1: 'Penambangan Pengguna 35%',
        data2: 'Penambangan Node 20%',
        data3: 'Yayasan 8%',
        data4: 'Penawaran Umum (IDO) 3%',
        data5: 'Putaran Benih 14%',
        data6: 'Pembangunan Operasional 10%',
        data7: 'Manajemen Nilai Pasar 5%',
        data8: 'Imbalan Staking 4%',
        data9: 'Insentif Komunitas 0.95%',
        data10: 'Imbalan Airdrop 0.05%',
        total: '1 Miliar Total Pasokan',
    },
    roadmap: {
        title: 'Peta Jalan Pengembangan',
        data1: {
            q: 'K4/ 2022',
            d: 'Pendirian Tim WOW EARN.<br/>' +
                'Rilis Whitepaper WOW EARN dan rencana pengembangan.'
        },
        data2: {
            q: 'K1/ 2023',
            d: 'Pengujian berhasil proyek DApp.<br/>' +
                'Peluncuran WOW EARN.<br/>' +
                'Dukungan untuk ETH, BSC.<br/>' +
                'Pengenalan staking USDT.<br/>' +
                'Mekanisme undangan referral unik.<br/>' +
                'Basis pengguna WOW EARN mencapai 50.000.<br/>' +
                'Pendirian kemitraan dengan outlet media blockchain utama.'
        },
        data3: {
            q: 'K2/ 2023',
            d: 'Audit kontrak pintar selesai.<br/>' +
                'Audit kontrak DApp lulus.<br/>' +
                'Peluncuran citra merek baru.<br/>' +
                'Rilis publik WOWCoin.<br/>' +
                'Basis pengguna WOW EARN mencapai 100.000.<br/>' +
                'Dukungan untuk transaksi lintas-rantai.<br/>' +
                'Pendirian kemitraan strategis dengan berbagai proyek DeFi.'
        },
        data4: {
            q: 'K3/ 2023',
            d: 'Basis pengguna WOW EARN mencapai 500.000.<br/>' +
                'Kolaborasi merek global.<br/>' +
                'Inisiasi acara offline Web3.'
        },
        data5: {
            q: 'K4/ 2023',
            d: 'Basis pengguna WOW EARN mencapai 1.000.000.<br/>' +
                'Integrasi data aset TOKEN dan NFT dalam beberapa skenario.'
        },
        data6: {
            q: 'K1/ 2024',
            d:`Peluncuran situs web resmi baru<br/>
            Pengguna aktif harian DApp melebihi 100.000 orang<br/>
            Alamat pemegang koin pada rantai publik WOW EARN melebihi 500.000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`DEX berbasis rantai publik WOW EARN memulai uji coba publik dan diluncurkan<br/>
            Proyek gamefi pada rantai publik WOW EARN resmi dimulai<br/>
            Meningkatkan pengalaman pengguna dompet WOW EARN dengan dukungan multi-rantai<br/>
            Sukses meluncurkan lebih dari 10.000 aplikasi DApp<br/>
            Partisipasi dalam pameran blockchain offline terkenal secara global secara signifikan meningkatkan visibilitas merek<br/>
            Membangun platform inkubasi untuk menyempurnakan ekosistem rantai publik WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX menargetkan TVL melebihi 1M U, produk pinjaman menargetkan TVL melebihi 1M U<br/>
            WOWCoin terdaftar di bursa terpusat<br/>
            Membangun platform pembiayaan untuk memberikan dukungan dana bagi proyek kreatif<br/>
            Pengembangan produk yang berkelanjutan, meluncurkan aplikasi blockbuster, termasuk aplikasi game DApp dan aplikasi Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Memelihara pembuat pasar untuk menjaga nilai WOWCoin dan pengembangan produk terobosan secara berkelanjutan<br/>
            Produk sekitar memiliki peningkatan signifikan dalam kelekatan pengguna<br/>
            Tata kelola WOW EARN DAO disempurnakan secara menyeluruh`
        },
    },
    partners: {
        title: 'Mitra dan Media Kami'
    },

    downloadWallet : {
        title: "Bertransaksi dengan aman dan terlindungi kapan saja. Di mana saja.",
        subtitle: "Bertransaksi di rumah dan saat bepergian dengan aplikasi kami. Tersedia di App Store dan Google Play Store.",
        downWalletTop: "Unduh dari",
        donwloadTitle: "एंड्रॉयड",
        titleIOS: "आईओएस",



    },
    footer: {
        social: 'Media Sosial',
        resources: {
            title: 'Sumber Daya',
            data1: 'WhitePaper',
            data2: 'Penambangan',
            data3: 'Staking',
            data4: 'Peta Jalan',
        },
        contact: 'Hubungi kami',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}

export default id;

/*印度尼西亚语*/
