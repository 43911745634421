const it = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Home',
        Function: 'Funzione',
        Mining: 'Mining',
        RoadMap: 'Percorso',
        WhitePaper: 'WhitePaper',
        Blog: 'Blog',
        Task: 'Centro Attività'
    },
    language: 'Lingua',
    banner: {
        title: 'Portale<br/>Per Web3',
        des: 'Sblocca la vera proprietà di asset digitali:<br/>Il tuo portale verso esperienze Web3 senza pari.',
        btn1: 'Lancia Dapp',
        btn2: 'Scarica Wallet',
        btn3:'Verificato da Hacken'
    },

    data: {
        data1: 'Attivi giornalieri',
        data2: 'Paesi coperti',
        data3: 'Utente',
        data4: 'Partner strategici',
        data5: 'Utenti community',
    },
    features: {
        title: 'Funzionalità',
        data1: 'Acquista Crypto',
        data2: 'Mining Dapp',
        data3: 'NFT',
        data4: 'Blockchain Layer1',
        data5: 'Staking',
        data6: 'Scambio',
        data7: 'DEX',
        data8:'Comunità'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin fa parte dell\'ecosistema WOW EARN, una piattaforma blockchain che enfatizza l\'efficienza e la connettività nell\'ambito della finanza decentralizzata (DeFi), staking | yield farming | mining.',
        data2: 'La catena WOW EARN è una piattaforma Layer 1 all\'avanguardia progettata per offrire agli utenti e agli sviluppatori un\'efficienza e una scalabilità senza pari per varie applicazioni basate su blockchain.',
        data3: 'La WOW Coin svolge un ruolo cruciale all\'interno di questo ecosistema, facilitando le transazioni, le ricompense e le commissioni e fungendo da valuta primaria per le interazioni all\'interno della piattaforma WOW EARN.',
        data4: 'Il suo utilizzo nei trasferimenti di monete, come dettagliato nella sezione delle transazioni dell\'esploratore, dimostra la sua utilità fondamentale nell\'abilitare operazioni blockchain e scambi di valore all\'interno della rete WOW EARN.',
    },
    mechanism: {
        title: 'Offerta Totale',
        data1: 'Mining dell\'utente 35%',
        data2: 'Mining dei nodi 20%',
        data3: 'Fondazione 8%',
        data4: 'Offerta pubblica (IDO) 3%',
        data5: 'Round iniziale 14%',
        data6: 'Sviluppo operativo 10%',
        data7: 'Gestione del valore di mercato 5%',
        data8: 'Ricompensa di staking 4%',
        data9: 'Incentivo alla comunità 0,95%',
        data10: 'Ricompensa Airdrop 0,05%',
        total: '1 miliardo di offerta totale',
    },
    roadmap: {
        title: 'Percorso di sviluppo',
        data1: {
            q: 'Q4/ 2022',
            d: 'Creazione del Team WOW EARN.<br/>' +
                'Rilascio del Libro bianco e del piano di sviluppo di WOW EARN.'
        },
        data2: {
            q: 'Q1/ 2023',
            d: 'Test di successo del progetto DApp.<br/>' +
                'Lancio di WOW EARN.<br/>' +
                'Supporto per ETH, BSC.<br/>' +
                'Introduzione dello staking USDT.<br/>' +
                'Meccanismo di invito di riferimento unico.<br/>' +
                'La base utenti di WOW EARN raggiunge 50.000.<br/>' +
                'Creazione di partnership con importanti media blockchain.'
        },
        data3: {
            q: 'Q2/ 2023',
            d: 'Auditing del contratto intelligente completato.<br/>' +
                'Auditing del contratto DApp superato.<br/>' +
                'Lancio della nuova immagine del marchio.<br/>' +
                'Rilascio pubblico di WOWCoin.<br/>' +
                'La base utenti di WOW EARN raggiunge 100.000.<br/>' +
                'Supporto per le transazioni cross-chain.<br/>' +
                'Formazione di partnership strategiche con numerosi progetti DeFi.'
        },
        data4: {
            q: 'Q3/ 2023',
            d: 'La base utenti di WOW EARN raggiunge 500.000.<br/>' +
                'Collaborazioni di marchi globali.<br/>' +
                'Inizio di eventi offline Web3.'
        },
        data5: {
            q: 'Q4/ 2023',
            d: 'La base utenti di WOW EARN raggiunge 1.000.000.<br/>' +
                'Integrazione di dati di asset TOKEN e NFT multi-scenari.'
        },
        data6: {
            q: 'Q1/ 2024',
            d:`Lancio del nuovo sito ufficiale<br/>
            Utenti attivi giornalieri di DApp superano i 100.000<br/>
            Gli indirizzi detentori nella blockchain WOW EARN superano i 500.000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Beta pubblica e lancio del DEX basato sulla blockchain WOW EARN<br/>
            Avvio ufficiale del progetto GameFi sulla blockchain WOW EARN<br/>
            Miglioramento dell'esperienza utente del portafoglio WOW EARN con supporto multi-chain aggiunto<br/>
            Lancio di successo di oltre 10.000 applicazioni DApp<br/>
            Partecipazione a mostre offline di blockchain di fama mondiale aumenta significativamente la visibilità del marchio<br/>
            Sviluppo di una piattaforma di incubazione per perfezionare l'ecosistema della blockchain WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`L'obiettivo di TVL del DEX supera 1M U, l'obiettivo di TVL del prodotto di prestito supera 1M U<br/>
            WOWCoin quotato negli scambi centralizzati<br/>
            Sviluppo di una piattaforma di finanziamento per fornire supporto finanziario ai progetti creativi<br/>
            Sviluppo continuo di prodotti, lancio di applicazioni di grande successo, comprese applicazioni di giochi DApp e applicazioni Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Manutenzione dei market maker per preservare il valore di WOWCoin e sviluppo continuo di prodotti innovativi<br/>
            Miglioramento significativo dell'aderenza degli utenti ai prodotti periferici<br/>
            Perfezionamento completo della governance di WOW EARN DAO`
        },
    },
    partners: {
        title: 'I nostri Partner e Media'
    },
    downloadWallet : {
        title: "Fai trading in modo sicuro e protetto in qualsiasi momento. Dovunque.",
        subtitle: "Fai trading a casa e in viaggio con la nostra app. Disponibile su App Store e Google Play Store.",
        downWalletTop: "Scarica da",
        donwloadTitle: "Androide",
        titleIOS: "iOS",


    },
    footer: {
        social: 'Social media',
        resources: {
            title: 'Risorse',
            data1: 'Whitepaper',
            data2: 'Mining',
            data3: 'Staking',
            data4: 'Percorso',
        },
        contact: 'Contattaci',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}
export default it;

/*意大利语*/
