const vn = {
    brand:'WOW ERAN',
    nav:{
        Home:'Home',
        Function:'Chức năng',
        Mining:'Đào tạo',
        RoadMap:'Lộ trình',
        WhitePaper:'WhitePaper',
        Blog:'Blog',
        Task: 'Trung tâm Nhiệm vụ'
    },
    language:'Ngôn ngữ',
    banner:{
        title:'Cổng<br/>Đến Web3',
        des:'Mở khóa quyền sở hữu tài sản kỹ thuật số chân thực:<br/>Cổng của bạn đến trải nghiệm Web3 không giới hạn.',
        btn1:'Khởi chạy Dapp',
        btn2:'Tải ví xuống',
        btn3:' Được kiểm tra bởi Hacken'
    },

    data:{
        data1:'Người dùng hàng ngày.',
        data2:'Quốc gia.',
        data3:'Người dùng',
        data4:'Đối tác',
        data5:'Cộng đồng',
    },
    features:{
        title:'TÍNH NĂNG',
        data1:'Mua Crypto',
        data2:'Dapp đào tạo',
        data3:'NFTs',
        data4:'Blockchain Layer1',
        data5:'Staking',
        data6:'Đổi',
        data7:'DEX',
        data8:'Cộng đồng'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin là một phần của hệ sinh thái WOW EARN, một nền tảng blockchain nhấn mạnh vào hiệu suất cao và kết nối trong thế giới tài chính phi tập trung (DeFi), đào | nông nghiệp cho thu hoạch | khai thác.',
        data2:'Chuỗi WOW EARN là một nền tảng Layer 1 tiên phong được thiết kế để cung cấp hiệu suất và khả năng mở rộng không giới hạn cho các ứng dụng dựa trên blockchain.',
        data3:'WOW Coin đóng vai trò quan trọng trong hệ sinh thái này, giúp giao dịch, phần thưởng và phí, và phục vụ làm loại tiền chính cho các tương tác trong nền tảng WOW EARN.',
        data4:'Việc sử dụng của nó trong việc chuyển đổi tiền xu, như được mô tả trong phần giao dịch của người khám phá, chứng tỏ tính cơ bản của nó trong việc kích hoạt các hoạt động blockchain và trao đổi giá trị trong mạng lưới WOW EARN.',

    },
    mechanism:{
        title:'Tổng cung',
        data1:'Đào tạo người dùng 35%',
        data2:'Đào tạo nút 20%',
        data3:'Quỹ 8%',
        data4:'Cung cấp công cộng (IDO) 3%',
        data5:'Vòng gốc 14%',
        data6:'Phát triển vận hành 10%',
        data7:'Quản lý giá trị thị trường 5%',
        data8:'Phần thưởng Staking 4%',
        data9:'Cơ hội cộng đồng 0.95%',
        data10:'Phần thưởng Airdrop 0.05%',
        total:'10 tỷ tổng cung',
    },
    roadmap:{
        title:'Lộ trình phát triển',
        data1:{
            q:'Q4/ 2022',
            d:'Thành lập Đội WOW EARN.<br/>' +
                'Phát hành Bản trắng và kế hoạch phát triển WOW EARN.'
        },
        data2:{
            q:'Q1/ 2023',
            d:'Kiểm tra thành công của dự án DApp.<br/>' +
                'Khởi chạy WOW EARN.<br/>' +
                'Hỗ trợ cho ETH, BSC.<br/>' +
                'Giới thiệu việc stake USDT.<br/>' +
                'Cơ chế mời giới thiệu duy nhất.<br/>' +
                'Số lượng người dùng WOW EARN đạt 50,000.<br/>' +
                'Thành lập đối tác với các truyền thông blockchain lớn.'
        },
        data3:{
            q:'Q2/ 2023',
            d:'Kiểm toán hợp đồng thông minh hoàn thành.<br/>' +
                'Kiểm toán hợp đồng DApp đã qua.<br/>' +
                'Khởi chạy hình ảnh thương hiệu mới.<br/>' +
                'Phát hành công cộng của WOWCoin.<br/>' +
                'Số lượng người dùng WOW EARN đạt 100,000.<br/>' +
                'Hỗ trợ cho các giao dịch qua chuỗi.<br/>' +
                'Thành lập đối tác chiến lược với nhiều dự án DeFi.'
        },
        data4:{
            q:'Q3/ 2023',
            d:'Số lượng người dùng WOW EARN đạt 500,000.<br/>' +
                'Cộng tác thương hiệu toàn cầu.<br/>' +
                'Bắt đầu sự kiện ngoại tuyến Web3.'
        },
        data5:{
            q:'Q4/ 2023',
            d:'Số lượng người dùng WOW EARN đạt 1,000,000.<br/>' +
                'Tích hợp dữ liệu tài sản TOKEN và NFT đa tình huống.'
        },
        data6:{
            q:'Q1/ 2024',
            d:`Trang web chính thức mới được phát hành<br/>
            Số người dùng hoạt động hàng ngày của DApp vượt qua 100.000 người<br/>
            Địa chỉ giữ coin trên chuỗi công khai WOW EARN vượt qua 500.000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`DEX dựa trên chuỗi công khai WOW EARN bắt đầu thử nghiệm công cộng và được phát hành<br/>
            Dự án gamefi trên chuỗi công khai WOW EARN chính thức khởi động<br/>
            Cải thiện trải nghiệm người dùng ví WOW EARN, tăng cường hỗ trợ nhiều chuỗi<br/>
            Thành công trong việc phát hành hơn 10.000 ứng dụng DApp<br/>
            Tham gia các hội nghị blockchain nổi tiếng toàn cầu giúp nâng cao đáng kể độ nhận biết thương hiệu<br/>
            Xây dựng nền tảng ươm mầm, hoàn thiện hệ sinh thái chuỗi công khai WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`Mục tiêu TVL của DEX vượt qua 1M U, mục tiêu TVL của sản phẩm cho vay vượt qua 1M U<br/>
            WOWCoin đăng nhập vào sàn giao dịch tập trung<br/>
            Xây dựng nền tảng tài chính, cung cấp hỗ trợ tài chính cho các dự án sáng tạo<br/>
            Phát triển sản phẩm liên tục, ra mắt các ứng dụng nổi bật, bao gồm các ứng dụng game DApp và ứng dụng Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Bảo trì nhà tạo lập thị trường, duy trì giá trị của WOWCoin và phát triển liên tục các sản phẩm đột phá<br/>
            Sản phẩm phụ trợ có sự gia tăng đáng kể về sự gắn bó của người dùng<br/>
            Quản trị WOW EARN DAO được hoàn thiện toàn diện`
        },

    },
    partners:{
        title:'Đối tác và Phương tiện của chúng tôi'
    },
    downloadWallet : {
        title: "Giao dịch một cách an toàn và đáng tin cậy vào bất kỳ thời điểm nào. Bất cứ nơi đâu.",
    subtitle: "Giao dịch tại nhà và khi di chuyển với ứng dụng của chúng tôi. Có sẵn trên App Store và Google Play Store.",
    downWalletTop: "Tải từ",
    donwloadTitle: "Android",
        titleIOS: "iOS",


    },
    footer:{
        social:'Mạng xã hội',
        resources:{
            title:'Tài nguyên',
            data1:'WhitePaper',
            data2:'KHAI THÁC',
            data3:'Staking',
            data4:'LỘ TRÌNH',
        },
        contact:'Liên hệ với chúng tôi',
        app:{
            ios:'IOS',
            android:'Android',
            apk:'APK'
        }
    }

}
export default vn;

/*越南语*/
