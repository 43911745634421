const pt = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'Início',
        Function: 'Função',
        Mining: 'Mineração',
        RoadMap: 'Roteiro',
        WhitePaper: 'WhitePaper',
        Blog: 'Blog',
        Task: 'Centro de Tarefas'
    },
    language: 'Idioma',
    banner: {
        title: 'Gateway<br/>Para Web3',
        des: 'Desbloqueie a verdadeira propriedade de ativos digitais:<br/>Seu portal para experiências incomparáveis do Web3.',
        btn1: 'Iniciar Dapp',
        btn2: 'Baixar Carteira',
        btn3:'Auditado por Hacken'
    },
    data: {
        data1: 'Usuários ativos diários',
        data2: 'Países cobertos',
        data3: 'Usuário',
        data4: 'Parceiros estratégicos',
        data5: 'Usuários da comunidade',
    },
    features: {
        title: 'RECURSOS',
        data1: 'Comprar Crypto',
        data2: 'Mineração Dapp',
        data3: 'NFTs',
        data4: 'Layer1 Blockchain',
        data5: 'Staking',
        data6: 'Troca',
        data7: 'DEX',
        data8:'Comunidade'
    },
    wowcoin: {
        title: 'WOW COIN',
        data1: 'WOW Coin faz parte do ecossistema WOW EARN, uma plataforma blockchain que enfatiza alta eficiência e conectividade no mundo das finanças descentralizadas (DeFi), staking | yield farming | mining.',
        data2: 'A cadeia WOW EARN é uma plataforma pioneira de Camada 1 projetada para oferecer eficiência e escalabilidade incomparáveis para várias aplicações baseadas em blockchain.',
        data3: 'WOW Coin desempenha um papel crucial dentro deste ecossistema, facilitando transações, recompensas e taxas, e servindo como a moeda primária para interações dentro da plataforma WOW EARN.',
        data4: 'Seu uso em transferências de moedas, como detalhado na seção de transações do explorador, demonstra sua utilidade fundamental na habilitação de operações de blockchain e troca de valor dentro da rede WOW EARN.',
    },
    mechanism: {
        title: 'Fornecimento total',
        data1: 'Mineração de usuário 35%',
        data2: 'Mineração de nó 20%',
        data3: 'Fundação 8%',
        data4: 'Oferta pública (IDO) 3%',
        data5: 'Rodada de semente 14%',
        data6: 'Desenvolvimento operacional 10%',
        data7: 'Gerenciamento de valor de mercado 5%',
        data8: 'Recompensa de staking 4%',
        data9: 'Incentivo da comunidade 0.95%',
        data10: 'Recompensa de airdrop 0.05%',
        total: '1 bilhão de fornecimento total',
    },
    roadmap: {
        title: 'Roteiro de Desenvolvimento',
        data1: {
            q: 'T4/ 2022',
            d: 'Estabelecimento da equipe WOW EARN.<br/>' +
                'Lançamento do Whitepaper e plano de desenvolvimento da WOW EARN.'
        },
        data2: {
            q: 'T1/ 2023',
            d: 'Teste bem-sucedido do projeto DApp.<br/>' +
                'Lançamento da WOW EARN.<br/>' +
                'Suporte para ETH, BSC.<br/>' +
                'Introdução do staking USDT.<br/>' +
                'Mecanismo exclusivo de convite para referência.<br/>' +
                'Base de usuários WOW EARN atinge 50.000.<br/>' +
                'Estabelecimento de parcerias com grandes meios de comunicação blockchain.'
        },
        data3: {
            q: 'T2/ 2023',
            d: 'Auditoria de contrato inteligente concluída.<br/>' +
                'Auditoria de contrato DApp aprovada.<br/>' +
                'Lançamento de nova imagem de marca.<br/>' +
                'Lançamento público do WOWCoin.<br/>' +
                'Base de usuários WOW EARN atinge 100.000.<br/>' +
                'Suporte para transações entre cadeias.<br/>' +
                'Parcerias estratégicas formadas com numerosos projetos DeFi.'
        },
        data4: {
            q: 'T3/ 2023',
            d: 'Base de usuários WOW EARN atinge 500.000.<br/>' +
                'Colaborações globais de marca.<br/>' +
                'Início de eventos offline Web3.'
        },
        data5: {
            q: 'T4/ 2023',
            d: 'Base de usuários WOW EARN atinge 1.000.000.<br/>' +
                'Integração de dados de ativos TOKEN e NFT de vários cenários.'
        },
        data6: {
            q: 'T1/ 2024',
            d:`Lançamento do novo site oficial<br/>
            Usuários ativos diários de DApp ultrapassam 100.000<br/>
            Endereços de detenção na cadeia pública WOW EARN ultrapassam 500.000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Beta público e lançamento do DEX baseado na cadeia pública WOW EARN<br/>
            Lançamento oficial do projeto GameFi na cadeia pública WOW EARN<br/>
            Melhoria da experiência do usuário da carteira WOW EARN com suporte a múltiplas cadeias<br/>
            Lançamento bem-sucedido de mais de 10.000 aplicações DApp<br/>
            Participação em exposições de blockchain offline mundialmente renomadas aumenta significativamente a visibilidade da marca<br/>
            Desenvolvimento de uma plataforma de incubação para aprimorar o ecossistema da cadeia pública WOW EARN`
        },
        data8:{
            q:'Q3/ 2024',
            d:`O DEX visa ultrapassar um Valor Total Bloqueado (TVL) de 1M U, os produtos de empréstimo visam ultrapassar um TVL de 1M U<br/>
            WOWCoin listado em bolsas centralizadas<br/>
            Desenvolvimento de uma plataforma de financiamento para fornecer apoio financeiro a projetos criativos<br/>
            Desenvolvimento contínuo de produtos, lançamento de aplicações de sucesso, incluindo aplicações de jogos DApp e aplicações Gamefi.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Manutenção de formadores de mercado para preservar o valor do WOWCoin e desenvolvimento contínuo de produtos inovadores<br/>
            Melhoria significativa na aderência do usuário para produtos periféricos<br/>
            A governança do WOW EARN DAO é completamente aperfeiçoada`
        },
    },
    partners: {
        title: 'Nossos Parceiros e Mídias'
    },
    downloadWallet : {
        title: "Negocie de forma segura e protegida a qualquer momento. Em qualquer lugar.",
        subtitle: "Negocie em casa e em movimento com o nosso aplicativo. Disponível na App Store e Google Play Store.",
        downWalletTop: "Baixar de",
        donwloadTitle: "Android",
        titleIOS: "IOS"


    },
    footer: {
        social: 'Mídia social',
        resources: {
            title: 'Recursos',
            data1: 'WhitePaper',
            data2: 'Mineração',
            data3: 'Staking',
            data4: 'Roteiro',
        },
        contact: 'Contate-nos',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}

export default pt;
/*葡萄牙语*/
