<template>
  <header
    class="container header"
    :class="isOpen ? 'open' : ''"
    id="Home"
    @touchmove.prevent
  >
    <nav class="container-box navbar">
      <div class="navbar-brand-toggler">
        <div class="navbar-brand">
          <img src="@/assets/images/logo.svg" />
          <span>WOW EARN</span>
        </div>
        <div class="navbar-toggler" v-on:click="toOpen">
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>

      <div class="collapse" id="navbarContent">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a
              class="nav-link"
              href="javascript:void(0);"
              @click="scrollToSection('Home')"
              >{{ t.nav.Home }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              @click="scrollToSection('Function')"
              >{{ t.nav.Function }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              @click="scrollToSection('Mining')"
              >{{ t.nav.Mining }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              @click="scrollToSection('RoadMap')"
              >{{ t.nav.RoadMap }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://wowearn.gitbook.io/docs">{{
              t.nav.WhitePaper
            }}</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              @click="redirectToblog()"
              >{{ t.nav.Blog }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="showToast('Stay tuned')"
              >{{ t.nav.Task }}</a
            >
          </li>
        </ul>
        <div class="navbar-other">
          <div class="browsing-mode">
            <div
              v-if="browseMode == 'dark'"
              class="dark-mode"
              v-on:click="toLight"
            >
              <img src="@/assets/images/light_icon.png" /><span>Light</span>
            </div>
            <div
              v-if="browseMode == 'light'"
              class="light-mode"
              v-on:click="toDark"
            >
              <img src="@/assets/images/dark_icon.png" /><span>Dark</span>
            </div>
          </div>
          <div class="change-lang" v-click-outside="toClose">
            <div class="change-lang-btn">
              <img src="@/assets/images/lang_icon.svg" />
              <span>{{ t.language }}</span>
            </div>
            <div class="mask"></div>
            <div class="lang-list">
              <div class="lang-close" @click="toClose"></div>

              <ul @touchmove.stop>
                <li v-for="(item, index) in lang" :key="index" :alt="item.name" :data-val="item.val" @click="changeLang">
                  <img
                    :src="item.icon"
                    :alt="item.name"
                    :data-val="item.val"
                  />
                  <span :data-val="item.val">{{ item.self }}</span>
                </li>
              </ul>
            </div>
          </div>
          <a href="https://app.wowearn.com/" class="launch-dapp">{{
            t.banner.btn1
          }}</a>
        </div>
      </div>
    </nav>
  </header>

  <div class="container banner">
    <div class="container-box banner-box">
      <div class="banner-text-box">
        <div class="banner-text">
          <div
            data-wow-duration="0.6s"
            class="wow animate__animated animate__fadeInLeftBig banner-text-title"
            v-html="t.banner.title"
          ></div>
          <div></div>
          <div
            data-wow-duration="0.6s"
            data-wow-delay="0.3s"
            class="wow animate__animated animate__fadeInLeftBig banner-text-des"
            v-html="t.banner.des"
          ></div>
        </div>
        <div class="banner-btn">
          <a
            href="https://app.wowearn.com"
            data-wow-duration="0.6s"
            data-wow-delay="0.6s"
            class="wow animate__animated animate__bounceIn"
          >
            <img src="@/assets/images/launch_icon.svg" />
            <span>{{ t.banner.btn1 }}</span>
          </a>
          <!-- <a href="" data-wow-duration="1s" data-wow-delay="1.5s" class="wow animate__animated animate__bounceIn">
                    <img src="@/assets/images/download_icon.svg" />
                    <span>{{t.banner.btn2}}</span>
                  </a> -->

          <a
            @click="navigateToDownloadWallet"
            data-wow-duration="0.6s"
            data-wow-delay="0.6s"
            class="wow animate__animated animate__bounceIn"
          >
            <img src="@/assets/images/download_icon.svg" />
            <span>{{ t.banner.btn2 }}</span>
          </a>
          <!-- <router-link to="/donwload-wallet" class="wow animate__animated animate__bounceIn">
                    <img src="@/assets/images/download_icon.svg" />
                    <span>{{t.banner.btn2}}</span>
                   </router-link> -->
          <a
            href="https://hacken.io/audits/wow-earn/"
            data-wow-duration="0.6s"
            data-wow-delay="0.6s"
            class="wow animate__animated animate__bounceIn"
          >
            <img src="@/assets/images/audited_icon.svg" />
            <span>{{ t.banner.btn3 }}</span>
          </a>
        </div>
      </div>
      <div
        data-wow-delay="500ms"
        data-wow-duration="0.6s"
        class="wow animate__animated animate__fadeInRightBig banner-ani-box"
      >
        <div><img src="@/assets/images/banner.webp" /></div>
      </div>
    </div>
  </div>
  <div class="container data" id="data" ref="targetElement">
    <div
      data-wow-duration="500ms"
      data-wow-delay="300ms"
      class="wow animate__animated animate__bounceInUp container-box data-box"
    >
      <div class="data-item">
        <div class="data-num"><span id="data1">10000</span>+</div>
        <div class="data-label">{{ t.data.data1 }}</div>
      </div>
      <div class="data-item">
        <div class="data-num"><span id="data2">0</span></div>
        <div class="data-label">{{ t.data.data2 }}</div>
      </div>
      <div class="data-item">
        <div class="data-num"><span id="data3">1000000</span>+</div>
        <div class="data-label">{{ t.data.data3 }}</div>
      </div>
      <div class="data-item">
        <div class="data-num"><span id="data4">0</span>+</div>
        <div class="data-label">{{ t.data.data4 }}</div>
      </div>
      <div class="data-item">
        <div class="data-num"><span id="data5">100000</span>+</div>
        <div class="data-label">{{ t.data.data5 }}</div>
      </div>
    </div>
  </div>
  <div class="container features" id="Function">
    <div class="container-box features-box">
      <div class="title features-top">
        <div
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInLeft coin-icon coin-1"
        >
          <img src="@/assets/images/coin_1.png" />
        </div>
        <div
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInLeft coin-icon coin-2"
        >
          <img src="@/assets/images/coin_2.png" />
        </div>
        <div
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInRight coin-icon coin-3"
        >
          <img src="@/assets/images/coin_3.png" />
        </div>
        <div
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInDown coin-icon coin-4"
        >
          <img src="@/assets/images/diamond_1.png" />
        </div>
        <div
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInDown coin-icon coin-5"
        >
          <img src="@/assets/images/diamond_2.png" />
        </div>
        <h1
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInDown"
        >
          <span>{{ t.features.title }}</span>
        </h1>
      </div>
      <div
        data-wow-duration="1s"
        class="wow animate__animated animate__fadeInUp features-list"
      >
        <ul>
          <li>
            <a href="https://www.wowearn.com/download.html">
              <div>
                <div class="features-icon">
                  <img src="@/assets/images/f_1.png" />
                </div>
                <p>{{ t.features.data1 }}</p>
              </div>
            </a>
          </li>
          <li>
            <a href="https://app.wowearn.com/">
              <div>
                <div class="features-icon">
                  <img src="@/assets/images/f_2.png" />
                </div>
                <p>{{ t.features.data2 }}</p>
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.wowearn.com/download.html">
              <div>
                <div class="features-icon">
                  <img src="@/assets/images/f_3.png" />
                </div>
                <p>{{ t.features.data3 }}</p>
              </div>
            </a>
          </li>
          <li>
            <a href="https://wowearn.io/">
              <div>
                <div class="features-icon">
                  <img src="@/assets/images/f_4.png" />
                </div>
                <p>{{ t.features.data4 }}</p>
              </div>
            </a>
          </li>
          <li>
            <a href="https://app.wowearn.com">
              <div>
                <div class="features-icon">
                  <img src="@/assets/images/f_5.png" />
                </div>
                <p>{{ t.features.data5 }}</p>
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.wowearn.com/download.html">
              <div>
                <div class="features-icon">
                  <img src="@/assets/images/f_6.png" />
                </div>
                <p>{{ t.features.data6 }}</p>
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.wowearn.com/">
              <div>
                <div class="features-icon">
                  <img src="@/assets/images/f_7.png" />
                </div>
                <p>{{ t.features.data7 }}</p>
              </div>
            </a>
          </li>
          <li>
            <a href="https://link3.to/wowearn">
              <div>
                <div class="features-icon">
                  <img src="@/assets/images/f_8.png" />
                </div>
                <p>{{ t.features.data8 }}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="container wow-coin">
    <div class="container-box wow-coin-box">
      <div class="title wow-coin-top">
        <div
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInLeft wowo-coin-icon wowo-coin-1"
        >
          <img src="@/assets/images/wow_coin_1.png" />
        </div>
        <div
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInLeft wowo-coin-icon wowo-coin-2"
        >
          <img src="@/assets/images/wow_coin_2.png" />
        </div>
        <h1
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInDown"
        >
          <span>WOW COIN</span>
        </h1>
      </div>
      <div class="wow-coin-con">
        <div class="wow-coin-text">
          <div>{{ t.wowcoin.data1 }}</div>
          <div class="banner-btn">
            <a
              href="https://app.wowearn.com"
              data-wow-duration="1"
              class="wow animate__animated animate__bounceIn"
            >
              <img src="@/assets/images/launch_icon.svg" />
              <span>{{ t.banner.btn1 }}</span>
            </a>
          </div>
        </div>
        <!--
                    <div class="wowo-coin-img">
                        <div  data-wow-duration="400ms" class="wow animate__animated animate__fadeInLeft wowo-coin-icon wowo-coin-3"><img src="@/assets/images/wow_coin_3.png" /></div>
                        <div  data-wow-duration="400ms" class="wow animate__animated animate__fadeInDown wowo-coin-icon wowo-coin-4"><img src="@/assets/images/wow_coin_4.png" /></div>
                        <div  data-wow-duration="400ms" class="wow animate__animated animate__fadeInRight wowo-coin-icon wowo-coin-5"><img src="@/assets/images/wow_coin_5.png" /></div>
                    </div>
                    -->
        <view
          data-wow-delay="300ms"
          data-wow-duration="1s"
          class="wow animate__animated animate__fadeInRightBig wow-coin-ani"
        >
          <img src="@/assets/images/wow.webp" />
        </view>
      </div>
    </div>
  </div>
  <div class="container mechanism" id="Mining">
    <div class="container-box mechanism-box">
      <div class="title mechanism-box-top">
        <h1
          data-wow-duration="1s"
          class="wow animate__animated animate__bounceInDown"
        >
          <span>{{ t.mechanism.title }}</span>
        </h1>
      </div>
      <div class="mechanism-text">
        <div class="mechanism-text-1">
          <div
            data-wow-duration="500ms"
            class="wow animate__animated animate__fadeInLeftBig"
          >
            <div v-html="t.mechanism.data1"></div>
            <div v-html="t.mechanism.data2"></div>
          </div>
          <div
            data-wow-delay="200ms"
            data-wow-duration="500ms"
            class="wow animate__animated animate__fadeInLeftBig"
          >
            <div v-html="t.mechanism.data3"></div>
            <div v-html="t.mechanism.data4"></div>
          </div>
        </div>
        <div class="flex100 mechanism-ani">
          <div class="mechanism-ani-img">
            <img
              src="@/assets/images/mechanism.webp"
              data-wow-delay="1s"
              data-wow-duration="0s"
              class="wow animate__animated animate__fadeIn"
            />
          </div>
          <div class="mechanism-total" v-html="t.mechanism.total"></div>
        </div>

        <div class="mechanism-text-2">
          <div
            data-wow-delay="400ms"
            data-wow-duration="500ms"
            class="wow animate__animated animate__fadeInRightBig"
          >
            <div v-html="t.mechanism.data5"></div>
            <div v-html="t.mechanism.data6"></div>
          </div>
          <div
            data-wow-delay="600ms"
            data-wow-duration="500ms"
            class="wow animate__animated animate__fadeInRightBig"
          >
            <div v-html="t.mechanism.data7"></div>
            <div v-html="t.mechanism.data8"></div>
          </div>
          <div
            data-wow-delay="800ms"
            data-wow-duration="500ms"
            class="wow animate__animated animate__fadeInRightBig"
          >
            <div v-html="t.mechanism.data9"></div>
            <div v-html="t.mechanism.data10"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container roadmap" id="RoadMap">
    <div class="container-box roadmap-box">
      <div class="title">
        <h1
          data-wow-duration="1s"
          class="wow animate__animated animate__bounceInDown"
        >
          <span>{{ t.roadmap.title }}</span>
        </h1>
      </div>
      <div class="current-step">
        <h3>{{ currentStep.q ? currentStep.q : t.roadmap.data1.q }}</h3>
        <p
          v-html="
            currentStep.d
              ? currentStep.d
              : stepid == 6
              ? ''
              : t.roadmap.data1.d
          "
        ></p>
      </div>
      <div class="roadmap-step">
        <div class="line">
          <div class="step step1" :class="stepid == 1 ? 'current' : ''">
            <div
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInDownBig step-text"
            >
              <h3>{{ t.roadmap.data1.q }}</h3>
            </div>
            <div
              class="step-circle"
              :data-stepid="1"
              :data-item="JSON.stringify(t.roadmap.data1)"
              v-on:click="toStep"
            ></div>
          </div>
          <div class="step step2" :class="stepid == 2 ? 'current' : ''">
            <div
              data-wow-delay="400ms"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-delay="400ms"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInUpBig step-text"
            >
              <h3>{{ t.roadmap.data2.q }}</h3>
            </div>
            <div
              class="step-circle"
              :data-stepid="2"
              :data-item="JSON.stringify(t.roadmap.data2)"
              v-on:click="toStep"
            ></div>
          </div>
          <div class="step step3" :class="stepid == 3 ? 'current' : ''">
            <div
              data-wow-delay="600ms"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-delay="600ms"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInDownBig step-text"
            >
              <h3>{{ t.roadmap.data3.q }}</h3>
            </div>
            <div
              class="step-circle"
              :data-stepid="3"
              :data-item="JSON.stringify(t.roadmap.data3)"
              v-on:click="toStep"
            ></div>
          </div>
          <div class="step step4" :class="stepid == 4 ? 'current' : ''">
            <div
              data-wow-delay="800ms"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-delay="800ms"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInUpBig step-text"
            >
              <h3>{{ t.roadmap.data4.q }}</h3>
            </div>
            <div
              class="step-circle"
              :data-stepid="4"
              :data-item="JSON.stringify(t.roadmap.data4)"
              v-on:click="toStep"
            ></div>
          </div>
          <div class="step step5" :class="stepid == 5 ? 'current' : ''">
            <div
              data-wow-delay="1s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-delay="1s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInDownBig step-text"
            >
              <h3>{{ t.roadmap.data5.q }}</h3>
            </div>
            <div
              class="step-circle"
              :data-stepid="5"
              :data-item="JSON.stringify(t.roadmap.data5)"
              v-on:click="toStep"
            ></div>
          </div>
          <div class="step step6" :class="stepid == 6 ? 'current' : ''">
            <div
              data-wow-delay="1.2s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-delay="1.2s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInDownBig step-text"
            >
              <h3>{{ t.roadmap.data6.q }}</h3>
            </div>

            <div
              class="step-circle"
              :data-stepid="6"
              :data-item="JSON.stringify(t.roadmap.data6)"
              v-on:click="toStep"
            ></div>
          </div>

          <div class="step step7" :class="stepid == 7 ? 'current' : ''">
            <div
              data-wow-delay="1.4s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-delay="1.4s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInDownBig step-text"
            >
              <h3>{{ t.roadmap.data7.q }}</h3>
            </div>

            <div
              class="step-circle"
              :data-stepid="7"
              :data-item="JSON.stringify(t.roadmap.data7)"
              v-on:click="toStep"
            ></div>
          </div>

          <div class="step step8" :class="stepid == 8 ? 'current' : ''">
            <div
              data-wow-delay="1.6s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-delay="1.6s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInDownBig step-text"
            >
              <h3>{{ t.roadmap.data8.q }}</h3>
            </div>

            <div
              class="step-circle"
              :data-stepid="8"
              :data-item="JSON.stringify(t.roadmap.data8)"
              v-on:click="toStep"
            ></div>
          </div>

          <div class="step step9" :class="stepid == 9 ? 'current' : ''">
            <div
              data-wow-delay="1.8s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeIn step-line"
            ></div>
            <div
              data-wow-delay="1.8s"
              data-wow-duration="200ms"
              class="wow animate__animated animate__fadeInDownBig step-text"
            >
              <h3>{{ t.roadmap.data9.q }}</h3>
            </div>

            <div
              class="step-circle"
              :data-stepid="9"
              :data-item="JSON.stringify(t.roadmap.data9)"
              v-on:click="toStep"
            ></div>
          </div>
        </div>
      </div>

      <div class="mobile-step">
        <div
          class="wow animate__animated mobile-step-item"
          data-wow-delay="300ms"
          data-wow-duration="1s"
          v-for="(item, key, index) in t.roadmap"
          :key="key"
          :class="
            index % 2 === 0 ? 'animate__bounceInRight' : 'animate__bounceInLeft'
          "
        >
          <h3>{{ item.q }}</h3>
          <p v-html="item.d"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="container link">
    <div class="container-box link-box">
      <div class="title">
        <h1
          data-wow-duration="1s"
          class="wow animate__animated animate__bounceInDown"
        >
          <span>{{ t.partners.title }}</span>
        </h1>
      </div>
      <ul
        data-wow-delay="300ms"
        data-wow-duration="1s"
        class="wow animate__animated animate__bounceInUp"
      >
        <li>
          <a href="#"><img src="@/assets/images/l_1.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_2.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_3.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_4.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_5.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_6.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_7.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_8.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_9.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_10.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_11.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_12.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_13.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_14.png" /></a>
        </li>
        <li>
          <a href="#"><img src="@/assets/images/l_15.png" /></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="container footer">
    <div class="container-box footer-box">
      <div class="s-box">
        <div class="bottom-title">{{ t.footer.social }}</div>
        <div class="s-list">
          <a href="https://www.instagram.com/wowearnen"
            ><img src="@/assets/images/s_1.svg"
          /></a>
          <a href="https://twitter.com/WOWEARNENG"
            ><img src="@/assets/images/s_2.svg"
          /></a>
          <a href="https://www.facebook.com/WOWEARNEN"
            ><img src="@/assets/images/s_3.svg"
          /></a>
          <a href="https://t.me/wowearnen"
            ><img src="@/assets/images/s_4.png"
          /></a>
          <a href="https://www.threads.net/@wowearnen"
            ><img src="@/assets/images/s_5.svg"
          /></a>
          <a href="https://discord.com/invite/29CXG3AMa4"
            ><img src="@/assets/images/s_6.svg"
          /></a>
          <a href="https://coinmarketcap.com/community/profile/WOWEARN/"
            ><img src="@/assets/images/s_7.svg"
          /></a>
          <a href="https://medium.com/@wowearn2023"
            ><img src="@/assets/images/s_8.svg"
          /></a>
        </div>
        <div class="copyright">@2022-2024 Copy WOWEARN All Rights Reserved</div>
      </div>

      <div class="resources-box">
        <div class="bottom-title">{{ t.footer.resources.title }}</div>
        <div class="resources-list">
          <ul>
            <li>
              <a href="https://wowearn.gitbook.io/docs/">{{
                t.footer.resources.data1
              }}</a>
            </li>
            <li>
              <a href="https://app.wowearn.com/">{{
                t.footer.resources.data2
              }}</a>
            </li>
            <li>
              <a href="https://app.wowearn.com/">{{
                t.footer.resources.data3
              }}</a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                @click="scrollToSection('RoadMap')"
                >{{ t.footer.resources.data4 }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="reach-us-box">
        <div class="bottom-title">{{ t.footer.contact }}</div>
        <div class="reach-us-email">E-mail: support@wowearn.com</div>
      </div>
      <div class="qr-code">
        <a
          href="https://apps.apple.com/us/app/wow-earn-btc-crypto-wallet/id6443434220"
        >
          <img src="@/assets/images/ios.svg" />
          <div>
            <p>Download from</p>
            <h3>App Store</h3>
          </div>
        </a>
        <a href="https://ossimg.ullapay.com/ulla-app-package/android_release/WOWEARN_release.apk">
          <img src="@/assets/images/android.svg" />
          <div>
            <p>Download from</p>
            <h3>Android</h3>
          </div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.hxg.wallet">
          <img src="@/assets/images/google.svg" />
          <div>
            <p>Download from</p>
            <h3>GooglePlay</h3>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { WOW } from "wowjs";
import "@/assets/css/theme.css";
import language from "@/lang";

//console.log(language)

//console.log('localStorage language',localStorage.getItem('language'))
export default {
  components: {},
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      browseMode: "dark", // light dark
      isOpen: false,
      isShowContent: false,
      currentStep: {
        q: "",
        d: "",
      },
      stepid: 1,
      lang: [
        {
          name: "English",
          val: "en",
          icon: require("@/assets/images/la_1.png"),
          cname: "英语",
          self: "English",
        },
        {
          name: "Germany",
          val: "de",
          icon: require("@/assets/images/la_2.png"),
          cname: "德语",
          self: "Deutsch",
        },
        {
          name: "Spain",
          val: "es",
          icon: require("@/assets/images/la_3.png"),
          cname: "西班牙语",
          self: "Español",
        },
        {
          name: "France",
          val: "fr",
          icon: require("@/assets/images/la_4.png"),
          cname: "法语",
          self: "Français",
        },
        {
          name: "Italy",
          val: "it",
          icon: require("@/assets/images/la_5.png"),
          canme: "意大利语",
          self: "Italiano",
        },
        {
          name: "Russian",
          val: "ru",
          icon: require("@/assets/images/la_6.png"),
          canme: "俄语",
          self: "Русский",
        },
        {
          name: "Japan",
          val: "jp",
          icon: require("@/assets/images/la_7.png"),
          cname: "日语",
          self: "日本語",
        },
        {
          name: "Korea-South",
          val: "kr",
          icon: require("@/assets/images/la_8.png"),
          cname: "韩语",
          self: "한국어",
        },
        {
          name: "Portugal",
          val: "pt",
          icon: require("@/assets/images/la_9.png"),
          cname: "葡萄牙语",
          self: "Português",
        },
        {
          name: "Ukrainian SSR",
          val: "ua",
          icon: require("@/assets/images/la_10.png"),
          cname: "乌克兰语",
          self: "українська",
        },
        {
          name: "Czech Republic",
          val: "cz",
          icon: require("@/assets/images/la_11.png"),
          cname: "捷克",
          self: "čeština",
        },
        {
          name: "Malaysia",
          val: "my",
          icon: require("@/assets/images/la_12.png"),
          cname: "马来语",
          self: "Bahasa Melayu",
        },
        {
          name: "Turkey",
          val: "tr",
          icon: require("@/assets/images/la_13.png"),
          cname: "土耳其",
          self: "Türkçe",
        },
        {
          name: "Myanmar",
          val: "mm",
          icon: require("@/assets/images/la_14.png"),
          cname: "缅甸",
          self: "မြန်မာ",
        },
        {
          name: "Indonesia",
          val: "id",
          icon: require("@/assets/images/la_15.png"),
          cname: "印度尼西亚",
          self: "Bahasa Indonesia",
        },
        {
          name: "Bangladesh",
          val: "bd",
          icon: require("@/assets/images/la_16.png"),
          cname: "孟加拉",
          self: "বাংলা",
        },
        {
          name: "Saudi Arabia",
          val: "sa",
          icon: require("@/assets/images/la_17.png"),
          cname: "阿拉伯",
          self: "العربية",
        },
        {
          name: "Thailand",
          val: "th",
          icon: require("@/assets/images/la_18.png"),
          cname: "泰国",
          self: "ไทย",
        },
        {
          name: "Vietnam",
          val: "vn",
          icon: require("@/assets/images/la_19.png"),
          cname: "越南",
          self: "Tiếng Việt",
        },
        {
          name: "Cambodia",
          val: "kh",
          icon: require("@/assets/images/la_20.png"),
          cname: "柬埔寨",
          self: "ភាសាខ្មែរ",
        },
        {
          name: "Pakistan",
          val: "pk",
          icon: require("@/assets/images/la_21.png"),
          cname: "巴基斯坦",
          self: "ایران",
        },
        {
          name: "Iran",
          val: "ir",
          icon: require("@/assets/images/la_22.png"),
          cname: "伊朗",
          self: "پاکستانی",
        },
        {
          name: "繁体中文",
          val: "zh_TW",
          icon: require("@/assets/images/la_23.png"),
          self: "繁體中文",
        },
        {
          name: "简体中文",
          val: "zh",
          icon: require("@/assets/images/la_24.png"),
          self: "简体中文",
        },
      ],
      t:
        localStorage.getItem("language") &&
        language[localStorage.getItem("language")]
          ? language[localStorage.getItem("language")]
          : language["en"],
    };
  },
  setup() {},
  created() {
    var browseMode = localStorage.getItem("browseMode");
    if (browseMode) {
      this.browseMode = browseMode;
      document.documentElement.setAttribute("data-theme", browseMode);
    }

    this.$nextTick(() => {
      let wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  mounted() {
    const tag = this.$route.query.tag
    if (tag) {
      this.scrollToSection(tag)
    }

    $(document).ready(() => {
  var isCount = false;
  var isVisible = false;
  try {
    var rect = $(".data-box")?.[0].getBoundingClientRect();
    isVisible =
      (rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)) ||
      (rect.top <
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right > 0 &&
        rect.bottom > 0);
    //console.log('isVisible',isVisible)
  } catch (e) {
    console.log(e);
  }

  if (isVisible && !isCount) {
    isCount = true;
    countUp("#data1", 10000, 100000, 3);
    countUp("#data2", 0, 178, 3);
    countUp("#data3", 1000000, 2000000, 3);
    countUp("#data4", 0, 30, 3);
    countUp("#data5", 100000, 300000, 3);
  }

  $(".change-lang-btn").on("click", function () {
    if ($(".change-lang").hasClass("open")) {
      $(".change-lang").removeClass("open");
    } else {
      $(".change-lang").addClass("open");
    }
  });

  $(document).on("click", function (event) {
    var target = $(event.target); // 获取被点击的元素

    if (
      $(".change-lang").length > 0 &&
      $.contains($(".change-lang")[0], target[0])
    ) {
      //console.log("点击的元素在div内");
    } else {
      // console.log("点击的元素不在div内");
      $(".change-lang").removeClass("open");
    }
  });

  $(document).scroll(function () {
    var isVisible = false;
    try {
      var rect = $(".data-box")?.[0].getBoundingClientRect();
      isVisible =
        (rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)) ||
        (rect.top <
          (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right > 0 &&
          rect.bottom > 0);
      //console.log('isVisible',isVisible)
    } catch (e) {
      console.log(e);
    }
    // var rect=$('.data-box')[0].getBoundingClientRect()
    // var isVisible = (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) ||
    // (rect.top < (window.innerHeight || document.documentElement.clientHeight) && rect.right > 0 && rect.bottom > 0);

    if (isVisible && !isCount) {
      isCount = true;
      countUp("#data1", 10000, 60000, 3);
      countUp("#data2", 0, 178, 3);
      countUp("#data3", 1000000, 2000000, 3);
      countUp("#data4", 0, 30, 3);
      countUp("#data5", 100000, 300000, 3);
    }
  });
  $(".btn").click(function () {
    $(".box").addClass("animate__animated animate__bounceInDown");
  });
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0, // 确保没有小数部分
      maximumFractionDigits: 0,
      useGrouping: true // 启用分组，即千分位
    });
  }
  /***
   * ele 元素
   * startVal 开始值
   * endDVal 结束值
   * duration 时间 秒
   * dec 保留小数
   * */
  function countUp(ele, startVal, endDVal, duration, dec) {
    var ival = (endDVal - startVal) / (duration * 20);
    //console.log(ele,ival)
    setInterval(function () {
      startVal = startVal + ival;
      if (startVal >= endDVal) {
        startVal = endDVal;
        clearInterval();
      }
      if (dec) {
        $(ele).text(startVal.toFixed(dec));
      } else {
        $(ele).text(formatNumberWithCommas(startVal));
      }
    }, 50);
  }
});

    window.onresize = () =>
      (() => {
        console.log(document.body.clientWidth);
        if (document.body.clientWidth > 768) {
          this.isOpen = false;
        }
      })();
  },

  computed: {},
  methods: {
    redirectToblog() {
      // Programmatic navigation to '/download-Wallet' route
      this.$router.push("/blog");
    },

    //黑夜模式
    toDark() {
      document.documentElement.setAttribute("data-theme", "dark"); // 添加data属性表示夜间模式

      localStorage.setItem("browseMode", "dark");
      this.browseMode = "dark";
    },
    //白天模式
    toLight() {
      document.documentElement.setAttribute("data-theme", "light"); // 移除data属性表示取消夜间模式

      localStorage.setItem("browseMode", "light");
      this.browseMode = "light";
    },
    //切换语言
    changeLang: function (e) {
      console.log(e,e.target.dataset.val, language[val])
      var val = e.target.dataset.val;
      if (val && language[val]) {
        localStorage.setItem("language", val);
        this.t = language[val];
        var rindex = $(".step.current").index();
        //console.log(rindex)
        var kdata = "data" + (rindex + 1);
        this.currentStep = this.t.roadmap[kdata];
      } else {
        // console.log('暂未开放')
      }
      $(".change-lang").removeClass("open");
    },
    //
    toStep: function (e) {
      //console.log(e.target.dataset.item)
      var item = e.target.dataset.item;
      var stepid = e.target.dataset.stepid;
      var json = JSON.parse(item);
      this.currentStep = json;
      this.stepid = stepid;
      //console.log('stepid',stepid)
    },
    toOpen: function () {
      this.isOpen = !this.isOpen;
    },
    toClose: function () {
      //console.log('======')
      $(".change-lang").removeClass("open");
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        this.isOpen = false;
        section.scrollIntoView({ behavior: "smooth" });
      }
    },

    showToast(message) {
      console.log(message);
      this.$toast.info(message, {
        duration: 2000,
        position: "top-right",
      });
    },
    navigateToDownloadWallet() {
      this.$router.push("/download-wallet");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  width: 1rem;
  height: 1rem;
  background: #42b983;
}
</style>
