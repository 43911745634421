const en = {
    brand:'WOW ERAN',
    nav:{
        Home:'Home',
        Function:'Features',
        Mining:'Mining',
        RoadMap:'RoadMap',
        WhitePaper:'WhitePaper',
        Blog:'Archives',
        Task: 'Task Center'
    },
    language:'Language',
    banner:{
        title:'Your Gateway<br/>To Web3',
        des:'Unlock Genuine Digital Asset Ownership:<br/>Your Gateway to Unparalleled Web3 Experiences.',
        btn1:'Dapp Deployment',
        btn2:'Download Wallet',
        btn3:' Audited By Hacken'
    },

    data:{
        data1:'Daily Active Users',
        data2:'Countries Covered',
        data3:'Users',
        data4:'Strategic Partners',
        data5:'Community Users',
    },
    features:{
        title:'FEATURES',
        data1:'Trade Crypto',
        data2:'Mining Dapp',
        data3:'NFTs',
        data4:'Layer1 Blockchain',
        data5:'Staking',
        data6:'Swap',
        data7:'DEX',
        data8:'Community'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin is an integral component of the WOW EARN ecosystem, a cutting-edge blockchain platform designed to deliver high efficiency and seamless connectivity within the decentralized finance (DeFi) realm. This ecosystem empowers users to engage in staking, yield farming, and mining opportunities.',
        data2:'The WOW EARN chain is a pioneering Layer 1 platform designed to offer users and developers unparalleledefficiency and scalabilityfor various blockchain-based applications.',
        data3:'WOW Coin plays a crucial role within this ecosystem,facilitatingtransactions,rewards,and fees,and serving as the primary currencyfor interactions within the WOW EARN platform.',
        data4:'Its use in coin transfers,as detailed in the transactionssection of the explorer,demonstrates its fundamental utility inenabling blockchain operations and value exchange withinthe WOW EARN network.',

    },
    mechanism:{
        title:'Total Supply',
        data1:'Ecosystem Participants 35%',
        data2:'Node Validators 20%',
        data3:'Foundation Reserve 8%',
        data4:'Initial Decentralized Offering (IDO) 3%',
        data5:'Seed Funding Round 14%',
        data6:'Operational & Development Reserve 10%',
        data7:'Market Value Management 5%',
        data8:'Staking Rewards Pool 4%',
        data9:'Community Incentives Program 0.95%',
        data10:'Airdrop Rewards 0.05%',
        total:'1 Billion Total Supply',
    },
    roadmap:{
        title:'Strategic Roadmap: Driving Continuous Innovation',
        data1:{
            q:'Q4/ 2022',
            d:'Establishment of WOW EARN Team.<br/>' +
                'Release of WOW EARN Whitepaper and development plan.'
        },
        data2:{
            q:'Q1/ 2023',
            d:'Successful testing of DApp project.<br/>' +
                'Launch of WOW EARN.<br/>' +
                'Support for ETH, BSC.<br/>' +
                'Introduction of USDT staking.<br/>' +
                'Unique referral invitation mechanism.<br/>' +
                'WOW EARN user base reaches 50,000.<br/>' +
                'Establishment of partnerships with major blockchain media outlets.'
        },
        data3:{
            q:'Q2/ 2023',
            d:'Smart contract audit completed.<br/>' +
                'DApp contract audit passed.<br/>' +
                'Launch of new brand image.<br/>' +
                'Public release of WOWCoin.<br/>' +
                'WOW EARN user base reaches 100,000.<br/>' +
                'Support for cross-chain transactions.<br/>' +
                'Strategic partnerships formed with numerous DeFi projects.'
        },
        data4:{
            q:'Q3/ 2023',
            d:'WOW EARN user base reaches 500,000.<br/>' +
                'Global brand collaborations.<br/>' +
                'Initiation of Web3 offline events.'
        },
        data5:{
            q:'Q4/ 2023',
            d:'WOW EARN user base reaches 10,000,000.<br/>' +
                'Integration of multi-scenario TOKEN and NFT asset data.'
        },
        data6:{
            q:'Q1/ 2024',
            d:`New official website launched.<br/>
            Daily active users of DApp surpass 100,000.<br/>
            WOW EARN public chain holding addresses exceed 500,000`
        },
        data7:{
            q:'Q2/ 2024',
            d:`Public beta and launch of DEX based on the WOW EARN public chain.<br/>
            Official launch of the GameFi project on the WOW EARN public chain.<br/>
            Enhance the WOW EARN wallet user experience with added multi-chain support.<br/>
            Successful launch of 10,000+ DApp applications.<br/>
            Participation in globally renowned blockchain offline exhibitions significantly boosts brand visibility.<br/>
            Development of an incubation platform to build the ecosystem of the WOW EARN public chain`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX targets to surpass a Total Value Locked (TVL) of 1M U, lending product targets to surpass a TVL of 1M U.<br/>
            WOWCoin listed on centralized exchanges.<br/>
            Development of a financing platform to provide funding support for creative projects.<br/>
            Continuous product development, launching blockbuster applications, including DApp gaming applications and Gamefi applications.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`Maintenance of market makers to preserve the value of WOWCoin and continuous development of breakthrough products.<br/>
            Significant improvement in user stickiness for peripheral products.<br/>
            Comprehensive perfection of WOW EARN DAO governance`
        },

    },
    partners:{
        title:'Partners And Medias'
    },
    downloadWallet : {
        title:"Trade Safely and Securely \n Anytime. Anywhere.",
        subtitle: 'Trade at home and on the go with our app. Available via the App Store and Google Play Store',
        donwloadTitle: "Android",
        titleIOS: "IOS",
        downWalletTop : "Download from"

    },
    footer:{
        social:'Social media',
        resources:{
            title:'Resources',
            data1:'WhitePaper',
            data2:'Mining',
            data3:'Staking',
            data4:'RoadMap',
        },
        contact:'Contact Us',
        app:{
            ios:'IOS',
            android:'Android',
            apk:'APK'
        }
    }

}
export default en;
