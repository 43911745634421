const kr = {
    brand: 'WOW ERAN',
    nav: {
        Home: '홈',
        Function: '기능',
        Mining: '채굴',
        RoadMap: '로드맵',
        WhitePaper: '백서',
        Blog: '블로그',
        Task: ' 작업 센터'
    },
    language: '언어',
    banner: {
        title: '게이트웨이<br/>웹3로 가는 길',
        des: '진정한 디지털 자산 소유권 해제:<br/>웹3에서 전례없는 경험을 열어줍니다.',
        btn1: 'Dapp 시작',
        btn2: '지갑 다운로드',
        btn3:'Hacken에 의해 감사'
    },
    data: {
        data1: '일일 활성 사용자',
        data2: '국가 수',
        data3: '사용자',
        data4: '전략적 파트너',
        data5: '커뮤니티 사용자',
    },
    features: {
        title: '특징',
        data1: '크립토 구매',
        data2: '채굴 Dapp',
        data3: 'NFTs',
        data4: '레이어1 블록체인',
        data5: '스테이킹',
        data6: '스왑',
        data7: 'DEX',
        data8:'커뮤니티'
    },
wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coin은 분산 금융(DeFi) 세계에서 높은 효율성과 연결성을 강조하는 블록체인 플랫폼, WOW EARN 생태계의 일부입니다. 스테이킹, 수익성 농사, 채굴을 포함합니다.',
        data2:'WOW EARN 체인은 사용자와 개발자에게 다양한 블록체인 기반 애플리케이션에 대한 비교할 수 없는 효율성과 확장성을 제공하도록 설계된 선구적인 레이어 1 플랫폼입니다.',
        data3:'WOW Coin은 이 생태계 내에서 중요한 역할을 하며, 거래, 보상, 수수료를 용이하게 하고 WOW EARN 플랫폼 내의 상호작용을 위한 주요 통화로서 기능합니다.',
        data4:'탐색기의 거래 섹션에 자세히 설명된 것처럼, 코인 전송에서의 사용은 WOW EARN 네트워크 내에서 블록체인 작업과 가치 교환을 가능하게 하는 기본적인 유틸리티를 입증합니다.',
    },

    mechanism: {
        title: '총 공급량',
        data1: '사용자 채굴 35%',
        data2: '노드 채굴 20%',
        data3: '재단 8%',
        data4: '공개 제공 (IDO) 3%',
        data5: '시드 라운드 14%',
        data6: '운영 개발 10%',
        data7: '시장 가치 관리 5%',
        data8: '스테이킹 보상 4%',
        data9: '커뮤니티 인센티브 0.95%',
        data10: '에어드랍 보상 0.05%',
        total: '10억 개의 총 공급량',
    },
    roadmap: {
        title: '개발 로드맵',
        data1: {
            q: '2022년 Q4',
            d: 'WOW EARN 팀 설립.<br/>' +
                'WOW EARN 백서 및 개발 계획 공개.'
        },
        data2: {
            q: '2023년 Q1',
            d: 'DApp 프로젝트 성공적인 테스트.<br/>' +
                'WOW EARN 출시.<br/>' +
                'ETH, BSC 지원.<br/>' +
                'USDT 스테이킹 소개.<br/>' +
                '특별한 추천 인증 메커니즘.<br/>' +
                'WOW EARN 사용자 기반이 50,000명 달성.<br/>' +
                '주요 블록체인 미디어와의 파트너십 체결.'
        },
        data3: {
            q: '2023년 Q2',
            d: '스마트 계약 감사 완료.<br/>' +
                'DApp 계약 감사 통과.<br/>' +
                '새로운 브랜드 이미지 출시.<br/>' +
                'WOWCoin 공개.<br/>' +
                'WOW EARN 사용자 기반이 100,000명 달성.<br/>' +
                '크로스체인 트랜잭션 지원.<br/>' +
                '다수의 DeFi 프로젝트와 전략적 파트너십 체결.'
        },
        data4: {
            q: '2023년 Q3',
            d: 'WOW EARN 사용자 기반이 500,000명 달성.<br/>' +
                '글로벌 브랜드 협업.<br/>' +
                'Web3 오프라인 이벤트 시작.'
        },
        data5: {
            q: '2023년 Q4',
            d: 'WOW EARN 사용자 기반이 1,000,000명 달성.<br/>' +
                '다중 시나리오 토큰 및 NFT 자산 데이터 통합.'
        },
        data6: {
            q: '2024년 Q1',
            d:`새로운 공식 웹사이트 출시<br/>
            DApp 일일 활성 사용자 수 100,000명 돌파<br/>
            WOW EARN 공용 체인의 보유 주소 수 500,000개 돌파`
        },
        data7:{
            q:'Q2/ 2024',
            d:`WOW EARN 공용 체인 기반의 DEX 공개 베타 테스트 시작 및 출시<br/>
            WOW EARN 공용 체인의 GameFi 프로젝트 공식 시작<br/>
            WOW EARN 지갑 사용자 경험 개선, 다중 체인 지원 추가<br/>
            10,000개 이상의 DApp 애플리케이션 성공적 출시<br/>
            세계적으로 유명한 블록체인 오프라인 전시회 참여를 통해 브랜드 인지도 크게 향상<br/>
            WOW EARN 공용 체인의 생태계를 완성하기 위한 인큐베이션 플랫폼 구축`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEX 목표 TVL 1M U 돌파, 대출 상품 목표 TVL 1M U 돌파<br/>
            WOWCoin이 중앙화된 거래소에 상장<br/>
            창의적 프로젝트에 자금 지원을 제공하기 위한 금융 플랫폼 구축<br/>
            지속적인 제품 개발, 블록버스터 애플리케이션 출시, DApp 게임 애플리케이션 및 Gamefi 애플리케이션 포함.`
        },
        data9:{
            q:'Q4/ 2024',
            d:`마켓 메이커 유지를 통해 WOWCoin 가치 보존 및 혁신적 제품의 지속적 개발<br/>
            주변 제품의 사용자 접착성 현저히 향상<br/>
            WOW EARN DAO 거버넌스 전면 완성`
        },
    },
    partners: {
        title: '파트너 및 미디어'
    },
    downloadWallet : {
        title: "안전하고 안전하게 거래하세요. 언제 어디서나.",
    subtitle: "당사의 앱으로 집에서도 외출 중에도 거래하세요. App Store 및 Google Play Store에서 이용 가능합니다.",
    downWalletTop: "에서 다운로드",
    donwloadTitle: "기계적 인조 인간",
    titleIOS: "iOS",




    },
    footer: {
        social: '소셜 미디어',
        resources: {
            title: '자료',
            data1: '백서',
            data2: '채굴',
            data3: '스테이킹',
            data4: '로드맵',
        },
        contact: '문의하기',
        app: {
            ios: 'IOS',
            android: '안드로이드',
            apk: 'APK'
        }
    }
}

export default kr;
/*韩语*/
