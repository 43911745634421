<template>
  <header
    class="container header"
    :class="isOpen ? 'open' : ''"
    id="Home"
    @touchmove.prevent
  >
    <nav class="container-box navbar">
      <div class="navbar-brand-toggler">
        <div class="navbar-brand" @click="this.$router.push('/')">
          <img src="@/assets/images/logo.svg" />
          <span>WOW EARN</span>
        </div>
        <div class="navbar-toggler" v-on:click="toOpen">
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>

      <div class="collapse" id="navbarContent">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a
              class="nav-link"
              href="/#"
              @click="scrollToSection('Home')"
              >{{ t.nav.Home }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/#/?tag=Function"
              @click="scrollToSection('Function')"
              >{{ t.nav.Function }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/#/?tag=Mining"
              @click="scrollToSection('Mining')"
              >{{ t.nav.Mining }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/#/?tag=RoadMap"
              @click="scrollToSection('RoadMap')"
              >{{ t.nav.RoadMap }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://wowearn.gitbook.io/docs">{{
              t.nav.WhitePaper
            }}</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              @click="redirectToblog()"
              >{{ t.nav.Blog }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="showToast('Stay tuned')"
              >{{ t.nav.Task }}</a
            >
          </li>
        </ul>
        <div class="navbar-other">
          <div class="browsing-mode">
            <div
              v-if="browseMode == 'dark'"
              class="dark-mode"
              v-on:click="toLight"
            >
              <img src="@/assets/images/light_icon.png" /><span>Light</span>
            </div>
            <div
              v-if="browseMode == 'light'"
              class="light-mode"
              v-on:click="toDark"
            >
              <img src="@/assets/images/dark_icon.png" /><span>Dark</span>
            </div>
          </div>
          <div class="change-lang" v-click-outside="toClose">
            <div class="change-lang-btn">
              <img src="@/assets/images/lang_icon.svg" />
              <span>{{ t.language }}</span>
            </div>
            <div class="mask"></div>
            <div class="lang-list">
              <div class="lang-close" @click="toClose"></div>

              <!-- <ul>
                <li v-for="(item, index) in lang" :key="index">
                  <img
                    :src="item.icon"
                    :alt="item.name"
                    :data-val="item.val"
                    @click="changeLang"
                  />
                </li>
              </ul> -->
              <ul @touchmove.stop>
                <li v-for="(item, index) in lang" :key="index" :alt="item.name" :data-val="item.val" @click="changeLang">
                  <img
                    :src="item.icon"
                    :alt="item.name"
                    :data-val="item.val"
                  />
                  <span :data-val="item.val">{{ item.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <a href="https://app.wowearn.com/" class="launch-dapp">{{
            t.banner.btn1
          }}</a>
        </div>
      </div>
    </nav>
  </header>
  <!-- download wallet component -->
  <section class="download-wallet-main-wrp">
    <div class="container">
      <div class="download-wallet-inn d-flex flex-column">
        <div class="download-wallet-box">
          <div class="title" >
            {{ t.downloadWallet.title }}
           <!-- <div v-html="replaceWithBr(t.downloadWallet.title)"></div> -->
          </div>

          <div class="subtitle">
            <!-- Trade at home and on the go with our app. Available via the App
            Store and Google Play Store -->
            {{ t.downloadWallet.subtitle }}
          </div>
        </div>

        <!-- download info -->

        <div class="download-wallet-info">
          <div class="download-wallet-info-left">
            <div class="donwload-title">{{ t.downloadWallet.donwloadTitle }}</div>
            <a
              href="https://play.google.com/store/apps/details?id=com.hxg.wallet"
              target="_blank"
              class="download-wallet-list"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                class="css-1t1hcer"
              >
                <path
                  d="M13.559 11.087l-9.477-9.49h.092c.812 0 1.512.294 2.637.902l9.938 5.382-3.19 3.206zM12.637 12.008L2.902 21.72c-.24-.387-.405-1.014-.405-1.916V4.194c0-.903.165-1.53.405-1.917l9.735 9.731zM13.559 12.912l3.19 3.207L6.81 21.5c-1.125.608-1.826.904-2.637.904h-.092l9.477-9.492zM17.944 8.526l-3.466 3.483 3.466 3.465 2.102-1.143C20.82 13.907 22 13.133 22 12.009c0-1.143-1.18-1.917-1.954-2.34l-2.102-1.143z"
                  fill="currentColor"
                ></path>
              </svg>
              <div class="download-wallet-text">
                <div class="down-wallet-top"> Download from</div>
                <div class="down-wallet-from">Google Play</div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                class="css-ewgzez"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 4h7v7H4V4zm0 9h7v7H4v-7zm11 0h-2v4h4v-2h3v-2h-4v2h-1v-2zm5 3h-2v2h-2v2h4v-4zm-5 2h-2v2h2v-2zM13 4h7v7h-7V4zM8.5 6.5h-2v2h2v-2zm-2 9h2v2h-2v-2zm11-9h-2v2h2v-2z"
                  fill="currentColor"
                ></path>
              </svg>

              <!-- qr code box -->
              <div class="download-qr-box">
                <img
                  src="@/assets/picture/google.webp"
                  alt="qr code"
                />
                <div class="qrcode-text">Scan to Download</div>
              </div>
            </a>

            <a href="https://ossimg.ullapay.com/ulla-app-package/android_release/WOWEARN_release.apk" class="download-wallet-list">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1t1hcer"><path d="M17.543 14.1a1 1 0 01-.997-.997 1 1 0 01.997-.997 1 1 0 01.997.997 1 1 0 01-.997.997zm-11.05 0a1 1 0 01-.996-.997 1 1 0 01.997-.997 1 1 0 01.997.997.993.993 0 01-.997.997zM17.9 8.082l1.994-3.458a.409.409 0 00-.155-.567.409.409 0 00-.567.156l-2.022 3.503a12.354 12.354 0 00-5.14-1.098c-1.857 0-3.594.394-5.14 1.098L4.848 4.213a.419.419 0 00-.568-.156.419.419 0 00-.155.567l1.994 3.458C2.689 9.948.348 13.423 0 17.52h24c-.32-4.107-2.67-7.583-6.1-9.44z" fill="currentColor"></path></svg>
              <div class="download-wallet-text">
                <div class="down-wallet-top">Download from</div>
                <div class="down-wallet-from">Android</div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                class="css-ewgzez"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 4h7v7H4V4zm0 9h7v7H4v-7zm11 0h-2v4h4v-2h3v-2h-4v2h-1v-2zm5 3h-2v2h-2v2h4v-4zm-5 2h-2v2h2v-2zM13 4h7v7h-7V4zM8.5 6.5h-2v2h2v-2zm-2 9h2v2h-2v-2zm11-9h-2v2h2v-2z"
                  fill="currentColor"
                ></path>
              </svg>

              <!-- qr code box -->
              <div class="download-qr-box">
                <img
                  src="@/assets/picture/apk.webp"
                  alt="qr code"
                />
                <div class="qrcode-text">Scan to Download</div>
              </div>
            </a>

            <div class="donwload-title title-ios">IOS</div>

            <a
              href="https://apps.apple.com/us/app/ulla-pay/id6443434220"
              target="_blank"
              class="download-wallet-list"
            >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1t1hcer"><path d="M15.69 1.978c.133 1.149-.345 2.28-1.046 3.112-.73.819-1.9 1.445-3.037 1.364-.15-1.104.428-2.28 1.073-2.996.73-.815 1.997-1.438 3.01-1.48zm3.64 6.17c-.135.076-2.231 1.27-2.208 3.699.026 2.94 2.707 3.912 2.739 3.922-.015.069-.42 1.403-1.424 2.757-.84 1.193-1.72 2.359-3.116 2.38-.665.014-1.113-.168-1.58-.357-.488-.197-.996-.402-1.79-.402-.843 0-1.374.212-1.886.416-.442.177-.87.349-1.474.372-1.33.047-2.347-1.273-3.217-2.454-1.738-2.413-3.092-6.8-1.277-9.786.88-1.464 2.484-2.406 4.197-2.432.755-.014 1.48.262 2.114.504.485.186.918.35 1.273.35.312 0 .733-.158 1.224-.343.773-.291 1.72-.648 2.683-.552.659.018 2.536.247 3.745 1.924l-.003.002z" fill="currentColor"></path></svg>
              <div class="download-wallet-text">
                <div class="down-wallet-top">Download from</div>
                <div class="down-wallet-from">App Store</div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                class="css-ewgzez"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 4h7v7H4V4zm0 9h7v7H4v-7zm11 0h-2v4h4v-2h3v-2h-4v2h-1v-2zm5 3h-2v2h-2v2h4v-4zm-5 2h-2v2h2v-2zM13 4h7v7h-7V4zM8.5 6.5h-2v2h2v-2zm-2 9h2v2h-2v-2zm11-9h-2v2h2v-2z"
                  fill="currentColor"
                ></path>
              </svg>

              <!-- qr code box -->
              <div class="download-qr-box">
                <img
                  src="@/assets/picture/ios.webp"
                  alt="qr code"
                />
                <div class="qrcode-text">Scan to Download</div>
              </div>
            </a>
          </div>

          <div class="download-wallet-info-right">
            <img
              :src="require('@/assets/images/ins.webp')"
              alt=""
              class="download-img"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container footer">
    <div class="container-box footer-box">
      <div class="s-box">
        <div class="bottom-title">{{ t.footer.social }}</div>
        <div class="s-list">
          <a href="https://www.instagram.com/wowearnen"
            ><img src="@/assets/images/s_1.svg"
          /></a>
          <a href="https://twitter.com/WOWEARNENG"
            ><img src="@/assets/images/s_2.svg"
          /></a>
          <a href="https://www.facebook.com/WOWEARNEN"
            ><img src="@/assets/images/s_3.svg"
          /></a>
          <a href="https://t.me/wowearnen"
            ><img src="@/assets/images/s_4.png"
          /></a>
          <a href="https://www.threads.net/@wowearnen"
            ><img src="@/assets/images/s_5.svg"
          /></a>
          <a href="https://discord.com/invite/29CXG3AMa4"
            ><img src="@/assets/images/s_6.svg"
          /></a>
          <a href="https://coinmarketcap.com/community/profile/WOWEARN/"
            ><img src="@/assets/images/s_7.svg"
          /></a>
          <a href="https://medium.com/@wowearn2023"
            ><img src="@/assets/images/s_8.svg"
          /></a>
        </div>
        <div class="copyright">@2022-204 Copy WOWEARN All Rights Reserved</div>
      </div>

      <div class="resources-box">
        <div class="bottom-title">{{ t.footer.resources.title }}</div>
        <div class="resources-list">
          <ul>
            <li>
              <a href="https://wowearn.gitbook.io/docs/">{{
                t.footer.resources.data1
              }}</a>
            </li>
            <li>
              <a href="https://app.wowearn.com/">{{
                t.footer.resources.data2
              }}</a>
            </li>
            <li>
              <a href="https://app.wowearn.com/">{{
                t.footer.resources.data3
              }}</a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                @click="scrollToSection('RoadMap')"
                >{{ t.footer.resources.data4 }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="reach-us-box">
        <div class="bottom-title">{{ t.footer.contact }}</div>
        <div class="reach-us-email">E-mail: support@wowearn.com</div>
      </div>
      <div class="qr-code">
        <a
          href="https://apps.apple.com/us/app/wow-earn-btc-crypto-wallet/id6443434220"
        >
          <img src="@/assets/images/ios.svg" />
          <div>
            <p>Download from</p>
            <h3>App Store</h3>
          </div>
        </a>
        <a href="https://ossimg.ullapay.com/ulla-app-package/android_release/WOWEARN_release.apk">
          <img src="@/assets/images/android.svg" />
          <div>
            <p>Download from</p>
            <h3>Android</h3>
          </div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.hxg.wallet">
          <img src="@/assets/images/google.svg" />
          <div>
            <p>Download from</p>
            <h3>GooglePlay</h3>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { WOW } from "wowjs";
import "@/assets/css/theme.css";
import language from "@/lang";
import "@/assets/css/download-wallet.css";

export default {
  name: "DownloadWallet",

  props: {
    msg: String,
  },
  data() {
    return {
      browseMode: "dark", // light dark
      isOpen: false,
      isShowContent: false,
      currentStep: {
        q: "",
        d: "",
      },
      stepid: 1,
      lang: [
        {
          name: "English",
          val: "en",
          icon: require("@/assets/images/la_1.png"),
          cname: "英语",
        },
        {
          name: "Germany",
          val: "de",
          icon: require("@/assets/images/la_2.png"),
          cname: "德语",
        },
        {
          name: "Spain",
          val: "es",
          icon: require("@/assets/images/la_3.png"),
          cname: "西班牙语",
        },
        {
          name: "France",
          val: "fr",
          icon: require("@/assets/images/la_4.png"),
          cname: "法语",
        },
        {
          name: "Italy",
          val: "it",
          icon: require("@/assets/images/la_5.png"),
          canme: "意大利语",
        },
        {
          name: "Russian",
          val: "ru",
          icon: require("@/assets/images/la_6.png"),
          canme: "俄语",
        },
        {
          name: "Japan",
          val: "jp",
          icon: require("@/assets/images/la_7.png"),
          cname: "日语",
        },
        {
          name: "Korea-South",
          val: "kr",
          icon: require("@/assets/images/la_8.png"),
          cname: "韩语",
        },
        {
          name: "Portugal",
          val: "pt",
          icon: require("@/assets/images/la_9.png"),
          cname: "葡萄牙语",
        },
        {
          name: "Ukrainian SSR",
          val: "ua",
          icon: require("@/assets/images/la_10.png"),
          cname: "乌克兰语",
        },
        {
          name: "Czech Republic",
          val: "cz",
          icon: require("@/assets/images/la_11.png"),
          cname: "捷克",
        },
        {
          name: "Malaysia",
          val: "my",
          icon: require("@/assets/images/la_12.png"),
          cname: "马来语",
        },
        {
          name: "Turkey",
          val: "tr",
          icon: require("@/assets/images/la_13.png"),
          cname: "土耳其",
        },
        {
          name: "Myanmar",
          val: "mm",
          icon: require("@/assets/images/la_14.png"),
          cname: "缅甸",
        },
        {
          name: "Indonesia",
          val: "id",
          icon: require("@/assets/images/la_15.png"),
          cname: "印度尼西亚",
        },
        {
          name: "Bangladesh",
          val: "bd",
          icon: require("@/assets/images/la_16.png"),
          cname: "孟加拉",
        },
        {
          name: "Saudi Arabia",
          val: "sa",
          icon: require("@/assets/images/la_17.png"),
          cname: "阿拉伯",
        },
        {
          name: "Thailand",
          val: "th",
          icon: require("@/assets/images/la_18.png"),
          cname: "泰国",
        },
        {
          name: "Vietnam",
          val: "vn",
          icon: require("@/assets/images/la_19.png"),
          cname: "越南",
        },
        {
          name: "Cambodia",
          val: "kh",
          icon: require("@/assets/images/la_20.png"),
          cname: "柬埔寨",
        },
        {
          name: "Pakistan",
          val: "pk",
          icon: require("@/assets/images/la_21.png"),
          cname: "巴基斯坦",
        },
        {
          name: "Iran",
          val: "ir",
          icon: require("@/assets/images/la_22.png"),
          cname: "伊朗",
        },
        {
          name: "繁体中文",
          val: "zh_TW",
          icon: require("@/assets/images/la_23.png"),
        },
        {
          name: "简体中文",
          val: "zh",
          icon: require("@/assets/images/la_24.png"),
        },
      ],
      t:
        localStorage.getItem("language") &&
        language[localStorage.getItem("language")]
          ? language[localStorage.getItem("language")]
          : language["en"],
    };
  },
  setup() {},
  created() {
    var browseMode = localStorage.getItem("browseMode");
    if (browseMode) {
      this.browseMode = browseMode;
      document.documentElement.setAttribute("data-theme", browseMode);
    }

    this.$nextTick(() => {
      let wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  mounted() {
    const launchApp = this.$route.query.launchApp
    if (launchApp) {
      const link = document.createElement('a')
      link.href = `ullawallet://v1.0.9/default_page?url=${launchApp}`
      link.click()
    }

    $(document).ready(() => {
  var isCount = false;
  var isVisible = false;
  try {
    var rect = $(".data-box")?.[0].getBoundingClientRect();
    isVisible =
      (rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)) ||
      (rect.top <
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right > 0 &&
        rect.bottom > 0);
    //console.log('isVisible',isVisible)
  } catch (e) {
    console.log(e);
  }

  if (isVisible && !isCount) {
    isCount = true;
    countUp("#data1", 10000, 60000, 3);
    countUp("#data2", 0, 178, 3);
    countUp("#data3", 1000000, 2000000, 3);
    countUp("#data4", 0, 30, 3);
    countUp("#data5", 100000, 300000, 3);
  }

  $(".change-lang-btn").on("click", function () {
    if ($(".change-lang").hasClass("open")) {
      $(".change-lang").removeClass("open");
    } else {
      $(".change-lang").addClass("open");
    }
  });

  $(document).on("click", function (event) {
    var target = $(event.target); // 获取被点击的元素

    if (
      $(".change-lang").length > 0 &&
      $.contains($(".change-lang")[0], target[0])
    ) {
      //console.log("点击的元素在div内");
    } else {
      // console.log("点击的元素不在div内");
      $(".change-lang").removeClass("open");
    }
  });

  $(document).scroll(function () {
    var isVisible = false;
    try {
      var rect = $(".data-box")?.[0].getBoundingClientRect();
      isVisible =
        (rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)) ||
        (rect.top <
          (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right > 0 &&
          rect.bottom > 0);
      //console.log('isVisible',isVisible)
    } catch (e) {
      console.log(e);
    }
    // var rect=$('.data-box')[0].getBoundingClientRect()
    // var isVisible = (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) ||
    // (rect.top < (window.innerHeight || document.documentElement.clientHeight) && rect.right > 0 && rect.bottom > 0);

    if (isVisible && !isCount) {
      isCount = true;
      countUp("#data1", 10000, 60000, 3);
      countUp("#data2", 0, 178, 3);
      countUp("#data3", 1000000, 2000000, 3);
      countUp("#data4", 0, 30, 3);
      countUp("#data5", 100000, 300000, 3);
    }
  });
  $(".btn").click(function () {
    $(".box").addClass("animate__animated animate__bounceInDown");
  });
  /***
   * ele 元素
   * startVal 开始值
   * endDVal 结束值
   * duration 时间 秒
   * dec 保留小数
   * */
  function countUp(ele, startVal, endDVal, duration, dec) {
    var ival = (endDVal - startVal) / (duration * 20);
    //console.log(ele,ival)
    setInterval(function () {
      startVal = startVal + ival;
      if (startVal >= endDVal) {
        startVal = endDVal;
        clearInterval();
      }
      if (dec) {
        $(ele).text(startVal.toFixed(dec));
      } else {
        $(ele).text(parseInt(startVal));
      }
    }, 50);
  }
});
    window.onresize = () =>
      (() => {
        console.log(document.body.clientWidth);
        if (document.body.clientWidth > 768) {
          this.isOpen = false;
        }
      })();
  },

  computed: {},
  methods: {
    redirectToblog() {
      // Programmatic navigation to '/download-Wallet' route
      this.$router.push("/blog");
    },

   replaceWithBr(str) {
   return str.replace(/\n/g, "<br />");
},

    //黑夜模式
    toDark() {
      document.documentElement.setAttribute("data-theme", "dark"); // 添加data属性表示夜间模式

      localStorage.setItem("browseMode", "dark");
      this.browseMode = "dark";
    },
    //白天模式
    toLight() {
      document.documentElement.setAttribute("data-theme", "light"); // 移除data属性表示取消夜间模式

      localStorage.setItem("browseMode", "light");
      this.browseMode = "light";
    },
    //切换语言
    changeLang: function (e) {
      //console.log(e,e.target.dataset.val)
      var val = e.target.dataset.val;
      if (val && language[val]) {
        localStorage.setItem("language", val);
        this.t = language[val];
        var rindex = $(".step.current").index();
        //console.log(rindex)
        var kdata = "data" + (rindex + 1);
        this.currentStep = this.t.roadmap[kdata];
      } else {
        // console.log('暂未开放')
      }
      $(".change-lang").removeClass("open");
    },
    //
    toStep: function (e) {
      //console.log(e.target.dataset.item)
      var item = e.target.dataset.item;
      var stepid = e.target.dataset.stepid;
      var json = JSON.parse(item);
      this.currentStep = json;
      this.stepid = stepid;
      //console.log('stepid',stepid)
    },
    toOpen: function () {
      this.isOpen = !this.isOpen;
    },
    toClose: function () {
      //console.log('======')
      $(".change-lang").removeClass("open");
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        this.isOpen = false;
        section.scrollIntoView({ behavior: "smooth" });
      }
    },

    showToast(message) {
      console.log(message);
      this.$toast.info(message, {
        duration: 2000,
        position: "top-right",
      });
    },
    navigateToDownloadWallet() {
      this.$router.push("/download-wallet");
    },
  },
};
</script>

<style scoped>
.box {
  width: 1rem;
  height: 1rem;
  background: #42b983;
}
</style>
