const jp = {
    brand: 'WOW ERAN',
    nav: {
        Home: 'ホーム',
        Function: '機能',
        Mining: 'マイニング',
        RoadMap: 'ロードマップ',
        WhitePaper: 'ホワイトペーパー',
        Blog: 'ブログ',
        Task: 'タスクセンター'
    },
    language: '言語',
    banner: {
        title: 'Web3へのゲートウェイ<br/>',
        des: '真のデジタル資産の所有権を解除します：<br/>非比類ないWeb3体験へのポータル。',
        btn1: 'Dappを起動',
        btn2: 'ウォレットをダウンロード',
        btn3:'Hackenによって監査済み'
    },

    data: {
        data1: 'デイリーアクティブユーザー',
        data2: 'カバーされた国々',
        data3: 'ユーザー',
        data4: '戦略的パートナー',
        data5: 'コミュニティユーザー',
    },
    features: {
        title: '特徴',
        data1: '暗号通貨の購入',
        data2: 'マイニングDapp',
        data3: 'NFT',
        data4: 'レイヤー1ブロックチェーン',
        data5: 'ステーキング',
        data6: 'スワップ',
        data7: 'DEX',
        data8:'コミュニティ'
    },
    wowcoin:{
        title:'WOW COIN',
        data1:'WOW Coinは、分散型金融(DeFi)の世界で高効率と接続性を強調するブロックチェーンプラットフォーム、WOW EARNエコシステムの一部です。ステーキング、イールドファーミング、マイニングが含まれます。',
        data2:'WOW EARNチェーンは、様々なブロックチェーンベースのアプリケーションに対して、比類のない効率性とスケーラビリティを提供するために設計された、先駆的なレイヤー1プラットフォームです。',
        data3:'WOW Coinは、このエコシステム内で重要な役割を果たし、取引、報酬、手数料の促進、およびWOW EARNプラットフォーム内の相互作用における主要通貨として機能します。',
        data4:'エクスプローラーの取引セクションに詳述されている通り、コイン転送でのその使用は、WOW EARNネットワーク内でのブロックチェーン操作と価値交換を可能にする基本的なユーティリティを示しています。',
    },

    mechanism: {
        title: '総供給量',
        data1: 'ユーザーマイニング35%',
        data2: 'ノードマイニング20%',
        data3: '財団8%',
        data4: '公開募集（IDO）3%',
        data5: 'シードラウンド14%',
        data6: '運用開発10%',
        data7: '市場価値管理5%',
        data8: 'ステーキング報酬4%',
        data9: 'コミュニティインセンティブ0.95%',
        data10: 'エアドロップ報酬0.05%',
        total: '10億の総供給量',
    },
    roadmap: {
        title: '開発ロードマップ',
        data1: {
            q: '2022年Q4',
            d: 'WOW EARNチームの設立。<br/>' +
                'WOW EARNホワイトペーパーと開発計画の公開。'
        },
        data2: {
            q: '2023年Q1',
            d: 'DAppプロジェクトの成功したテスト。<br/>' +
                'WOW EARNのローンチ。<br/>' +
                'ETH、BSCのサポート。<br/>' +
                'USDTステーキングの導入。<br/>' +
                '独自の紹介招待メカニズム。<br/>' +
                'WOW EARNユーザーベースが5万人に達する。<br/>' +
                '主要なブロックチェーンメディアとのパートナーシップの確立。'
        },
        data3: {
            q: '2023年Q2',
            d: 'スマートコントラクトの監査完了。<br/>' +
                'DAppコントラクトの監査合格。<br/>' +
                '新しいブランドイメージのローンチ。<br/>' +
                'WOWCoinの公開。<br/>' +
                'WOW EARNユーザーベースが10万人に達する。<br/>' +
                'クロスチェーントランザクションのサポート。<br/>' +
                '多数のDeFiプロジェクトとの戦略的パートナーシップの形成。'
        },
        data4: {
            q: '2023年Q3',
            d: 'WOW EARNユーザーベースが50万人に達する。<br/>' +
                'グローバルブランドとのコラボレーション。<br/>' +
                'Web3オフラインイベントの開始。'
        },
        data5: {
            q: '2023年Q4',
            d: 'WOW EARNユーザーベースが100万人に達する。<br/>' +
                '複数シナリオTOKENとNFTアセットデータの統合。'
        },
        data6: {
            q: '2024年Q1',
            d:`新しい公式ウェブサイトが公開されました<br/>
            DAppの日間アクティブユーザーが100,000人を突破<br/>
            WOW EARN公共チェーンの保持アドレスが500,000を突破`
        },
        data7:{
            q:'Q2/ 2024',
            d:`WOW EARN公共チェーンに基づくDEXが公開ベータテストを開始し、リリースされました<br/>
            WOW EARN公共チェーンのGameFiプロジェクトが正式に開始<br/>
            WOW EARNウォレットのユーザーエクスペリエンスを向上させ、マルチチェーンサポートを追加<br/>
            10,000以上のDAppアプリケーションが成功裏にリリース<br/>
            世界的に有名なブロックチェーンのオフライン展示会への参加でブランドの知名度が顕著に向上<br/>
            孵化プラットフォームを構築し、WOW EARN公共チェーンのエコシステムを完璧にする`
        },
        data8:{
            q:'Q3/ 2024',
            d:`DEXの目標TVLが1M Uを突破、貸付製品の目標TVLが1M Uを突破<br/>
            WOWCoinが中央集権型取引所に上場<br/>
            クリエイティブなプロジェクトに資金を提供するための融資プラットフォームを構築<br/>
            継続的な製品開発、ブロックバスターアプリケーションの発売、DAppゲームアプリケーションおよびGamefiアプリケーションを含む。`
        },
        data9:{
            q:'Q4/ 2024',
            d:`マーケットメーカーを維持し、WOWCoinの価値を保持しつつ、革新的な製品の継続的な開発<br/>
            周辺製品のユーザーの粘着性が顕著に向上<br/>
            WOW EARN DAOのガバナンスが完全に洗練されました`
        },
    },
    partners: {
        title: '私たちのパートナーとメディア'
    },
    downloadWallet : {
        title: "安全に取引し、いつでもどこでも取引します。",
        subtitle: "当社のアプリで家でも外出先でも取引できます。App Store と Google Play Store で利用可能です。",
        downWalletTop: "からダウンロード",
        donwloadTitle: "アンドロイド",
        titleIOS: "IOS",



    },
    footer: {
        social: 'ソーシャルメディア',
        resources: {
            title: 'リソース',
            data1: 'ホワイトペーパー',
            data2: 'マイニング',
            data3: 'ステーキング',
            data4: 'ロードマップ',
        },
        contact: 'お問い合わせ',
        app: {
            ios: 'IOS',
            android: 'Android',
            apk: 'APK'
        }
    }
}
export default jp;

/*日语*/
